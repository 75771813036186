import { Redirect, Route } from "react-router-dom"
import Login from "../../components/Login"

const NoAuthRoutes = ()=>{
    return(
        <>
        <Route path="/">
            <Login/>
        </Route>
        <Redirect to="/"/>
        </>
    )
}

export default NoAuthRoutes;