import React from "react";
import { Button, TextField } from "@material-ui/core";

export default function BodyEditar(props) {
    const {
        onSubmitEditar,
        styles,
        handleChangeInsert,
        info,
        abrirCerrarModalEditar,
        error
    } = props
  return (
    <form action="" onSubmit={onSubmitEditar}>
      <div className={styles.modal}>
        <h3 className="my-5">Registrar usuario nuevo</h3>
        {error ? (
          <h4 className=" text-red-700">
            Completar todos los campos del formulario
          </h4>
        ) : null}
        <TextField
          className={styles.inputMaterial}
          name="name"
          onChange={handleChangeInsert}
          value={info && info.name}
          label="Nombre"
        />
        <br />
        <TextField
          className={styles.inputMaterial}
          name="lastName"
          onChange={handleChangeInsert}
          value={info && info.lastName}
          label="Apellido"
        />
        <br />
        <TextField
          className={styles.inputMaterial}
          name="document"
          onChange={handleChangeInsert}
          value={info && info.document}
          label="Doc. de Identidad"
        />
        <br />
        <TextField
          className={styles.inputMaterial}
          name="email"
          onChange={handleChangeInsert}
          value={info && info.email}
          label="Doc. de Identidad"
        />
        <br />

        <br />
        <br />
        <div align="right">
          <Button color="primary" type="submit">
            Editar
          </Button>
          <Button onClick={() => abrirCerrarModalEditar()}> Cancelar</Button>
        </div>
      </div>
    </form>
  );
}
