import axios from 'axios';
import { getHeaders } from '../../../../utils/methods';
import { urlInvoice } from '../../../../utils/urls';

export const getInvoiceForOwner = async (token)=>{
    try{
        const header = getHeaders(token);
        const consulta = await axios.get(urlInvoice,header);
        if(consulta.status === 200){
            return consulta.data.data;
        }else{
            return 0;
        }
    }catch(e){
        alert("Error al obtner las facturas");
        console.log("Error Invoice:",e);
    }
}