import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { userContext } from "../context/UserContext";
import AuthRoutes from "./auth/auth.route";
import NoAuthRoutes from "./noAuth/noauth.route";

const AppRoutes = ()=>{
    const [isLogin, setIsLogin] = useState(false);
    const userStorage = localStorage.getItem('user');
    const tokenStorage= localStorage.getItem('Authorization');

    const { setdataUser } = useContext(userContext);

    useEffect(()=>{
        const init = ()=>{
            if(tokenStorage !== "" && userStorage !== "" && tokenStorage !== null && userStorage !== null){
                console.log(tokenStorage, userStorage)
                setIsLogin(true);
                setdataUser(JSON.parse(userStorage));
            }else{
                setIsLogin(false);
            }
        }
        init();
    },[]);
    return(
        <Router>
            <Switch>
            {!isLogin ? <NoAuthRoutes/> : <AuthRoutes/>}
            </Switch>
        </Router>
    )
}


export default AppRoutes;