export const customerTableHeadNewExpense = [
    {
        title:"Nombre",
        field: "name"
    },
    {
        title:"Mz.",
        field: "apple"
    },
    {
        title:"Lte.",
        field: "lote"
    },
    {
        title:"Teléfono",
        field: "phoneNumber"
    },
    {
        title:"Correo",
        field: "email",
        render: data => data.email.toLowerCase()
    },
    {
        title:"Monto Total",
        field: "totalAmount"
    },
    {
        title:"Estado",
        field: "state",
        render: row=><div style={row.state === "Pendiente" ? {
            color: "#EC0E00"
        } : null} >{row.state}</div>
    }
]