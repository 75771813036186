import '../../pages/users/Users.css'
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import React, { useRef, useState} from 'react';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list'; //For List View
function CalendarioComponent({
    esLocale,
    dataCalendar,
    onClickCalendar
}) {

    const [modalOpen, setModalOpen] = useState(false);
    const calendarRef = useRef(null)

    return (
        <div>
            <button onClick={()=> setModalOpen(true)}>Agregar Evento</button>
            <div className='FullCalendar' >
                <FullCalendar 
                locale={esLocale}
                ref={calendarRef}
                events={dataCalendar}
                eventClick={onClickCalendar}
                plugins={[ dayGridPlugin, listPlugin ]}                
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek'
                  }}
                    defaultView="dayGridMonth"
                />
            </div>
        </div>
    )
}

CalendarioComponent.defaultProps = {
    esLocale: "",
    dataCalendar: {},
    onClickCalendar: ()=>{}
}

export default CalendarioComponent;
