import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlFormalities } from "../../../utils/urls";

export const obtainTramites = async (token)=>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.get(urlFormalities,header);
        if(consulta.status === 200){
            return consulta.data.data;
        }else{
            return false;
        }
    }catch(e){
        console.log("Error al obtener los tramites:",e);
        alert("Error al obtener la lista de tramites");
        return false;
    }
}