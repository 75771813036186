import "./FeaturedCard.css"

function FeaturedCard(props) {
    return (
        <div className="card" onClick={props.onClick}>
            <h5 className="tittleCard">{props.tittle}</h5>
            <div className="containerInfoCard">
                <p>{props.num}</p>
            </div>
        </div>
    )
}

export default FeaturedCard