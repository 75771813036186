import { Button, TextField } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";

export const BodyEditar = ({
  onSubmitEditar,
  error,
  styles,
  handleChangeInsert,
  info,
  abrirCerrarModalEditar
}) => {
  const {register,handleSubmit,formState:{errors}}= useForm()

  return (
    <form action="" onSubmit={handleSubmit(onSubmitEditar)}>
      <div className={styles.modal}>
        <h3 className="my-5">Editar Regla</h3>
  
        <br />
        <TextField
          className={styles.inputMaterial}
          name="description"
          value={info && info.description}
          label="Descripción*"
          {...register('descripcion',{
            required:{
              value:true,
              message:'El campo es requerido'
            },
            minLength:{
              value:4,
              message:'La descripcion debe tener mas de 4 letras'
            }
          })}
        />
        <span className="text-danger text-small d-block mb-2">
                {errors?.descripcion?.message}
        </span>
        <br />
        <TextField
          className={styles.inputMaterial}
          name="phone"
          label="Teléfono*"
          type="number"
          {...register('phone',{
            required:{
              value:true,
              message:'El campo es requqerido'
            },
            minLength:{
              value:10,
              message:'El Telefono debe ser de 10 digitos'
            },
            MaxLength:{
              value:10,
              message:'El Telefono debe ser de 10 digitos'
            }
          })}
        />
        <span className="text-danger text-small d-block mb-2">
                {errors?.phone?.message}
        </span>
        <br />

        <br />
        <br />
        <div align="right">
          <Button color="primary" type="submit">
            Editar
          </Button>
          <Button onClick={() => abrirCerrarModalEditar()}> Cancelar</Button>
        </div>
      </div>
    </form>
  );
};
