import { Button, TextField } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";

export const BodyEditar = ({
  onSubmitEditar,
  styles,
  error,
  handleChangeInsert,
  info,
  abrirCerrarModalEditar,
  urlAvatar,
}) => {
  const {register,handleSubmit,formState:{errors}}= useForm()

  return (
    <form action="" onSubmit={handleSubmit(onSubmitEditar)}>
      <div className={styles.modal}>
        <h3 className="my-5">Editar archivo</h3>
        {error ? (
          <h4 className=" text-red-700">
            Completar todos los campos del formulario
          </h4>
        ) : null}
        <TextField
          className={styles.inputMaterial}
          name="description"
          onChange={handleChangeInsert}
          value={info && info.description}
          label="Titulo*"
          {...register("descripcion", {
            required: {
              value: true,
              message: "El campo es requerido",
            },
            minLength: {
              value: 4,
              message: "La descripcion debe tener mas de 4 letras",
            },
          })}
        />
        <span className="text-danger text-small d-block mb-2">
                {errors?.descripcion?.message}
        </span>
        <br /> <br /> <br />
        <input
          type="date"
          className={styles.inputMaterial}
          name="publicationDate"
          onChange={handleChangeInsert}
          value={info && info.publicationDate}
          label="Fecha de Publicación*"
        />
        <br />
        {info?.attached !== undefined && info?.attached !== null ? (
          <div className="d-flex justify-content-center mt-5">
            <div>Ver documento</div>
            <a
              href={`${urlAvatar}/${info?.attached}`}
              target="_blank"
              className="linkdownload"
              title="Descargar Archivo"
            >
              <i className="material-icons file_download">visibility</i>
            </a>
          </div>
        ) : null}
        <br />
        <br />
        <div align="right">
          <Button color="primary" type="submit">
            Editar
          </Button>
          <Button onClick={() => abrirCerrarModalEditar()}> Cancelar</Button>
        </div>
      </div>
    </form>
  );
};
