import { getHeaders } from "../../../../utils/methods";
import { urlInvoice } from "../../../../utils/urls";

const token = localStorage.getItem('Authorization');
export const saveDataPropietarioCalculusPage = async (propertyId, total, date)=>{
    try{
        const header = getHeaders(token);
        const bodyResponse = {
            propertyId: propertyId,
            total: total,
            date: date,
            stateId: 7
        }
        const consulta = await fetch(urlInvoice,{
            method:'POST',
            body:JSON.stringify(bodyResponse),
            ...header
        });
        if(consulta.status === 201){
            return true;
        }else{
            return false;
        }
    }catch(e){
        throw Error(e);
    }
}