import axios from "axios";
import { getHeaderFiles } from "../../../utils/methods";
import { urlSpaceImage } from "../../../utils/urls";

export const setImageSpace = async (token, spaceId, imageInfo)=>{
    const header = getHeaderFiles(token);
    try{
        const file = new FormData();
        file.append("file", imageInfo);
        file.append("spaceId", spaceId);
        const consulta = await axios.post(urlSpaceImage, file, header);
        if(consulta.status === 201){
            return true;
        }
    }catch(e){
        console.log("Error al guardar la imagen del espacio:",e);
    }
    return false;
}