import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlFormalities } from "../../../utils/urls";

export const updateTramite = async (id, body, token)=>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.put(`${urlFormalities}/${id}`, body, header);
        if(consulta.status >= 200 && consulta.status < 300)
            return true;
    }catch(e){
        console.log("Error al actualizar el tramite:",e);
        alert("Error al actualizar el tramite");
    }
    return false;
}