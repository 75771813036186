import { TextField } from '@material-ui/core';
import { Field, Formik } from 'formik';
import '../../../styles/global.scss';
import createSpaceValidationSchema from '../schemas/create.validation';

const CreateBodyEspaciosModal = ({ onSubmit, spaceTypes, onChangeImagen, onDeleteImage }) =>{
    /**
     * Variables iniciales formik
     */
    const INITIAL_VALUES = {
        spaceTypeId: "",
        description: "",
        internalCode: "",
        previusReservationTime: "",
        maximiunReservationTime: "",
        rulesOfUse: "",
        file: null
    }
    return(
        <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={createSpaceValidationSchema}
            onSubmit={(val)=>console.log(val)}
        >
            {({ errors, values, handleChange })=>{
                console.log(values);
                return(
                <div className='modalContainer'>
                    <h3 className="my-5">Agregar Nuevo Espacio</h3>
                    <Field as="select" name="spaceTypeId" className="select1" values={values.spaceTypeId}>
                        {spaceTypes.map(tipos => (
                            <option value={tipos.id} key={tipos.id} >{tipos.name}</option>
                        ))}
                    </Field>
                    <Field 
                        id="description"
                        name="description"
                        label="Descripción(*)"
                        multiline 
                        rows={3}
                        component={TextField}
                        onChange={handleChange('description')}
                        className='inputModal'
                        error={errors.description}
                        helperText={errors.description}
                        values={values.description}
                    />
                    <Field 
                        id="internalCode"
                        name="internalCode"
                        label="ID (N° o nombre)(*)"
                        component={TextField}
                        onChange={handleChange('internalCode')}
                        className='inputModal'
                        error={errors.internalCode}
                        helperText={errors.internalCode}
                        value={values.internalCode}
                    />
                </div>
            )
            }}
        </Formik>
    )
}

CreateBodyEspaciosModal.defaultProps = {
    spaceTypes: [], 
    onSubmit:()=>{}, 
    onChangeImagen: ()=>{}, 
    onDeleteImage: ()=>{}
}

export default CreateBodyEspaciosModal;