import { getTotalGastosGenerales } from "../../methods/maths";

export const getGastosGeneralesRepartidos = (data=[],totalGeneral,amount)=>{
    if(data.length > 0){
        return data.reduce((t,{ property })=>t + getTotalGastosGenerales(property.participation,totalGeneral,amount),0);
    }
    return 0;
}

export const getTotalParticipation = (data=[])=>{
    if(data.length > 0){
        return data.reduce((t,{property})=>t + parseFloat(property.participation) ,0);
    }
    return 0;
}

export const getTotalCobranza = (data=[],amount)=>{
    if(data.length > 0){
        return data.length*amount;
    }
    return 0;
}