import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlUser } from "../../../utils/urls";

export const editUsurio = async (info, token)=>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.put(`${urlUser}/${info?.id}` ,info ,header);
        if(consulta.status === 201){
            return true;
        }else{
            return false;
        }
    }catch(e){
        console.log("Error al editar un usuario:",e);
        return false;
    }
}