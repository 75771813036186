import { money } from "../../../utils/format";
import { calculateSubTotalLight, calculateTotalLight, getSubTotalGastosGenerales, getSubtotalWater, getTotalGastosGenerales, getTotalWater } from "../methods/maths";

export const customerTableHeadCondominiumCommunal = ({amount, totalCondominium})=>([
    {
        title:"Propietario",
        cellStyle: {
            minWidth: 150,
            maxWidth: 150
        },
        render: data => data.user.name  + " " +  data.user.lastName    , 
    },

    {
        title:"Mz.",
        cellStyle: {
            minWidth: 80,
            maxWidth: 80
        },
        render: data => data.property.block   
    },
    {
        title:"Lte.",
        cellStyle: {
            minWidth: 80,
            maxWidth: 80
        },
        render: data => data.property.lot  
    },
    {
        title:"Área(m2)",
        cellStyle: {
            minWidth: 80,
            maxWidth: 80
        },
        render: data =>parseFloat(data.property.area)  
    },
    {
        title:"Pariticipacion (%)",
        cellStyle: {
            minWidth: 80,
            maxWidth: 80
        },
        render: data =>parseFloat(data.property.participation)  
    },
    {
        title:"Subtotal(S/)",
        cellStyle: {
            minWidth: 100,
            maxWidth: 100
        },
        render: data => money.format(getSubTotalGastosGenerales(data.property.participation,totalCondominium))
    },
    {
        title:"Cobranza(S/)",
        cellStyle: {
            minWidth: 80,
            maxWidth: 80
        },
         render: () => money.format(amount) 
    },
    {
        title:"Total",
        cellStyle: {
            minWidth: 80,
            maxWidth: 80
        }, 
        render: data => money.format(getTotalGastosGenerales(data.property.participation,totalCondominium,amount))
    }
]);

export const customerTableHeadEnergyTable = ({ amount, unitCost, fecha })=>(
    [
    
        {
            title:"Propietario",
            cellStyle: {
                minWidth: 50,
                maxWidth: 50
            },
            field: 'name',
            render: data => {
                for(let i = 0; i < data.property.users.length; i++) {
                    return data.property.users[i].name + " " + data.property.users[i].lastName
              
                }   
            }
        },
        {
            title:"Mz.",
            cellStyle: {
                minWidth: 10,
                maxWidth: 10
            },
            render: data => data.property.block     
        },
        {
            title:"Lte.",
            cellStyle: {
                minWidth: 10,
                maxWidth: 10
            },
            render: data => data.property.lot    
        },
        {
            title:"Periodo",
            cellStyle: {
                minWidth: 50,
                maxWidth: 50
            },
            field: 'date',
            render: () => fecha 
        },
        {
            title:"Consumo (KW)",
            cellStyle: {
                minWidth: 50,
                maxWidth: 50
            },
            render: data => data.consume
        },  
        {
            title:"SubTotal",
            cellStyle: {
                minWidth: 50,
                maxWidth: 50
            },
            render: data => money.format(calculateSubTotalLight(data.consume,unitCost))
        },
        {
            title:"Cobranza(S/)",
            cellStyle: {
                minWidth: 50,
                maxWidth: 50
            },
            render: () => money.format(amount) 
        },
        {
            title:"Total",
            cellStyle: {
                minWidth: 50,
                maxWidth: 50
            },
            render: data => money.format( calculateTotalLight(data.consume,unitCost,amount))
        }
    ]
);

export const customerTableHeadEnergyTableV2 =
    [
    
        {
            title:"Propietario"
        },
        {
            title:"Mz."    
        },
        {
            title:"Lte."    
        },
        {
            title:"Periodo"
        },
        {
            title:"Consumo (KW)"
        },  
        {
            title:"SubTotal"
        },
        {
            title:"Cobranza(S/)"
        },
        {
            title:"Total"
        }
    ];

export const customerTableHeadWater = ({ unitCost, amount, fecha })=>(
    [
    
        {
            title:"Propietario",
            cellStyle: {
              minWidth: 50,
              maxWidth: 50
            },
            field: 'name',
            render: data => data?.property?.users?.[0]?.name + " " + data?.property?.users?.[0]?.lastName
        },
        {
            title:"Mz.",
            cellStyle: {
              minWidth: 10,
              maxWidth: 10
            },
            render: data => data.property.block     
        },
        {
            title:"Lte.",
            cellStyle: {
              minWidth: 10,
              maxWidth: 10
            },
            render: data => data.property.lot    
        },
        {
            title:"Periodo",
            cellStyle: {
                minWidth: 50,
                maxWidth: 50
            },
            field: 'date',
            render: () => fecha  
        },
        {
            title:"Consumo (Lts)",
            cellStyle: {
                minWidth: 50,
                maxWidth: 50
            },
            render: data => data.consume
        },
        {
          title:"SubTotal",
          cellStyle: {
            minWidth: 50,
            maxWidth: 50
          },
            render: data => money.format(getSubtotalWater(data.consume,unitCost))  
        },
        {
            title:"Cobranza(S/)",
            cellStyle: {
                minWidth: 50,
                maxWidth: 50
            },
            render: () => money.format(amount) 
        },
        {
            title:"Total",
            cellStyle: {
                minWidth: 50,
                maxWidth: 50
            },
            render: data => money.format(getTotalWater(data.consume, unitCost, amount))
      }
    ]
)

export const customerTableTotalPerPeriod = ()=>(
    [
        {
            title: "Propietario",
            render: data => data.name
        },
        {
            title: "Gasto individual",
            render: data => money.format(data.comunInvoice)
        },
        {
            title: "Gasto de luz",
            render: data => money.format(data.lightInvoice)
        },
        {
            title: "Gasto de agua",
            render: data => money.format(data.waterInvoice || 0)
        },
        {
            title: "Total del periodo",
            render: data => money.format(parseFloat(data.comunInvoice || 0) + parseFloat(data.lightInvoice || 0) + parseFloat(data.waterInvoice || 0))
        }
    ]
)
