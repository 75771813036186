import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlUsefulInformation } from "../../../utils/urls";

export const setTelefono = async (info, token) =>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.post(urlUsefulInformation, info, header);
        if(consulta.status === 201 || consulta.status === 200){
            return true;
        }else{
            return false;
        }
    }catch(e){
        alert("Error al guardar el telefono");
        return false;
    }
}