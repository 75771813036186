import { faLocation, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import "../styles/GastosComunesOptions.scss";

const GastosComunesOptions = ({ approvedAll, role, total })=>{

    const [startDate, setStartDate] = useState(new Date());

    console.log("aqui"+approvedAll)

    return(
        <div className="OptionsCointainer">
            {role == 1 ? 
                <div className="item">
                    <button>
                        <FontAwesomeIcon icon={faPlus}/>
                        Agregar gasto
                    </button>
                </div> 
                : null 
            }
            {role == 4 ? 
                <div className="item">
                    <button>
                        <FontAwesomeIcon icon={faLocation}/>
                        Aprobar
                    </button>
                </div> 
                : null 
            }
            <div className="item">
                <div>
                    <div>Periodo:</div>
                    <DatePicker
                        selected={startDate}
                        onChange={(date)=>setStartDate(date)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                    />
                </div>
            </div>
            <div className="item">
                Total de gastos {total}
            </div>
        </div>
    )

}

GastosComunesOptions.defaultProps={
    role: 0,
    calendarSelected: {},
    calendarOnChange: ()=>{},
    total: 0
}

export default GastosComunesOptions;