import { Button, TextField } from "@material-ui/core";
import React from "react";


export const BodyInsertar = ({
  onSubmitInsertar,
  styles,
  abrirCerrarModalInsertar,
  handleSubmit,
  register,
  errors
}) => {
  
  return (
    <form action="" onSubmit={handleSubmit(onSubmitInsertar)}>
      <div className={styles.modal}>
        <h3 className="my-5">Agregar Regla</h3>

        <TextField
          className={styles.inputMaterial}
          name="description"
          label="Descripción*"
          multiline
          
          {...register('descripcion',{
            required:{
              value:true,
              message:'El campo es requerido'
            },
            minLength:{
              value:4,
              message:'La descripcion debe tener mas de 4 letras'
            }
          })}
        />
        <span className="text-danger text-small d-block mb-2">
                {errors?.descripcion?.message}
        </span>

        <br />
        {/* <TextField className={styles.inputMaterial} name="availableDays" onChange={handleChangeInsert} label="Días disponibles*" /> */}
        <TextField
          className={styles.inputMaterial}
          name="phone"
          label="Teléfono*"
          type="number"
          {...register('phone',{
            required:{
              value:true,
              message:'El campo es requqerido'
            },
            minLength:{
              value:10,
              message:'El Telefono debe ser de 10 digitos'
            },
            MaxLength:{
              value:10,
              message:'El Telefono debe ser de 10 digitos'
            }
          })}
        />
        <span className="text-danger text-small d-block mb-2">
                {errors?.phone?.message}
        </span>

        <br />
        <br />

        <div align="right">
          <Button color="primary" type="submit">
            Insertar
          </Button>
          <Button onClick={abrirCerrarModalInsertar}> Cancelar</Button>
        </div>
      </div>
    </form>
  );
};
