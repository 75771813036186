import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlSpaces } from "../../../utils/urls";

export const deleteSpace = async (token, idSpace)=>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.delete(`${urlSpaces}/${idSpace}`, header);
        if(consulta.status === 201)
            return true;
    }catch(e){
        console.log("Error al borrar el espacio",e);
    }
    return false;
}