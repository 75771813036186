import "../users/Users.css";
import "./style/Tramites.scss";
import { Button, TextField } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ModalDetails from "../../components/pageComponents/ModalDetails";
import ModalRespuestaQueja from "../../components/pageComponents/ModalRespuestaQueja";
import TitlePage from "../../components/pageComponents/TitlePage";
import { TramitesContext } from "../../context/TramitesContext";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { userContext } from "../../context/UserContext";
import { apiUrl, urlAvatar } from "../../utils/urls";
import CustomTable from "../../components/CustomTable/TableCustom";
import { obtainTramites } from "./services/obtenerTramites";
import { updateTramite } from "./services/actualizarTramites";
import { observationTramite } from "./services/observarTramite";
import Loader from "../../components/LoaderComponent";
import { BodyRespuestaQueja } from "./bodysTramites/BodyRespuestaQueja";
import { BodyDetail } from "./bodysTramites/BodyDetail";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //   display: "grid"
  },
  iconos: {
    cursor: "pointer",
  },
  inputMaterial: {
    width: "100%",
  },
}));
const customerTableHead = [
  {
    title: "Fecha",
    cellStyle: {
      minWidth: 80,
      maxWidth: 80,
    },
    field: "fecha",
  },
  {
    title: "Tipo",
    cellStyle: {
      minWidth: 80,
      maxWidth: 80,
    },
    field: "tipo",
  },
  {
    title: "Proyecto",
    cellStyle: {
      minWidth: 80,
      maxWidth: 80,
    },
    field: "Proyecto",
  },
  {
    title: "Propietario",
    cellStyle: {
      minWidth: 80,
      maxWidth: 80,
    },
    field: "Propietario",
  },
  {
    title: "Estado",
    cellStyle: {
      minWidth: 80,
      maxWidth: 80,
    },
    field: "Estado",
  },
  {
    title: "Acciones",
  },
];

function Tramites() {
  const [data, setdata] = useState([]);
  const [cleanData, setCleanData] = useState([]);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalRespuestaQueja, setShowModalRespuestaQueja] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedFilesPost, setSelectedFilesPost] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [responseId, setResponseId] = useState("");
  const { dataTramites, setdataTramites } = useContext(TramitesContext);
  const { dataUser, setdataUser } = useContext(userContext);
  const [info, setInfo] = useState({
    subject: "",
    propertyId: "",
    stateId: "",
    subject: "",
    description: "",
    file: "",
  });
  const [infoResp, setInfoResp] = useState({
    subject: "",
    attached: "",
  });
  /**
   * Token de usuario
   */
  const token = localStorage.getItem("Authorization");

  const { subject, attached } = infoResp;

  const abrirCerrarModalDetails = () => {
    setShowModalDetails(!showModalDetails);
    console.log(info);
  };
  const abrirCerrarModalRespuestaQueja = () => {
    setShowModalRespuestaQueja(!showModalRespuestaQueja);
  };
  /**
   * Funcion para obtener info del usuario.
   * @param {string} user
   */
  const seleccionarUser = (user) => {
    setInfo(user);
    console.log("Info user:", user);
    abrirCerrarModalDetails();
  };

  const seleccionarUser2 = () => {
    // console.log(info.property.block);
    abrirCerrarModalDetails();
    abrirCerrarModalRespuestaQueja();
  };
  const seleccionarUser3 = () => {
    // console.log(info.property.block);
    abrirCerrarModalDetails();
  };

  /**
   * Obtener lista de tramites
   */
  const obtenerTramites = useCallback(async () => {
    setLoading(true);
    const consulta = await obtainTramites(token);
    if (consulta) setdataTramites(consulta);
    setLoading(false);
  }, []);
  /**
   * Mandar observaciones
   */
  const mandarObservacion = async () => {
    setLoading(true);
    abrirCerrarModalRespuestaQueja();
    let body = null;
    let type = "";
    if (selectedFilesPost !== undefined) {
      body = new FormData();
      body.append("file", selectedFilesPost);
      body.append("ownerProcessId", info.id);
      body.append("description", infoResp.subject);

      type = "file";
    } else {
      body = { ownerProcessId: info.id, description: infoResp.subject };
    }
    const consulta = await observationTramite(body, type, token);
    if (consulta) {
      const actualizaEstatus = await updateTramite(
        info.id,
        { stateId: "11" },
        token
      );
      if (actualizaEstatus) {
        await obtenerTramites();
        setSelectedImage(undefined);
        setInfoResp({ subject: "", attached: "" });
      } else {
        abrirCerrarModalRespuestaQueja();
      }
    } else {
      abrirCerrarModalRespuestaQueja();
    }
    setLoading(false);
  };
  /**
   * Actualizar el tramite
   */
  const actualizarTramite = async (info) => {
    setLoading(true);
    const consulta = updateTramite(info.id, { stateId: "4" }, token);
    if (consulta) {
      obtenerTramites();
      abrirCerrarModalDetails();
    }
    setLoading(false);
  };
  useEffect(() => {
    obtenerTramites();
  },[]);

  const nuevaData = () => {
    let newData = [];
    const newData2 = dataTramites.filter(
      (itm) => itm?.proyect?.property?.users?.[0]?.name !== undefined
    );

    for (let i = 0; i < newData2.length; i++) {
      newData.push({
        fecha: newData2[i].proyectDate,
        tipo: newData2[i].proyect.project_type.name,
        Proyecto: newData2[i].proyect.name,
        Descripcion: newData2[i].proyect.description,
        Documento: newData2[i]?.proyect?.property?.users?.[0]?.document,
        Propietario:
          newData2[i]?.proyect?.property?.users?.[0]?.name +
          " " +
          newData2[i]?.proyect?.property?.users?.[0]?.lastName,
        file: newData2[i].attachments[0],
        Estado: newData2[i].state.name,
        id: newData2[i].id,
        Propiedad: newData2[i].proyect.property,
      });
    }

    setCleanData(newData);
    console.log(newData);
  };
  useEffect(() => {
    nuevaData();
  }, [dataTramites]);
  const styles = useStyles();
  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const onSubmitInsertar = (e) => {
    const{subject}=e
     
    if (subject == "") {
      setError(true);
    } else {
      setError(false);
      mandarObservacion();
      setInfoResp({subject})
    }
  };
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
      console.log(e.target.files[0]);
      setSelectedFilesPost(e.target.files[0]);
    }
  };

  const handleChangeInsert = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeInsert2 = (e) => {
    setInfoResp({
      ...infoResp,
      [e.target.name]: e.target.value,
    });
  };

  
  return (
    <div>
      <div>
        <TitlePage titulo="Tramites" />
        <div className="mt-10">
          <CustomTable columns={customerTableHead}>
            {cleanData.map((itm) => (
              <tr>
                <td className="tdHeadContent">{itm.fecha}</td>
                <td className="tdHeadContent">{itm.tipo}</td>
                <td className="tdHeadContent">{itm.Proyecto}</td>
                <td className="tdHeadContent">{itm.Propietario}</td>
                <td className="tdHeadContent">{itm.Estado}</td>
                <td className="tdHeadContent">
                  <button
                    onClick={() => seleccionarUser(itm)}
                    className="buttonAction"
                  >
                    <span className="material-icons find">find_in_page</span>
                  </button>
                </td>
              </tr>
            ))}
          </CustomTable>
        </div>
      </div>
      <Loader active={loading} title="Cargando datos de tramites" />
      <ModalDetails
        showModalDetails={showModalDetails}
        functionShow={abrirCerrarModalDetails}
        info={info}
        bodyDetails={<BodyDetail
          styles={styles}
          info={info}
          seleccionarUser2={seleccionarUser2}
          urlAvatar={urlAvatar}
          actualizarTramite={actualizarTramite} />}
      />
      <ModalRespuestaQueja
          showModalRespuestaQueja={showModalRespuestaQueja}
          functionShow={abrirCerrarModalRespuestaQueja}
          info={info}
          bodyRespuestaQueja={<BodyRespuestaQueja
              onSubmitInsertar={onSubmitInsertar} 
              
              handleChangeInsert2={handleChangeInsert2} 
              styles={styles} 
              imageChange={imageChange} 
              selectedImage={selectedImage} 
              removeSelectedImage={removeSelectedImage} 
              abrirCerrarModalRespuestaQueja={abrirCerrarModalRespuestaQueja}
              info={info} />}
      />
    </div>
  );
}

export default Tramites;
