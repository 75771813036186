import '../users/Users.css'

import CalendarioComponent from '../../components/pageComponents/Calendario'
import React, { useContext, useEffect, useState } from 'react'
import styled from "@emotion/styled";
import esLocale from '@fullcalendar/core/locales/es';
import { CalendarioContext } from '../../context/CalendarioContext';
import axios from 'axios';
import { apiUrl } from '../../utils/urls';
import { getHeaders } from '../../utils/methods';
import moment from "moment";
import Loader from '../../components/LoaderComponent';

export const StyleWrapper = styled.div`
  .fc{
    background-image: none;
    height: 70vh;
    
}
`


const Calendar = () => {
    //States
    const [isLoading,setIsLoading] = useState(false);
    //Context
    const {dataCalendario, setdataCalendario} = useContext(CalendarioContext);
    const token = localStorage.getItem('Authorization');
    //Carga inicial
    useEffect(()=>{
        const getInitialReservations = async () =>{
            setIsLoading(true);
            try{
                const header = getHeaders(token);
                const consulta = await axios(`${apiUrl}/reservation`,header);
                if(consulta.status === 200){
                    setdataCalendario(consulta.data.data);
                }
            }catch(e){
                console.error("Error desconocido:",e);
            }
            setIsLoading(false);
        }
        getInitialReservations();
    },[]);
    /**
     * @function onClickEvent
     * @return {object} null
     */
    const onClickEvent = (e)=>{
        const id = e.event.id;
        const find = dataCalendario.find((el)=>el.id == id);
        const data = e.event._def;
        const dataExtended = data?.extendedProps;
        alert(
            'Propietario: ' + dataExtended?.user?.name + " " + dataExtended?.user?.lastName +  '.\n' +
            "Espacio: " + data.title + '.\n'+ 
            "Comienza: " + moment(find.start).format('DD-MM-YYYY HH:mm')  + '.\n'+
            "Termina: " + moment(find.end).format('DD-MM-YYYY HH:mm')  + '.\n'        
        )
    }
    return (

        <div className="Container">
            <StyleWrapper>
                <CalendarioComponent
                    esLocale={esLocale}
                    dataCalendar={dataCalendario}
                    onClickCalendar={onClickEvent}
                />
            </StyleWrapper>
            <Loader title="Cargando los eventos" active={isLoading}/>
        </div>
    )
}

export default Calendar
