import axios from "axios";
import { getHeaders } from "../../../utils/methods";
import { urlFormalities } from "../../../utils/urls";

export const getFormalitiesOwner = async (token) =>{
    try{
        const header = getHeaders(token);
        const consulta = await axios.get(urlFormalities, header);
        if(consulta.status === 200){
            const data = consulta.data.data;
            const filter = data.filter(itm=>itm?.proyect?.property?.users?.[0]?.name !== undefined && itm?.state?.id !== 4);
            return filter;
        }
    }catch(e){
        alert("Error al obtener los tramites");
        console.log("Error Tramites:",e);
    }
    return 0;
}