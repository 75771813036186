import axios from "axios"
import { getHeaders } from "../../../utils/methods"
import { urlUsefulInformation } from "../../../utils/urls"

export const deleteTelefono = async (id, token, info) =>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.delete(`${urlUsefulInformation}/${id}`, header, info);
        if(consulta.status === 201){
            return true;
        }else{
            return false;
        }
    }catch(e){
        alert("Error al borrar la consulta");
        return false;
    }
}