import { money } from "../../../../utils/format";

const InformationCostCalculus = ({
    step,
    generalCost,
    participation,
    totalCobranza,
    gastosGeneralesRepartidos,
    costoKW,
    costoLT
})=>{
    const getGeneralTitle = ()=>{
        switch(step){
            case 1:
                return "Gastos de servicios generales";
            case 2:
                return "Gasto total de energía";
            case 3:
                return "Gasto total de agua";
            case 4:
                return "Gasto general acumulado";
        }
    }
    return(
        <div className="mt-5 text-gray-400 flex justify-end column">
            <div>{getGeneralTitle()}: { money.format(generalCost) }</div>
            { step === 1 ? <div>Participación total del condominio: {participation} %</div> : null }
            { step === 1 ? <div>Total cobranza: { money.format(totalCobranza) }</div> : null }
            { step === 1 ? <div>Gasto total repartido: { money.format(gastosGeneralesRepartidos) }</div> : null }
            { step === 2 ? <div>Costo unitario (KW): { money.format(costoKW) }</div> : null }
            { step === 3 ? <div>Costo unitario (Lt): { money.format(costoLT) } </div> : null}
        </div>
    )
}

InformationCostCalculus.defaultProps={
    step: 1,
    generalCost: "",
    participation: 0,
    totalCobranza: "",
    gastosGeneralesRepartidos: "",
    costoKW: "",
    costoLT: 0
}

export default InformationCostCalculus;