import "../users/Users.css";
import "./styles/archivos.css";

import { Button, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ModalEditar from "../../components/pageComponents/ModalEditar";
import ModalEliminar from "../../components/pageComponents/ModalEliminar";
import ModalInsertar from "../../components/pageComponents/ModalInsertar";
import Table2 from "../../components/Table2";
import TitlePage from "../../components/pageComponents/TitlePage";
import { UtilInfoContext } from "../../context/UtilInfoContext";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { userContext } from "../../context/UserContext";
import { apiUrl, urlAvatar } from "../../utils/urls";
import { checkFileIsImage, checkFileIsValid } from "../../utils/methods";
import Swal from "sweetalert2";
import { BodyInsertar } from "./bodys/BodyInsertar";
import { BodyEditar } from "./bodys/BodyEditar";
import { BodyEliminar } from "./bodys/BodyEliminar";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //   display: "grid"
  },
  iconos: {
    cursor: "pointer",
  },
  inputMaterial: {
    width: "100%",
  },
}));

const customerTableHead = [
  {
    title: "Fecha",
    render: (data) =>
      data.publicationDate.split(" ")[0].split("-").reverse().join("-"),
  },
  {
    title: "Tamaño",
    render: (data) => parseInt(data.size / 1000) + " kb",
  },
  {
    title: "Descripción",
    field: "description",
  },
];

function Archivos() {
  const [data, setdata] = useState([]);
  const [showModalInsertar, setShowModalInsertar] = useState(false);
  const [showModalEditar, setShowModalEditar] = useState(false);
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedFilesPost, setSelectedFilesPost] = useState();
  const [loading, setLoading] = useState(false);
  const [pathImg, setPathImg] = useState();
  const { dataUser, setdataUser } = useContext(userContext);
  const { dataUtilInfo, setdataUtilInfo } = useContext(UtilInfoContext);
  //Control de imagen
  const [isImageFile, setIsImageFile] = useState(false);

  const [info, setInfo] = useState({
    publicationDate: "",
    internalCode: "",
    description: "",
    internalCode: "1",
    phone: "1",
  });

  const [error, setError] = useState(false);

  const { description, publicationDate } = info;

  const baseUrl = `${apiUrl}/useful-information`;
  const handleChangeInsert = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const seleccionarUser = (user, caso) => {
    setInfo(user);
    console.log(info);

    caso === "Editar" ? abrirCerrarModalEditar() : abrirCerrarModalEliminar();
  };

  const buscarCotizacion = async () => {
    const url = `${apiUrl}/useful-information`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };

    const rtdo = await axios.get(url, { headers });

    setdataUtilInfo(
      rtdo.data.data.filter(
        (artista) => artista.phone === null || artista.phone === "1"
      )
    );
    setdataUser(JSON.parse(localStorage.getItem("user")));
  };
  // }
  useEffect(() => {
    if (dataUtilInfo.length === 0) {
      console.log(dataUtilInfo.length);
      buscarCotizacion();
    } else {
      console.log(dataUtilInfo.length);
      return;
    }
  }, []);

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const peticionPost = async (e) => {
    const f = new FormData();

    if (selectedFilesPost) {
      f.append("attached", selectedFilesPost);
    }

    f.append("publicationDate", info.publicationDate);
    f.append("description", e.description);
    f.append("internalCode", "1");
    f.append("phone", "1");

    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };

    const url1 = `${apiUrl}/useful-information`;
    await axios
      .post(url1, f, { headers })
      .then((response) => {
        // setdata(data.concat(response.data));
        // abrirCerrarModalInsertar();

        setSelectedFilesPost([]);
        console.log("exito -1");
      })
      .catch((error) => {
        console.log(error);

        setSelectedFilesPost([]);
      });

    // console.log(filesImg);
    buscarCotizacion();
  };

  // const peticionPost=async()=>{
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),

  // }
  //     await axios.post(baseUrl, info, {headers})
  //     .then(response=>{
  //       // setdata(data.concat(response.data));
  //       abrirCerrarModalInsertar();
  //     }).catch(error=>{
  //       console.log(error);
  //     })
  //    buscarCotizacion()
  //   }
  const peticionDelete = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };
    await axios
      .delete(baseUrl + "/" + info.id, { headers }, info)
      .then((response) => {
        // setdata(data.filter(artista=>artista.id!==info.id));
        abrirCerrarModalEliminar();
      })
      .catch((error) => {
        console.log(error);
      });
    buscarCotizacion();
  };
  const eliminarImg = async () => {
    console.log("borrandoimg");
    const url = `${apiUrl}/useful-information`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };

    await axios
      .put(url + "/" + info.id, { atached: null }, { headers })
      .then((response) => {
        console.log("cambiado a null");
      })
      .catch((error) => {
        console.log(error);
      });
    setPathImg();
  };

  const peticionPut = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };
    const info2 = {
      publicationDate: info.publicationDate,

      description: info.description,
    };

    await axios
      .put(baseUrl + "/" + info.id, info2, { headers: headers })
      .then((response) => {
        abrirCerrarModalEditar();
      })
      .catch((error) => {
        console.log(error);
      });

    buscarCotizacion();
  };

  const onSubmitInsertar = (e) => {
    const{description}=e

    if (description.trim()=="" || !info.publicationDate) {
      setError(true);
      return;
    } else {
      setError(false);
      setInfo({
        description: description,
      })
      peticionPost(e);
      setPathImg();
      setSelectedImage();
      setInfo({
        publicationDate: "",
        description: "",
      });

      abrirCerrarModalInsertar();
    }
    buscarCotizacion();
  };
  const onSubmitEditar = (e) => {
    e.preventDefault();
    peticionPut();
  };

  const abrirCerrarInsertar = () => {
    abrirCerrarModalInsertar();
    setPathImg();
    setSelectedImage();
  };

  const resetFiles = () => {
    setSelectedFilesPost(null);
    setSelectedImage(null);
    setIsImageFile(false);
  };
  const abrirCerrarModalInsertar = () => {
    resetFiles();
    setShowModalInsertar(!showModalInsertar);
  };

  const abrirCerrarModalEditar = () => {
    setShowModalEditar(!showModalEditar);
    resetFiles();
  };
  const abrirCerrarModalEliminar = () => {
    setShowModalEliminar(!showModalEliminar);
    resetFiles();
  };
  const styles = useStyles();

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const dataFile = e.target.files[0];
      const splitData = dataFile.name.split(".");
      const typeFile = splitData[splitData.length - 1];
      if (checkFileIsValid(typeFile)) {
        setSelectedImage(dataFile);
        setSelectedFilesPost(dataFile);
        if (checkFileIsImage(typeFile)) {
          setIsImageFile(true);
        } else {
          setIsImageFile(false);
        }
      } else {
        Swal.fire({
          title: "Extencion no permitida",
          text: "El archivo debe ser de tipo jpg, png, jpeg, pdf, mp4, avi",
          icon: "error",
        });
      }
    }
  };

  useEffect(() => {
    setPathImg(apiUrl + info.attached);
  }, [abrirCerrarModalEditar]);

  


  return (
    <div>
      <div>
        <TitlePage titulo="Información util" />
        <div className="flex justify-end ">
          <button className="btn" onClick={() => abrirCerrarModalInsertar()}>
            Agregar Info
          </button>
        </div>
        {loading ? (
          <Box sx={{ position: "absolute", left: 500, top: 500, zIndex: 1 }}>
            <CircularProgress color="success" size={80} />
          </Box>
        ) : null}
        <div className="mt-10">
          <Table2
            title=""
            columns={customerTableHead}
            data={dataUtilInfo}
            actions={[
              {
                icon: () => <i className="material-icons edit">edit</i>,
                tooltip: "Editar",
                onClick: (event, rowData) => seleccionarUser(rowData, "Editar"),
              },
              {
                icon: () => (
                  <i className="material-icons delete">highlight_off</i>
                ),
                tooltip: "Eliminar",
                // onClick: (event, rowData) => seleccionarUser(rowData, "Eliminar")
                onClick: (event, rowData) =>
                  seleccionarUser(rowData, "Eliminar"),
              },
            ]}
          />
        </div>
      </div>
      <ModalInsertar
        showmodalInsertar={showModalInsertar}
        functionShow={abrirCerrarModalInsertar}
        handleChangeInsert={handleChangeInsert}
        onSubmitInsertar={onSubmitInsertar}
        error={error}
        bodyInsertar={
          <BodyInsertar
            onSubmitInsertar={onSubmitInsertar}
            styles={styles}
            error={error}
            handleChangeInsert={handleChangeInsert}
            imageChange={imageChange}
            selectedImage={selectedImage}
            isImageFile={isImageFile}
            removeSelectedImage={removeSelectedImage}
            abrirCerrarInsertar={abrirCerrarModalInsertar}
            selectedFilesPost={selectedFilesPost}
          />
        }
      />
      <ModalEditar
        showModalEditar={showModalEditar}
        functionShow={abrirCerrarModalEditar}
        handleChangeInsert={handleChangeInsert}
        onSubmitEditar={onSubmitEditar}
        info={info}
        bodyEditar={<BodyEditar
          onSubmitEditar={onSubmitEditar} 
          styles={styles} 
          error={error} 
          handleChangeInsert={handleChangeInsert}  
          info={info} 
          abrirCerrarModalEditar={abrirCerrarModalEditar}
          urlAvatar={urlAvatar} />}
      />
      <ModalEliminar
        showModalEliminar={showModalEliminar}
        abrirCerrarModalEliminar={abrirCerrarModalEliminar}
        onSubmitEditar={onSubmitEditar}
        info={info}
        peticionDelete={peticionDelete}
        bodyEliminar={<BodyEliminar
          styles={styles} 
          info={info} 
          abrirCerrarModalEliminar={abrirCerrarModalEliminar} 
          peticionDelete={peticionDelete} />}
      />
    </div>
  );
}

export default Archivos;
