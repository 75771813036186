import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlSpaceImage } from "../../../utils/urls";

export const findImageSpace = async (token, idSpace)=>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.get(urlSpaceImage,header);
        const response = consulta.data.data;
        const filterData = response.filter(itm=>parseInt(itm.spaceId) === parseInt(idSpace));
        console.log("Filter data:",filterData, idSpace);
        if(filterData.length !== 0){
            return {
                path: filterData?.[0]?.path,
                infoImageSpace: filterData?.[0]
            }
        }
    }catch(e){
        console.log("Error al obtener la imgan:",e);
    }
    return false;
}