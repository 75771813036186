import "./Login.css"

import React, {useContext, useEffect, useState, useRef} from 'react';

import {Redirect, useHistory} from 'react-router-dom';
import axios from 'axios';
import imagen from "../IMG/Groupicono.svg"
import styled from '@emotion/styled';
import { userContext } from '../context/UserContext';
import { apiUrl } from "../utils/urls";
import logo2 from "../IMG/fincabonita.jpeg";

const Icono = styled.img`
    height: 200px;
    width: 220px;
    margin: 40px 10px;
    
`
const ContainersImg= styled.div`
 /* background-color: red; */
 /* margin-bottom: -100px; */
 display: grid;
 justify-content: center;
 align-items: center;
`



function Login() {


    const [data, setdata] = useState({});
    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const [wrongPassword, setwrongPassword] = useState(false)
    const [wrongEmail, setwrongEmail] = useState(false)
    const [notExist, setnotExist] = useState(false)
    const [redirect, setRedirect] = useState(false);
    
    const { dataUser, setdataUser } = useContext(userContext);
    const historyProvider = useHistory();

    const refInputMail = useRef(null);
    const refInputPass = useRef(null);


    const iniciarSesion = (e) => {
        e.preventDefault();


        const headers = {
            'Content-Type': 'application/json'
        }

        const data = {
            email: email,
            password: password
        }

        if (email.trim() === "" || password.trim() === "" ) {
            setnotExist(true);
            return
        } else {

            setnotExist(false);

        axios.post(`${apiUrl}/login`, data, {headers})
            .then(response => {
                const dataUser = response?.data?.user;
                const token = response?.data?.token;
                const role = dataUser?.roleId !== "3";
                if(token && role) {
                    localStorage.setItem('Authorization', response.data.token)
				    localStorage.setItem('user', JSON.stringify(response.data.user)) 
                    historyProvider.push("/");
                    setdataUser(dataUser);
                    window.location.reload();
                }
                if (response.data.user.roleId !== "3") {
                    setRedirect(true);
              
                }else{
                    setwrongPassword(true);
                }
             })
             .catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        console.log('danger', 'Contraseña inválida', 8)
                        setwrongPassword(true)
                    }
                    else if(err.response.status === 404){
                        console.log('warning', `El usuario ${data.email} no existe`)
                        setwrongEmail(true)
                    }
                    else {
                        console.log('warning', 'Hubo un error al intentar inciar sesion')
                        setnotExist(true)
                    }
                }
             })}
    }

    /*if (redirect) {
        return <Redirect to="/Home"/>;
    }*/


    return (
     
            <div className="Containers">
                    <div>
{/*                         
                        </ContainersImg> */}
                                        
                        <div className="formContainer">
                            <div className="formImg">
                                <img src={logo2} title="Logo" alt="Logo" className="logoIcon"/>
                            </div>
                            <form className="FormForm"  onSubmit={iniciarSesion}>
                                <div className="inputContainer">
                                    <input className="inputForm" type="text" ref={refInputMail} name="email" onChange={e=> setemail(e.target.value)} value={email}/>
                                    <label className="formLabel" onClick={()=>refInputMail.current.focus()}>Correo</label>
                                    <span className="formLine"/>
                                </div>
                                <div className="inputContainer">                                   
                                    <input className="inputForm" type= "password" ref={refInputPass} name="password" onChange={e=> setpassword(e.target.value)}  value={password}/>
                                    <label className="formLabel" onClick={()=>refInputPass.current.focus()}>Contraseña</label>
                                    <span className="formLine"/>
                                </div>
                                <button className='boton' type="submit">LOGIN</button>
                                <div className="ForgotContainer">
                                    <a href="" >
                                        <h3 className="forgot">Forgot password? </h3>
                                        </a>
                                </div>
                                        {/* <ContainersImg> */}
                                        {/* <Icono src={imagen} alt="" /> */}
                                        { wrongPassword ? <h2 className="warningContent maxContent">Usuario/Contraseña incorrecta</h2> : null}
                                        { wrongEmail ? <h2 className="bg-red-500 text-white py-3 px-5 maxContent">Usuario incorrecto</h2> : null}
                                        { notExist ? <h2 className="warningContent maxContent">Complete todos los datos</h2> : null}
                            </form>
                        </div>
      
                        
                    </div>
                    
                    { data ? (<h3 className="forgot">{data.name} </h3>): null}
                
            </div>


)
}

export default Login

    