import { CircularProgress } from "@material-ui/core";
import "./styles/loader.scss";

const Loader = ({ title, active })=>{
    if(!active)
        return null;

    return(
        <>
            <div className="cortina">
            </div>
            <div className="loaderView">
                <CircularProgress size={80} color="success"/>
                <div className="title">{title}</div>
            </div>
        </>
    )

}
Loader.defaultProps = {
    title:"",
    active: false
}

export default Loader;