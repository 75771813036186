


// falta loi de mail en editar

import "../users/Users.css";

import {Button, Modal, TextField} from '@material-ui/core';
import {
    Link,
    NavLink,
} from "react-router-dom";
import React, {useContext, useEffect, useState} from 'react';

import $ from "jquery";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import DatePicker from "react-datepicker";
import { Day } from "@material-ui/pickers";
import ModalEditar from '../../components/pageComponents/ModalEditar';
import ModalEliminar from '../../components/pageComponents/ModalEliminar';
import ModalInsertar from "../../components/pageComponents/ModalInsertar"
import Table2 from '../../components/Table2';
import { TableCell } from '@mui/material';
import TitlePage from '../../components/pageComponents/TitlePage';
import XLSX from 'xlsx'
import axios from "axios"
import { format } from 'date-fns'
import {makeStyles} from '@material-ui/core/styles';
import moment from "moment";
import { userContext } from '../../context/UserContext';
import TabOptionsRoute from "./routes/tabsRoutes";
import { apiUrl, urlCollectionExpense, urlPropertyUser, urlTotalWaterExpediture, urlWaterExpenditure } from "../../utils/urls";
import { useDispatch, useSelector } from "react-redux";
import { setGastosPagosPeriodoInRedux } from "../../redux/actions/GastosyPagosActions";
import { getHeaders } from "../../utils/methods";
import CustomTable from "../../components/CustomTable/TableCustom";
import { money } from "../../utils/format";
import { getSubtotalWater, getTotalWater } from "./methods/maths";
import Loader from "../../components/LoaderComponent";

// import { Switch } from 'antd';

const useStyles = makeStyles((theme) => ({
    modal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    iconos:{
      cursor: 'pointer'
    }, 
    inputMaterial:{
      width: '100%'
    }
  }));


  
  

  function Prueba() {
    const [data, setdata] = useState([]);
    const [data2, setdata2] = useState([]);
    const [data3, setdata3] = useState([]);
    const [data4, setdata4] = useState([]);
    const [base, setBase] = useState(0);
    const [fecha, setFecha] = useState("");
    const [totalAmount, setTotalAmount] = useState("");
    const [unitCost, setunitCost] = useState("");
    const [total, setTotal] = useState(0);
    const [showModalInsertar, setShowModalInsertar] = useState(false);
    const [showModalEditar, setShowModalEditar] = useState(false);
    const [showModalEliminar, setShowModalEliminar] = useState(false);
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState();
    const [selectedFilesPost, setSelectedFilesPost] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [year,setYear]=useState('all')
    const { dataUser, setdataUser } = useContext(userContext);
    const [filteredData,setFilteredData]=useState([])
    const [filteredData2,setFilteredData2]=useState([])
    const [linkEncode, setlinkEncode] = useState("");
    
    const [exito, setExito] = useState(false);
    const [info, setInfo] = useState({
        consume: "",
        transactionCost: "0"
    })
    const [info2, setInfo2] = useState({
        consume: "",
        date:format(new Date(), "yyyy/MM/dd"),
        amount:""
    })
    const token = localStorage.getItem('Authorization');
    const customerTableHead2 = [


      {
          title:"Id de Propiedad",
          field: "id"
      },
      {
          title:"Nombre",
          field: "nombre"
      },
      {
          title:"Apellido",
          field: "apellido"
      },
      {
          title:"DNI",
          field: "documento"
      },
      {
          title:"Consumo Lts",
          field: "consume"
      },
        {
            title:"Fecha",
                            cellStyle: {
        minWidth: 50,
        maxWidth: 50
      },
            field: 'date',
       
            render: data => fecha  
        }
      ]
    
    const customerTableHead = [
    
      {
        title:"Propietario"
      },
      {
        title:"Mz."     
      },
      {
        title:"Lte."
      },
      {
        title:"Periodo"   
      },
      {
        title:"Consumo (M3)"
      },
      {
        title:"SubTotal"
      },
      {
        title:"Cobranza(S/)"
      },
      {
        title:"Total"
      }
    ]

    const{ document } = info;

     /**
     * Reducers necesarios para la integridad del sistema
     */
    const periodoStore = useSelector((store)=>store.PeriodoControllerReducer);
    /**
     * Acciones para el control de los periodos
     */
    let dispatch = useDispatch();
    /**
     * Inicializamos la aplicación
     */
    useEffect(() => {
      /**
      * Mandar al redux la fecha actual
      */
      const getPeriod = () =>{
          if(periodoStore.periodo === ""){
            const actualDate = new Date();
            const tempPeriod = moment(actualDate).format("YYYY-MM");
            dispatch(setGastosPagosPeriodoInRedux(tempPeriod));
          }
      }
      /**
       * Obtenemos datos iniciales
       */
      const init = async ()=>{
        setLoading(true);
        await buscarCotizacion();
        await buscarTotalwater();
        await buscarCobranza();
        await getWaterExpediture();
        setLoading(false);
      }
      init();
      getPeriod();
    }, []);
  
    const baseUrl=`${apiUrl}/property`;
    const handleChangeInsert = (e) => {
      setInfo({
        ...info,
        [e.target.name]: e.target.value
    })

    }
    const handleChangeInsert2 = (e) => {
      setInfo2({
        ...info2,
        [e.target.name]: e.target.value
    })

    }

    const{consume, amount,  date } = info2;

   
        
    const buscarCotizacion = async() => {

      const url = `${apiUrl}/property-user`;

      const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
      }

      const rtdo = await axios.get(url, {headers})
      const rtdo2 = rtdo.data.data
     const rtdo3=  rtdo2.map(obj=> ({ id:obj.propertyId, nombre: obj.user.name, apellido: obj.user.lastName, documento: obj.user.document , consumo: 0}))
      
      
      setdata(rtdo3)    
      setdataUser(JSON.parse(localStorage.getItem('user')))

  }
  const getWaterExpediture = async() => {
    try{
      const header = getHeaders(token);
      const consulta = await axios.get(urlWaterExpenditure, header)
      const response = consulta.data.data;
      setdata4(response);
    }catch(e){
      alert("Error al obtener los resultados del periodo");
      console.log("Error expediture:",e);
    }
  }

  const buscarTotalwater = async() => {
    try{
      const header = getHeaders(token);
      const consulta = await axios.get(urlTotalWaterExpediture, header)
      const response = consulta.data.data;
      setdata2(response);//Datos de los gastos totales de agua   
    }catch(e){
      alert("Error al obtener los gastos de agua");
      console.log("Erro water:",e);
    }
  }

  const buscarCobranza = async() => {
    try{
      const header = getHeaders(token);
      const consulta = await axios.get(urlCollectionExpense, header);
      const response = consulta.data.data;
      const dataCobranza = response.filter(artista=> artista.id === 7);
      setdata3(dataCobranza[0]);
    }catch(e){
      alert("Error al obtener los datos de cobranza");
      console.log("Error cobranza:",e)
    }
  }

  /**
   * Controlamos cada que cambia fecha o datos relacionados
   */
  useEffect(()=>{
    const findWithPeriod = ()=>{
      /**
       * Obtenemos información general
       */
      const filterTotalWater = data2.filter(itm=>itm.date.slice(0,7) === periodoStore?.periodo);
      setTotalAmount(filterTotalWater?.[0]?.amount);
      setunitCost(filterTotalWater?.[0]?.consume);
      setFilteredData2(filterTotalWater);
      /**
       * Filtramos resultados de los propietarios
       */
      const dataFilter = data4.filter(itm=>itm.date.slice(0,7) === periodoStore?.periodo);
      
      setFilteredData(dataFilter);
    }
    findWithPeriod();
  },[periodoStore?.periodo, data2, data4]);
  


  useEffect(()=>{
    setFilteredData(data4.filter(dt=>dt.date.slice(0, 7) === year))
    setFilteredData2(data2.filter(dt=>dt.date.slice(0, 7) === year))
   
  },[year])
      
  const SaveData = () => {
    if (filteredData2.length > 0) {
      setTotalAmount(filteredData2[filteredData2.length - 1].amount)
      setunitCost(filteredData2[filteredData2.length - 1].consume)
    }
  }




    

    const seleccionarUser=(user, caso)=>{
        setInfo(user);
        console.log(user);
        (caso==="Editar")?abrirCerrarModalEditar()
        : 
        abrirCerrarModalEliminar() 
      }

      const peticionPost2=async()=>{
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
    
      }
          await axios.post(`${apiUrl}/total-water-expenditure`, info2, {headers})
          .then(response=>{
            // setdata(data.concat(response.data));
            abrirCerrarModalInsertar();
          }).catch(error=>{
            console.log(error);
          })
          buscarTotalwater()
          buscarCotizacion()
        }

        const peticionPost=async(e)=>{
          //console.log("post2");
     
          const f = new FormData()   
          //console.log(info);
          // console.log(selectedFilesPost.length > 0);
            
                  // if (selectedFilesPost) {

          const dateInsert = moment(new Date(periodoStore.periodo+"-10")).format("YYYY-MM-DD")
                    
          f.append("file", selectedImage)
          f.append("consume", "20")
          f.append("unitCost", "10")
          f.append("transactionCost", "3.25")
          f.append("unit", "m3")
          f.append("date", dateInsert)
        
            const headers = {
              'Content-type': 'multipart/form-data',
              'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
              
          }

          const url1= `${apiUrl}/import-water-expenditures`

          try{
            const response = await axios.post(url1, f, {headers})
            console.log(response)
          }catch(e){
            console.log("Error insersion:",e);
          }
        
            /*
              await axios.post(url1, f, {headers})
              .then(response=>{
                // setdata(data.concat(response.data));
                // abrirCerrarModalInsertar();
                console.log("exito -1");
                setExito(!exito)
              }).catch(error=>{
                console.log(error);
                setSelectedImage()
              })
        
          // console.log(filesImg);*/
     
          }
          useEffect(() => {
            getWaterExpediture();
          }, [exito]);
       
       


        const peticionDelete=async()=>{
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
      
        }
          await axios.delete(baseUrl+"/"+info.id, {headers}, info) 
          .then(response=>{
            // setdata(data.filter(artista=>artista.id!==info.id));
           
        }).catch(error=>{ 
            console.log(error);
        })
        buscarCotizacion()
        abrirCerrarModalEliminar();
        }

        const peticionPut=async()=>{       

          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
      
        }
          await axios.put(`${apiUrl}/condominium-expense`+"/"+info.id,  info , {headers: headers})
          .then(response=>{

            

            abrirCerrarModalEditar();
           
          }).catch(error=>{
            console.log(error);
          })
         buscarCotizacion()
        }
      


    const onSubmitInsertar = (e) => {

        e.preventDefault();

        if (consume.trim() === ""||amount.trim() === "" ) {
        
         setError(true);
         return
        }else {
            setError(false);

            peticionPost2()
            setInfo2({
              consume: "",
              date:periodoStore?.periodo+'-01',
              amount:""        
            });
            abrirCerrarModalInsertar()
            buscarCotizacion()
            buscarTotalwater()
            SaveData()
        }
    }
    const onSubmitEditar = (e) => {

      e.preventDefault();       

            peticionPost();
            abrirCerrarModalEditar()
            setSelectedImage()
        }

    
    const abrirCerrarModalInsertar = () => {
          
        setShowModalInsertar(!showModalInsertar)
      }

      const abrirCerrarModalEditar=()=>{
        setShowModalEditar(!showModalEditar);
        setSelectedImage()
      }
      const abrirCerrarModalEliminar=()=>{
        setShowModalEliminar(!showModalEliminar);
      }

      function fnExcelReport()
			{

        setlinkEncode(encodeURIComponent(tab_text))
				var todayDate = new Date().toISOString().slice(0, 10);

				var utf = "<meta http-equiv='content-type' content='application/vnd.ms-excel; charset=UTF-8' > ";
				var name_file = "<head><meta name='content-disposition' content='inline; filename=filename.xls'><head>";
			    var tab_text=name_file+ utf+ "<table border='2px'><tr bgcolor='#87AFC6'>";
			    var textRange; var j=0;	


			    var link = document.getElementById('link');
		        
		     var tab_text=  "<table border='2px'>"+
         "<tr>"+
             "<th rowspan='2'>id</th>"+
             "<th rowspan='2'>nombre</th>"+
             "<th rowspan='2'>documento</th>"+
             "<th rowspan='2'>consumo</th>"+
         "</tr><table/>"; 
			    // link.href='data:application/vnd.ms-excel,' + encodeURIComponent(tab_text);
			    // link.download='Reporte_Detallado_De_Documentos_Por_Vencer '+todayDate+'.xls';
			    // link.click();
          console.log(encodeURIComponent(tab_text));
			 
			}
      const styles= useStyles();

      const bodyInsertar=(
        <form action="" onSubmit={onSubmitInsertar}>
          <div className={styles.modal}>
            <h3 className="my-5">Agregar detalles de Consumo y gestión</h3>
            { error ? <h4 className=" text-red-700">Completar todos los campos (*) del formulario</h4> : null }
            <label htmlFor="">Monto total*</label> <br />
            <input type="number" name="amount" onChange={handleChangeInsert2}  label="Monto total*" step="any"/>
            {/* <TextField className={styles.inputMaterial} name="amount" onChange={handleChangeInsert2}  label="Monto total*" type="number" step="0.1"/>           */}
              <br />
              <br />
              <label htmlFor="">Costo Unitario Mt<sup>3</sup></label>
            <input className={styles.inputMaterial} name="consume" onChange={handleChangeInsert2} label="Costo Unitario Lts*" type="number" step="any"/>
            {/* <TextField className={styles.inputMaterial} name="amount" onChange={handleChangeInsert2}  label="Monto total*" type="number" />           */}
        
            {/* <TextField className={styles.inputMaterial} name="consume" onChange={handleChangeInsert2} label="Costo Unitario Lts*" type="number" /> */}
            <br />
              <br />
              
              {/* <label htmlFor="">Fecha de factura*</label>
              <br /> */}
              {/* <br />
              <input type="date" className={styles.inputMaterial} name="date" onChange={handleChangeInsert2} label="Fecha de factura*"  /> */}

              
            <br /><br />
            <div align="right">
              <Button color="primary" type="submit" >Insertar</Button>
              <Button onClick= {abrirCerrarModalInsertar}> Cancelar</Button>
            </div>
          </div>
        </form>
      )

      const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          setSelectedImage(e.target.files[0]);
          console.log(e.target.files[0]);
          // setSelectedFilesPost(e.target.files[0])
        }
    };

      const bodyEditar=(
        <form action="" onSubmit={onSubmitEditar}>
          <div className={styles.modal}>
            <h3 className="my-5">Adjuntar Excel para su importación</h3>
            { error ? <h4 className=" text-red-700">Completar todos los campos del formulario</h4> : null }
            { error ? <h4 className=" text-red-700">Completar todos los campos (*) del formulario</h4> : null }
            { error ? <h4 className=" text-red-700">Completar todos los campos (*) del formulario</h4> : null }
            {/* <TextField className={styles.inputMaterial} name="consume" onChange={handleChangeInsert} label="Lts consumidos*" type="number" /> */}
     
            <div className="mt-3"> Periodo: { periodoStore.periodo }</div>

            <div className='mt-5'>
              {/* <label>Choose File to Upload: </label> */}
              <input type="file" onChange={imageChange} id="file" />
              <div className="label-holder">
                <label htmlFor="file" className="label">
                  <i className="material-icons">note_add</i>
                </label>
              </div>
             
            </div>
            
            <br/>
            {selectedImage && (
              <div className='eliminarImg'>
                <h4>{selectedImage.name}</h4>
  
                {/* <button onClick={removeSelectedImage} style={styles.delete}>
                  Eliminar
                </button> */}
              </div>
            )}
  
     
            <br /><br />
            <div align="right">
              <Button color="primary" type="submit" >Importar</Button>
              <Button onClick= {abrirCerrarModalEditar}> Cancelar</Button>
            </div>
          </div>
        </form>
      )

      const bodyEliminar=(
        <div className={styles.modal}>
          <p>Estás seguro que deseas eliminar  <b>{info&&info.name}</b>? </p>
          <div align="right">
            <Button color="secondary" onClick={()=>peticionDelete()}>Sí</Button>
            <Button onClick={()=>abrirCerrarModalEliminar()}>No</Button>
    
          </div>
    
        </div>
      )

     
      const downloadExcel= async ()=>{
        let dataExel = null;
        if(filteredData.length > 0){
          dataExel = filteredData.map(itm=>({
            id: itm.propertyId,
            nombre: itm?.property?.users?.[0]?.name,
            apellidos: itm?.property?.users?.[0]?.lastName,
            manzana: itm?.property?.block?.toUpperCase(),
            lote: itm?.property?.lot?.toUpperCase(),
            documento: itm?.property?.users?.[0]?.document,
            consumo: 0
          }));
        }else{
          try{
            const header = getHeaders(token);
            const consulta = await axios.get(urlPropertyUser, header)
            const data = consulta.data.data
            dataExel =  data.map(obj=> ({ 
              id:obj.propertyId, 
              nombre: obj.user.name, 
              apellido: obj.user.lastName, 
              manzana: obj.property.block,
              lote: obj.property.lot,
              documento: obj.user.document, 
              consumo: 0
            }));
          }catch(e){
            console.log("Error al consultar Agua Usuarios:",e)
          }
        }
        const workSheet=XLSX.utils.json_to_sheet(dataExel)
        const workBook=XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook,workSheet,"Consumo de agua")
        //Binary string
        XLSX.write(workBook,{bookType:"xlsx",type:"binary"})
        //Download
        XLSX.writeFile(workBook,"ConsumoAgua.xlsx")
      }
    /**
     * Control de los periodos
     */
    const _handleDate = (date)=>{
      const periodo = moment(date).format("YYYY-MM");
      dispatch(setGastosPagosPeriodoInRedux(periodo));
    }
    const _handleSelectPeriodCalendar = ()=>{
      if(periodoStore.periodo !== ""){
        return new Date(periodoStore.periodo+"-20");
      }else{
        return new Date();
      }
    }

    console.log()

    return (
        <div>
        <div>
          <TitlePage titulo="Gasto de Agua" />
          <TabOptionsRoute/>
          <div className="pickFecha mt-10 ">
            <div className="flex">
              <h3>Periodo: </h3> <br />
              <DatePicker
                wrapperClassName="datePicker"
                selected={_handleSelectPeriodCalendar()}
                onChange={_handleDate}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </div>
          </div>
          <div className="flex justify-between mt-16">
            
          {dataUser.roleId === "1" &&
          <button className="btn" onClick={()=>downloadExcel()}>
              Descargar Plantilla
            </button>}
          
     
      
            {dataUser.roleId === "1" &&
            <button className="btn" onClick={()=>abrirCerrarModalEditar()}>
              Importar Plantilla
            </button>}
            <div>

              <div className="flex justify-end mt-1 text-gray-400">

              {dataUser.roleId === "1" &&
                <button className="btn" onClick={() => abrirCerrarModalInsertar()}>
                  Agregar gasto
                </button>}
              </div>
              <div className="flex justify-end mt-1 text-gray-400">
                {/* render: data => (data.publicationDate).split(" ")[0].split("-").reverse().join("-") */}
                {filteredData2.length > 0 ? <h3>Periodo: { periodoStore?.periodo } </h3>
                  :
                  null
                }
              </div>
               

                 <div className="flex justify-end mt-1 text-gray-400">
                {filteredData2.length > 0 ?    <div className="flex justify-end mt-1 text-gray-400">

<h3>Consumo total: { money.format(totalAmount) }</h3>

</div> 
                :
                 null
                 }  
                 </div> 

<div className="flex justify-end mt-1 text-gray-400">
                {filteredData2.length > 0 ?    <div className="flex justify-end mt-1 text-gray-400">

<h3>Costo unitario (M<sup>3</sup>): { money.format(unitCost) }</h3>

</div> 
                :
                 null
                 }
                    
                    </div> 
                                                </div>
                        </div>


              
          <CustomTable columns={customerTableHead}>
            {filteredData.map(itm=>
              <tr>
                <td>{ itm?.property?.users?.[0]?.name + ' ' + itm?.property?.users?.[0]?.lastName }</td>
                <td>{itm?.property?.block}</td>
                <td>{itm?.property?.lot}</td>
                <td>{itm?.date.slice(0,7)}</td>
                <td>{itm?.consume}</td>
                <td>{money.format(getSubtotalWater(itm?.consume, unitCost))}</td> 
                <td>{money.format(data3?.amount)}</td>
                <td>{money.format(getTotalWater(itm?.consume, unitCost, data3?.amount) )}</td>   
              </tr>
            )}
          </CustomTable>
              <div className="mt-10 datagrid">
              </div>

              <div className="mt-10">
                {/*<Table2 
                    title="" 
                    columns={customerTableHead} 
                    data={filteredData}
                  />*/}
              </div>
            </div>
            <Loader title={"Cargando gastos de agua"} active={loading} />
            <ModalInsertar
            showmodalInsertar={showModalInsertar}
            functionShow= {abrirCerrarModalInsertar}
            handleChangeInsert={handleChangeInsert}
            onSubmitInsertar={onSubmitInsertar}
            error={error}
            bodyInsertar={bodyInsertar}
           
            
            />
            <ModalEditar
            showModalEditar={showModalEditar}
            functionShow= {abrirCerrarModalEditar}
            handleChangeInsert={handleChangeInsert}
            onSubmitEditar={onSubmitEditar}
            info={info}
            bodyEditar={bodyEditar}
            />
            <ModalEliminar
            showModalEliminar={showModalEliminar}
            abrirCerrarModalEliminar= {abrirCerrarModalEliminar}
            onSubmitEditar={onSubmitEditar}
            info={info}
            peticionDelete={peticionDelete}
            bodyEliminar={bodyEliminar}
            />
          
        </div>
    )
}

export default Prueba
