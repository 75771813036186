import "../users/Users.css";

import { Button, Modal, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ModalDetails from "../../components/pageComponents/ModalDetails";
import ModalRespuestaQueja from "../../components/pageComponents/ModalRespuestaQueja";
import { QuejasContext } from "../../context/QuejasContext";
import Table2 from "../../components/Table2";
import TitlePage from "../../components/pageComponents/TitlePage";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { userContext } from "../../context/UserContext";
import { apiUrl, urlAvatar } from "../../utils/urls";
import { obtainClaims } from "./services/obtenerQuejas";
import CustomTable from "../../components/CustomTable/TableCustom";
import "./Quejas.scss";
import { maxHeight } from '@mui/system';
import { data } from 'jquery';
import { BodyRespuestaQueja } from './bodys/BodyRespuestaQueja';
import { BodyDeatail } from './bodys/BodyDeatail';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: "580PX",
    maxWidth: "468px",
    //   display: "grid"
  },
  iconos: {
    cursor: "pointer",
  },
  inputMaterial: {
    width: "100%",
  },
}));
const customerTableHead = [
  {
    cellStyle: {
      minWidth: 80,
      maxWidth: 80,
    },
    title: "Fecha",
    render: (data) =>
      data.created_at.slice(0, 10).split(" ")[0].split("-").reverse().join("-"),
  },
  {
    cellStyle: {
      minWidth: 80,
      maxWidth: 80,
    },
    title: "Tipo",
    render: (data) => data.state.name,
  },
  {
    cellStyle: {
      minWidth: 150,
      maxWidth: 150,
    },
    title: "Asunto",
    field: "subject",
  },
  {
    cellStyle: {
      minWidth: 50,
      maxWidth: 50,
    },
    title: "Propietario",
    render: (data) => data?.property?.users?.[0]?.name,
  },
  {
    title: "Manzana",
    render: (data) => data?.property?.users?.[0]?.name,
  },
  {
    title: "Lote",
  },
  {
    cellStyle: {
      minWidth: 80,
      maxWidth: 80,
    },
    title: "Estado",
    render: (data) => data.state.name,
  },

  {
    cellStyle: {
      minWidth: 60,
      maxWidth: 60,
    },
    title: "Actualización",
    render: (data) =>
      data.state.updated_at
        ?.slice(0, 10)
        ?.split(" ")?.[0]
        ?.split("-")
        ?.reverse()
        ?.join("-"),
  },
];

function Quejas() {
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalRespuestaQueja, setShowModalRespuestaQueja] = useState(false);
  const [infoProperty, setInfoProperty] = useState({});
  const [infoScope, setInfoScope] = useState({});
  const [selectedImage, setSelectedImage] = useState();
  const [selectedFilesPost, setSelectedFilesPost] = useState();
  const [error, setError] = useState(false);
  const [responseId, setResponseId] = useState("");
  const [responseAsco, setResponseAsco] = useState(false);
  const { dataQuejas, setdataQuejas } = useContext(QuejasContext);
  /**
   * Token de usuario
   */
  const tokenUser = localStorage.getItem("Authorization");

  const { dataUser, setdataUser } = useContext(userContext);

  const [info, setInfo] = useState({
    subject: "",
    propertyId: "",
    stateId: "",
    description: "",
    file: "",
  });

  const abrirCerrarModalDetails = () => {
    setShowModalDetails(!showModalDetails);
  };
  const abrirCerrarModalRespuestaQueja = () => {
    setShowModalRespuestaQueja(!showModalRespuestaQueja);
  };

  const seleccionarUser = (user, caso) => {
    setInfo(user);
    abrirCerrarModalDetails()

  }
  const seleccionarUser2 = () => {
    abrirCerrarModalDetails()
    abrirCerrarModalRespuestaQueja()

  }

  useEffect(() => {
    setInfoProperty(info.property);
    setInfoScope(info.state);
  }, [abrirCerrarModalDetails]);

  /**
   * Obtener la información de las quejas.
   */
  const cargarQuejas = async () => {
    const consulta = await obtainClaims(tokenUser);
    if (consulta) {
      setdataQuejas(consulta);
    }
  };

  useEffect(() => {
    setdataUser(JSON.parse(localStorage.getItem("user")));
  }, []);
  useEffect(() => {
    if (dataQuejas.length === 0) {
      cargarQuejas();
    } else {
      return;
    }
  }, [dataUser]);

  const styles = useStyles();
  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const peticionPost2 = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };
    const info3 = {
      "complaintClaimId": info.id,
      "responseComplaintClaimId": responseId
    }
    const url2 = `${apiUrl}/response-association`
    await axios.post(url2, info3, { headers })
      .then(response => {
        console.log(response.data.data.id);
        setResponseAsco(true)
      }).catch(error => {
        console.log(error);
      })
    peticionPut()

  
  }


  const peticionPut = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };
    await axios
      .put(
        `${apiUrl}/complaint-claim` + "/" + info.id,
        { stateId: "5" },
        { headers: headers }
      )
      .then((response) => {
        console.log("actualizado");
      })
      .catch((error) => {
        console.log(error);
        
      });

    cargarQuejas();
  };

  const onSubmitInsertar = (e) => {
    //  e.preventDefault();
    if (e.subject.trim() === "") {
      setError(true);
      return;
    } else {
      setError(false);
      peticionPost(e);

      setSelectedImage();
      abrirCerrarModalRespuestaQueja();
    }
  };
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0].name);
      console.log(e.target.files[0]);
      setSelectedFilesPost(e.target.files[0]);
    }
  };

  const peticionPost = async (e) => {
    console.log("post2");

    const f = new FormData()

    console.log(info, 'INFO');
    console.log(encodeURI.subject, 'infoResp.subject');
    if (selectedFilesPost) {
      alert('if')
      f.append("attached", selectedFilesPost);
      f.append("subject", e.subject);
      f.append("complaintClaimId", info.id)
      f.append("stateId", info.stateId)
    } else {
      alert('else')
      f.append("subject", e.subject);
      f.append("complaintClaimId", info.id)
      f.append("stateId", info.stateId)
    }



    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };
    const url1 = `${apiUrl}/response`;

    await axios
      .post(url1, f && f, { headers })
      .then((response) => {
        console.log(response);
        setResponseId(response.data.data.id);
        setSelectedFilesPost([]);
        console.log("exito -1");
        peticionPost2();
      })
      .catch((error) => {
        console.log(error);
        setSelectedFilesPost([]);
      });
  };

 
  const dataQuejasOrder = dataQuejas.sort(function(a, b){
    if(a.state.name.length > b.state.name.length) { return -1; }
    if(a.state.name.length < b.state.name.length) { return 1; }
    return 0;
  })

  return (
    <div>
      <div>
        <TitlePage titulo="Quejas y Reclamos" />
        <div className="mt-10">
          <CustomTable columns={customerTableHead}>
            {dataQuejasOrder.map((itm) => (
              <tr>
                <td className="tdHeadContent">
                  {itm?.created_at.slice(0, 10)}
                </td>
                <td className="tdHeadContent">{itm?.type}</td>
                <td className="tdHeadContent">{itm?.subject}</td>
                <td className="tdHeadContent">
                  {itm?.property?.users?.[0]?.name}
                </td>
                <td className="tdHeadContent">{itm?.property?.block}</td>
                <td className="tdHeadContent">{itm?.property?.lot}</td>
                <td className="tdHeadContent">{itm?.state?.name}</td>
                <td className="tdHeadContent">
                  <button
                    onClick={() => seleccionarUser(itm, "Details")}
                    className="buttonAction"
                  >
                    
                    <span class="material-icons find">find_in_page</span>
                  </button>
                </td>
              </tr>
            ))}
          </CustomTable>
        </div>
      </div>
      <ModalDetails
        showModalDetails={showModalDetails}
        functionShow={abrirCerrarModalDetails}
        info={info}
        bodyDetails={<BodyDeatail
          styles={styles}
          info={info}
          seleccionarUser2={seleccionarUser2}
          urlAvatar={urlAvatar} />}
      />
      <ModalRespuestaQueja
        showModalRespuestaQueja={showModalRespuestaQueja}
        functionShow={abrirCerrarModalRespuestaQueja}
        info={info}
        bodyRespuestaQueja={<BodyRespuestaQueja
          onSubmitInsertar={onSubmitInsertar} 
          error={error} 
          styles={styles} 
          imageChange={imageChange} 
          selectedImage={selectedImage} 
          removeSelectedImage={removeSelectedImage} 
          abrirCerrarModalRespuestaQueja={abrirCerrarModalRespuestaQueja} />}
      />
    </div>
  );
}

export default Quejas;
