import './Users.css'

import {Button, Modal, TextField} from '@material-ui/core';
import React, {useCallback, useContext, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ModalEditar from '../../components/pageComponents/ModalEditar';
import ModalEditar2 from '../../components/pageComponents/ModalEditar2';
import ModalEliminar from '../../components/pageComponents/ModalEliminar';
import ModalInsertar from "../../components/pageComponents/ModalInsertar"
import Switch from '@mui/material/Switch';
import Table2 from '../../components/Table2';
import TitlePage from '../../components/pageComponents/TitlePage';
import {UsuariosContext} from '../../context/UsuariosContext';
import axios from "axios"
import excel from "../../IMG/PantillaPropietarios.xlsx";
import {makeStyles} from '@material-ui/core/styles';
import { userContext } from '../../context/UserContext';
import { apiUrl } from '../../utils/urls';
import { createUser } from './services/crearUsuario';
import { createProperty } from './services/crearPropiedad';
import { getPropietarios } from './services/buscarPropiedad';
import { importPropietarios } from './services/importarPropietarios';
import { crearRelacionPropietarios } from './services/crearRelacionPropietarios';
import Loader from '../../components/LoaderComponent';
import { borrarUsuarioPorId, deleteUsuario } from './services/borrarUsuario';
import { editUsurio } from './services/editarUsuario';
import { toggleVisibilityUser } from './services/activeUser';

// import { Switch } from 'antd';

const useStyles = makeStyles((theme) => ({
    modal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    iconos:{
      cursor: 'pointer'
    }, 
    inputMaterial:{
      width: '100%'
    }
  }));

  
  
  
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
 
  function Users() {
    
    const [data, setdata] = useState([]);
  
    // const [dataUser, setdataUser] = useState([])
    
  const [showModalInsertar, setShowModalInsertar] = useState(false);
  const [showModalEditar, setShowModalEditar] = useState(false);
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const [switchOn, setSwitchOn] = useState(true);

  const [InfoUserProperty, setInfoUserProperty] = useState([])
  const [refresh, setrefresh] = useState(false)
  const [postSuccess, setpostSuccess] = useState(false)
  const [postSuccess2, setpostSuccess2] = useState(false)
  const [loading, setLoading] = useState(false);
  const [showModalEditar2, setShowModalEditar2] = useState(false);
  const { dataUser, setdataUser } = useContext(userContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [aprobador, setAprobador] = useState(false);
  const { dataUsuarios, setdataUsuarios } = useContext(UsuariosContext);
    

  // Token de usuario
  const token = localStorage.getItem('Authorization');

  const customerTableHead = [
    {
      title:"Nombres",
      render: data => data.user.name + " " + data.user.lastName 
    },
    {
        title:"Doc. de Identidad",
        render: data => data.user.document, width: "10%"
    },  
    {
        title:"Teléfono",
        render: data => data.user.phone, width: "10%"
    },
    {
      title:"Mz.",
      render: data => data.property.block, width: "5%"
    },
    { 
      title:"Lte.",
      render: data => data.property.lot, width: "5%"
    }
  ]
  const customerTableHeadSecurity = [
    {
      title:"Nombres",
      render: data => data.user.name + " " + data.user.lastName 
    },
    {
        title:"Doc. de Identidad",
        render: data => data.user.document, width: "10%"
    },  
    {
      title:"Mz.",
      render: data => data.property.block, width: "5%"
    },
    { 
      title:"Lte.",
      render: data => data.property.lot, width: "5%"
    }
  ]
      

    const [info, setInfo] = useState({

      name: "",
      lastName: "",
      document:"",
      email: "",
      phone: 0,
      password:"12345678",
      password_confirmation:"12345678",
      roleId: "3"

    })

    const [InfoUser, setInfoUser] = useState({
      name: "",
      lastName: "",
      document:"",
      email: "",
      phone: 0,
 


    })

    const [infoProperty, setInfoProperty] = useState({

      block: "",
      lot: "",
      area:""
    })

 
    const [error, setError] = useState(false)
   

    const{document, lastName,  name, email, phone } = info;
    const{block, lot,  area } = infoProperty;

  
    const baseUrl="http://localhost:3001/Users";

    const refreshPag=()=>{
      setrefresh(!refresh);
    }
    const handleChangeInsert = (e) => {

        setInfo({
            ...info,
            [e.target.name]: e.target.value,
       
        })
    }
    const handleChangeInsert2 = (e) => {

      setInfoUser({
            ...InfoUser,
            [e.target.name]: e.target.value,
       
        })
    }
    const handleChangeInsertProperty = (e) => {

        setInfoProperty({
            ...infoProperty,
            [e.target.name]: e.target.value,
          
        })
    }

    const seleccionarUser=(user, caso)=>{
        setInfo(user);
        console.log(user);        
        // console.log(user.user);
        (caso==="Editar")?abrirCerrarModalEditar()
        : 
        abrirCerrarModalEliminar() 
      }
    const seleccionarVisibility=(user, caso)=>{
     if (dataUser.roleId === "1") {
       
       setInfo(user);
       console.log(user);        
       // console.log(user.user);
       toggleSwitch(user);
     } else {
       return null
     }    
   
      }
      
      useEffect(() => {
        setInfoUser(info.user)
        setInfoUserProperty(info.property)
      }, [info]);

    useEffect(() => {
    if (dataUser.roleId === 4) {
        setAprobador(true)
    }
    }, [dataUser]);

  /**
   * Obtener los propietarios
   */
  const buscarPropietarios = async() => { 
    setLoading(true);
    const obtenPropietarios = await getPropietarios(token);
    if(obtenPropietarios){
      setdataUsuarios(obtenPropietarios);
    }
    setLoading(false);
  }
  /**
   * Funciones para crear usuarios
   */
  const crearUsuario= async () =>{
    setLoading(true);
    const creaUsuario = await createUser(token, info);
    if(creaUsuario){
      const crearProperty = await crearPropiedad();
      if(crearProperty){
        console.log("CreaProiedad:",crearProperty);
        console.log("creaUsuario:", creaUsuario);
        const idUser = creaUsuario?.id;
        const idPropiedad = crearProperty?.id;
        if(idUser && idPropiedad){
          const body = { userId: idUser , propertyId: idPropiedad };
          await crearRelacionPropietarios(token, body);
          await buscarPropietarios();
          setInfo({
            id: "",
            name: "",
            lastName: "",
            document:"",
            email: "",
            phone:"",
            password:"12345678",
            password_confirmation:"12345678",
            roleId: "3"
          });
          setInfoProperty({
            id: "",
            block: "",
            lot: "",
            area:""
          });
          abrirCerrarModalInsertar();
        }else{
          alert("Erro al crear usuario");
        }
      }else{
        await borrarUsuarioPorId(creaUsuario?.id, token);
      }
    }
    setLoading(false);
  }
  const crearPropiedad =async()=>{
    const creaProperty = await createProperty(token, infoProperty);
    if(creaProperty){
      return creaProperty;
    }else{
      return false;
    }
  }
  /**
   * Cargar datos iniciales 
   */
  const setInitialData = useCallback(()=>{
    const init = async ()=>{
      await buscarPropietarios();
      setdataUser(JSON.parse(localStorage.getItem('user')));
    }
    init();
  },[]);
   useEffect(() => {
    setInitialData();
  },[]);
  /**
   * Funcion para importar los propietarios 
   */
  const importarPropietarios = async (e) => {
    if(selectedFile !== null){
      const files = new FormData();
      files.append("file",selectedFile);
      const consulta = await importPropietarios(files, token);
      if(consulta){
        await buscarPropietarios();
        return true;
      }else{
        setSelectedFile(null);
        return false;
      }
    }
  }

  /**
   * Borrar usuarios
   */
   const borrarUsuario= async ()=>{
    setLoading(true);
    abrirCerrarModalEliminar();
    const borrarUser = await deleteUsuario(info, token);
    if(borrarUser){
      await buscarPropietarios();
    }
    setLoading(false);
  }
  /**
   * Editar información de un usuario
   */
  const editarUsuario = async ()=>{       
    setLoading(true);
    const consulta = await editUsurio(InfoUser, token);
    if(consulta){
      await buscarPropietarios();
    }
    setLoading(false);
  }
  /**
   * Activas / desactivar usuarios
   */
  const toggleSwitch=async(InfoUser)=>{    
    const consulta = await toggleVisibilityUser(parseInt(InfoUser?.user?.visibility), InfoUser, token);
    if(consulta){
      await buscarPropietarios();
    }
  }
  const onSubmitInsertar = (e) => {
    e.preventDefault();
    if (document.trim() === "" || lastName.trim() === "" ||name.trim() === "" ||email.trim() === "" ||block.trim() === "" ||lot.trim() === "" ||area.trim() === "" ) {
      setError(true);
      return false;
    }else{
      setError(false);
      crearUsuario()          
    }
  }

  const handleChangeSwitch = () => {
    setSwitchOn(!switchOn);
  }

      const buscarApi = async() => {
        const url = `${apiUrl}/property-user`;

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),

        }
        const rtdo = await axios.get(url, {headers})

        setdataUsuarios(rtdo.data.data)

    }

  const onSubmitEditar = (e) => {
    e.preventDefault();
    editarUsuario()
    abrirCerrarModalEditar();
    console.log("exit");
    buscarApi();      
  }


    
    const abrirCerrarModalInsertar = () => {
          
        setShowModalInsertar(!showModalInsertar)
      }

      const abrirCerrarModalEditar=()=>{
        setShowModalEditar(!showModalEditar);
      }
      const abrirCerrarModalEditar2=()=>{
        setShowModalEditar2(!showModalEditar2);
      }
      const abrirCerrarModalEliminar=()=>{
        setShowModalEliminar(!showModalEliminar);
      }
      const styles= useStyles();

      const bodyInsertar=(
        <form action="" onSubmit={onSubmitInsertar}>
          <div className={styles.modal}>
            <h3 className="my-5">Agregar Nuevo Usuario</h3>
            { error ? <h4 className=" text-red-700">Completar todos los campos (*) del formulario</h4> : null }
            <TextField className={styles.inputMaterial} name="name" onChange={handleChangeInsert} label="Nombres*"  />
            <br />
            <TextField className={styles.inputMaterial} name="lastName" onChange={handleChangeInsert}  label="Apellidos*" />          
              <br />
              <TextField className={styles.inputMaterial} name="email" onChange={handleChangeInsert}  label="Email*" />
              <TextField className={styles.inputMaterial} name="document" onChange={handleChangeInsert}  label="Doc. de Identidad*" />
            <br />
              <TextField className={styles.inputMaterial} name="phone" onChange={handleChangeInsert} label="Teléfono" />
            <br />
              <TextField className={styles.inputMaterial} name="block" onChange={handleChangeInsertProperty}  label="Manzana*" />
              <TextField className={styles.inputMaterial} name="lot" onChange={handleChangeInsertProperty}  label="Lote*" />
              <TextField className={styles.inputMaterial} name="area" onChange={handleChangeInsertProperty}  label="Area (m2)*" />
          
            <br /><br />
            <div align="right">
              <Button color="primary" type="submit" >Insertar</Button>
              <Button onClick= {abrirCerrarModalInsertar}> Cancelar</Button>
            </div>
          </div>
        </form>
      )



      const bodyEditar=(
          <form action="" onSubmit={onSubmitEditar}>
            <div className={styles.modal}>
              <h3 className="my-5">Editar Usuario</h3>
              { error ? <h4 className=" text-red-700">Completar todos los campos del formulario</h4> : null }
              <TextField className={styles.inputMaterial} name="name" onChange={handleChangeInsert2} value= {InfoUser&&InfoUser.name} label="Nombre" />
              <br />
              <TextField className={styles.inputMaterial} name="lastName" onChange={handleChangeInsert2} value= {InfoUser&&InfoUser.lastName} label="Apellido" />          
                <br />
                <TextField className={styles.inputMaterial} name="document" onChange={handleChangeInsert2} value= {InfoUser&&InfoUser.document} label="Doc. de Identidad" />
              <br />
                <TextField className={styles.inputMaterial} name="phone" onChange={handleChangeInsert2} value= {InfoUser&&InfoUser.phone} label="Teléfono" />
                <TextField className={styles.inputMaterial} name="block" onChange={handleChangeInsert2} value= {InfoUserProperty&&InfoUserProperty.block} label="Mz." />
                <TextField className={styles.inputMaterial} name="lot" onChange={handleChangeInsert2} value= {InfoUserProperty&&InfoUserProperty.lot} label="Lte." />
                <TextField className={styles.inputMaterial} name="area" onChange={handleChangeInsert2} value= {InfoUserProperty&&InfoUserProperty.area} label="Area." />
                <TextField className={styles.inputMaterial} name="email" onChange={handleChangeInsert2} value= {InfoUser&&InfoUser.email} label="Correo" />
              <br /><br />
              <div align="right">
                <Button color="primary" type="submit" >Editar</Button>
                <Button onClick= {()=>abrirCerrarModalEditar()}> Cancelar</Button>
              </div>
            </div>
          </form>
        )
        
      const fileChange = (e) => {
        console.log(e.target.files);
        if (e.target.files && e.target.files.length > 0) {
          setSelectedFile(e.target.files[0]);
          console.log(e.target.files[0]);
        }
    };

    const onSubmitEditar2 = async (e) => {
      e.preventDefault();
      setLoading(true);
      const importar =  await importarPropietarios();
      if(importar){
        abrirCerrarModalEditar2();
      }
      setLoading(false);
    }

        const bodyEditar2=(
          <form action="" onSubmit={onSubmitEditar2}>
            <div className={styles.modal}>
              <h3 className="my-5">Adjuntar Excel para su importación</h3>
              { error ? <h4 className=" text-red-700">Completar todos los campos del formulario</h4> : null }
              { error ? <h4 className=" text-red-700">Completar todos los campos (*) del formulario</h4> : null }
              { error ? <h4 className=" text-red-700">Completar todos los campos (*) del formulario</h4> : null }
              {/* <TextField className={styles.inputMaterial} name="consume" onChange={handleChangeInsert} label="Kw consumidos*" type="number" /> */}
       
              <div className='mt-5'>
                {/* <label>Choose File to Upload: </label> */}
                <input type="file" onChange={fileChange} id="file" />
                <div className="label-holder">
                  <label htmlFor="file" className="label">
                    <i className="material-icons">note_add</i>
                  </label>
                </div>
              </div> <br />
              {selectedFile && (
                <div className='eliminarImg'>
                  <h4>{selectedFile.name}</h4>
    
                  {/* <button onClick={removeSelectedImage} style={styles.delete}>
                    Eliminar
                  </button> */}
                </div>
              )}
    
       
              <br /><br />
              <div align="right">
                <Button color="primary" type="submit" >Importar</Button>
                <Button onClick= {abrirCerrarModalEditar2}> Cancelar</Button>
              </div>
            </div>
          </form>
        )

        

        const bodyEliminar=(
            <div className={styles.modal}>
              <p>Estás seguro que deseas eliminar  <b>{info&&info.names}</b>? </p>
              <div align="right">
                <Button color="secondary" onClick={()=>borrarUsuario()}>Sí</Button>
                <Button onClick={()=>abrirCerrarModalEliminar()}>No</Button>
        
              </div>
        
            </div>
          )

    return (
        <div>
            <div>
                <TitlePage titulo="Usuarios Propietarios" />
                
                {dataUser.roleId === "1"  ? 
                <div className=" flex justify-end">
                    <button className="btn" onClick={()=>abrirCerrarModalInsertar()}>
                        Agregar Usuario
                    </button>
                    <button className="btn" onClick={()=>abrirCerrarModalEditar2()}>
                        Importar Plantilla
                    </button>
                    <button className="btn" >
                    {/* <Link to="../../IMG/Pagos 1gastos.svg" target="_blank" download>Descagar Plantilla</Link> */}
                    <a className="enlace" href= {excel} download>Descagar Plantilla</a>
                        
                    </button>
                   
                </div>
                :null}

           {dataUser.roleId  === "1"  ?
             
                 <div className="mt-5 ">
                <Table2 
                 title="" 
                 columns={customerTableHead} 
                 data={dataUsuarios}
             
                 actions= 
                 {
                   [(data)=>{
                      return {
                      icon:() => <Switch {...label} defaultChecked={data?.user?.visibility !== "0"} onChange={handleChangeSwitch} className="toggle-button"/>,
                      tooltip:"Activar/Desactivar usuario",
                      onClick: (event, rowData) => seleccionarVisibility(rowData, "Visibility") 
                    }
                  },
                {
                  icon:() => <i className="material-icons edit">edit</i>,
                  tooltip:"Editar",
                  onClick: (event, rowData) => seleccionarUser(rowData, "Editar") 
                },
                {
                  icon:() => <i className="material-icons delete">highlight_off</i>,
                  tooltip:"Eliminar",
                  // onClick: (event, rowData) => seleccionarUser(rowData, "Eliminar")   
                  onClick: (event, rowData) => seleccionarUser(rowData, "Eliminar")
                }
                
              ] 
            } 
            /></div>
           : 
            <div className="mt-5 "><Table2 
              title="" 
              columns={customerTableHeadSecurity} 
              data={dataUsuarios}
            /></div>}
           

            </div>
            <ModalInsertar
            showmodalInsertar={showModalInsertar}
            functionShow= {abrirCerrarModalInsertar}
            handleChangeInsert={handleChangeInsert}
            onSubmitInsertar={onSubmitInsertar}
            error={error}
            bodyInsertar={bodyInsertar}
           
            
            />
             {dataUser.roleId === "1"  ? 
            <ModalEditar
            showModalEditar={showModalEditar}
            functionShow= {abrirCerrarModalEditar}
            handleChangeInsert={handleChangeInsert}
            onSubmitEditar={onSubmitEditar}
            info={info}
            bodyEditar={bodyEditar}
            />
            :null}

<ModalEditar2
            showModalEditar2={showModalEditar2}
            functionShow= {abrirCerrarModalEditar2}
            handleChangeInsert={handleChangeInsert}
            onSubmitEditar={onSubmitEditar}
            info={info}
            bodyEditar2={bodyEditar2}
            />
               {dataUser.roleId === "1"  ? 
            <ModalEliminar
            showModalEliminar={showModalEliminar}
            abrirCerrarModalEliminar= {abrirCerrarModalEliminar}
            onSubmitEditar={onSubmitEditar}
            info={info}
            peticionDelete={borrarUsuario}
            bodyEliminar={bodyEliminar}
            />
            :null}
            <Loader title={"Cargando datos de los propietarios"} active={loading} />
        </div>
    )
}

export default Users
