import axios from "axios";
import { getHeaderFiles } from "../../../utils/methods"
import { urlImportOwner } from "../../../utils/urls";

export const importPropietarios = async (file, token)=>{
    const header = getHeaderFiles(token);
    const files = new FormData();
    files.append("file",file);
    console.log(files);
    try{
        const consulta = await axios.post(urlImportOwner,file, header);
        if(consulta.status === 201){
            return true;
        }else{
            return false;
        }
    }catch(e){
        alert("Error al importar los propietarios");
        return false;
    }
}