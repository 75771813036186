import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlSpaceImage } from "../../../utils/urls";

export const deleteImageSpace = async (token, idImageSpace) =>{
    const header = getHeaders(token);
    try{
        const consulta = axios.delete(`${urlSpaceImage}/${idImageSpace}`,header);
        if(consulta){
            return true;
        }
    }catch(e){
        console.log("Error al eliminar la imagen:",e);
    }
    return false;
}