import React from "react";

export const BodyDeatail = ({ styles, info, seleccionarUser2, urlAvatar }) => {
  return (
    <div className={styles.modal}>
      <div className="estilosmodalDetails">
        <h1>Detalle de Queja o Reclamo</h1>
        <div className="linea"></div>
        <h3>
          Propietario:{" "}
          <span className="mt-5 detailsInfo">
            {info.property && info.property.users[0].name}
          </span>
        </h3>
        <h3>
          Manzana:{" "}
          <span className="mt-5 detailsInfo">
            {info.property && info.property.block}
          </span>
        </h3>
        <h3>
          Lote:{" "}
          <span className="mt-5 detailsInfo">
            {info.property && info.property.lot}
          </span>
        </h3>
        <h3>
          Doc de Identidad:{" "}
          <span className="mt-5 detailsInfo">{info && info.subject}</span>
        </h3>
        <h3>
          Proceso:{" "}
          <span className="mt-5 detailsInfo">
            {info.state && info.state.scope}
          </span>
        </h3>
        <h3>
          Asunto:{" "}
          <span className="mt-5 detailsInfo">{info && info.subject}</span>
        </h3>
        <h3>Descripción: </h3>
        <div className="descriptionQueja">{info && info.description}</div>
        {info.attached !== "" ? (
          <>
            <h3>Documentos Adjuntos:</h3>
            <div className="mt-5 flex justify-start items-center">
              <a
                href={`${urlAvatar}/${info.attached}`}
                target={"_blank"}
                rel="Documento adjunto"
                title="Documento adjunto"
                className="txt-center linkDocument"
              >
                <h4>
                  <span className="detailsInfo">Ver documento</span>{" "}
                </h4>
              </a>
            </div>
          </>
        ) : null}
        {info?.state?.name !== "Atendido" &&
        info?.state?.name !== "Pendiente" ? (
          <p>observado</p>
        ) : (
          <button
            className="btn btn-2 mt-10"
            onClick={() => seleccionarUser2()}
          >
            Responder
          </button>
        )}
      </div>
    </div>
  );
};
