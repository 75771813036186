import { customerTableHeadNewExpense } from "./constants/table.constants";
import TitlePage from "../../../components/pageComponents/TitlePage";
import Table from "./components/table";
import SubTitleExpense from "./components/SubTitleTableExpense";
import "./styles/index.css";
import { useEffect, useState } from "react";
import { getInvoiceForOwner } from "./services/invoiceService";
import { money } from "../../../utils/format";
import Loader from "../../../components/LoaderComponent";

function NewExpenseRecordPage (){
    const [data, setData] = useState([]);
    const [clousterData, setClousterData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [titleLoader, setTitleLoader] = useState("");
    const [periodo, setPeriodo] = useState("0000-00");
    const token = localStorage.getItem('Authorization');
    /**
     * Inicialización de la información
     */
    useEffect(()=>{
        const init = async ()=>{
            setTitleLoader("Cargando datos de las facturas");
            setLoading(true);
            const info = await getInvoiceForOwner(token);
            if(info !== false){
                const filterInfo = info.filter(el=>el.property !== null);
                const infoForTable = filterInfo.map(itm=>({
                    name: itm?.property?.users?.[0]?.name + " " + itm?.property?.users?.[0]?.lastName,
                    apple:itm?.property?.block,
                    lote: itm?.property?.lot,
                    phoneNumber:itm?.property?.users?.[0]?.phone,
                    email: itm?.property?.users?.[0]?.email.toLowerCase(),
                    totalAmount: money.format(parseFloat(itm?.total)),
                    state: itm?.state?.name,
                    date: itm?.date
                }));
                setData(infoForTable);
                setClousterData(infoForTable);
            }
            setLoading(false);
        }
        init();
    },[])
    /**
     * Fucion de cambio de periodo 
     */
    useEffect(()=>{
        const filterData = ()=>{
            setLoading(true);
            const newData = clousterData.filter(itm=>itm.date.slice(0,7) === periodo);
            setData(newData);
            setLoading(false);
        }
        filterData();
    },[periodo]);
    /**
     * Funcion para controlar el cambio de fechas
     */
    const handlePeriodoChange = (val)=>{
        setPeriodo(val);
    }
    return( 
        <>
        <div className="marco">
            <div className="titelNewExpense">
                Registro de pagos de propietarios
            </div>
            <SubTitleExpense onChange={handlePeriodoChange}/>
            <Table
                options={{
                        search: true
                    }}
                title=""
                columns={customerTableHeadNewExpense}
                actions={[
                    {
                        icon: 'check'
                    }
                ]}
                data={data}
            />
        </div>
        <Loader title={titleLoader} active={loading}/>
        </>
    )
}
export default NewExpenseRecordPage;