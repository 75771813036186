import axios from "axios";
import { getHeaders } from "../../../utils/methods";
import { urlSpaces } from "../../../utils/urls";
import { setImageSpace } from "./addImageSpace";

export const setSpace = async (token, info, imageInfo) =>{
    const headers = getHeaders(token);
    try{
        const consulta = await axios.post(urlSpaces, info, headers);
        if(consulta.status === 201){
            const spaceId = consulta.data.data.id;
            console.log(spaceId);
            const insertImage = await setImageSpace(token, spaceId, imageInfo);
            if(insertImage){
                return true;
            }
        }
    }catch(e){
        console.log("Error al agregar el espacio");
        alert("Error al añadir espacio");
    }
    return false;
}
