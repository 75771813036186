import axios from "axios";
import { getHeaders } from "../../../utils/methods";
import { urlClaims } from "../../../utils/urls";

export const getClaims = async (token)=>{
    try{
        const header = getHeaders(token);
        const consulta = await axios.get(urlClaims,header);
        if(consulta.status === 200){
            const data = consulta.data.data;
            const filter = data.filter(itm=>itm?.state?.id !== 5 && itm?.type=='Queja');
            return filter;
        }
    }catch(e){
        alert("Error al obtener las quejas");
        console.log("Error quejas:",e);
    }
    return 0;
}