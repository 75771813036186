

// falta adjuntar archivos

import '../users/Users.css'

import {Button, Modal, TextField} from '@material-ui/core';
import React, {useContext, useEffect, useRef, useState} from 'react';

import {EspacioContext} from '../../context/EspacioContext';
import ModalAdd from '../../components/pageComponents/ModalAdd';
import ModalEditar from '../../components/pageComponents/ModalEditar';
import ModalEliminar from '../../components/pageComponents/ModalEliminar';
import ModalInsertar from "../../components/pageComponents/ModalInsertar";
import Switch from '@mui/material/Switch';
import Table2 from '../../components/Table2';
import TitlePage from '../../components/pageComponents/TitlePage';
import axios from "axios"
import {makeStyles} from '@material-ui/core/styles';
import { userContext } from '../../context/UserContext';
import { apiUrl, urlAvatar, urlSpaceImage, urlSpaces, urlSpaceType } from '../../utils/urls';
import { checkFileIsImage, getHeaders } from '../../utils/methods';
import Loader from '../../components/LoaderComponent';
import './style/espacios.scss';
import Swal from 'sweetalert2';
import { getSpaces } from './services/getEspacios';
import CreateBodyEspaciosModal from './modalBodys/createModal';
import { setSpace } from './services/setSpace';
import { editSpace } from './services/editEspacio';
import { findImageSpace } from './services/findImageSpace';
import { deleteImageSpace } from './services/deleteImageSpace';
import { deleteSpace } from './services/deleteSpace';

const useStyles = makeStyles((theme) => ({
    modal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '500px',
      overflowX: 'hidden',
      overflowY: 'auto'
    },
    iconos:{
      cursor: 'pointer'
    }, 
    inputMaterial:{
      width: '100%'
    }
  }));

 

  
  
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  
  

function Espacio() {
 
    const [spaceTypes, setSpaceTypes] = useState([])
    const [showModalInsertar, setShowModalInsertar] = useState(false);
    const [showModalEditar, setShowModalEditar] = useState(false);
    const [showModalEliminar, setShowModalEliminar] = useState(false);
    const [switchOn, setSwitchOn] = useState(true);
    const [selectedFilesPost, setSelectedFilesPost] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [pathImg, setPathImg] = useState()
    const [infoImg, setInfoImg] = useState()
    const { dataEspacio, setdataEspacio } = useContext(EspacioContext);
    /**
     * Controller page mount
     */
    const pageReferece = useRef(true);
    /**
     * Loading controller
     */
    const [loading, setLoading] = useState(false);
    const [loadingTitle, setLoadingTitle] = useState("Cargando espacios");
    /**
     * Token de sesion
     */
    const token = localStorage.getItem('Authorization');
    // console.log(data);
    const customerTableHead = [
  
      {
          title:"Tipo de espacio",  
          render: data => data.space_type.name
      },
      {
          title:"Descripción",
          render: data => data.description.split(0, 200)
      },
      {
          title:"N° o Nombre",
          field: "internalCode"
      },
      {
          title:"Tiempo previo para reservar",
          render: data => data.previusReservationTime + " hs"
        
      },
      {
          title:"Horas máximas al mes",
          render: data => data.maximiunReservationTime +  " hs"
         
      },
      {
        title:"Activo",
        render: data => <div className={parseInt(data.visibility) === 0 ? 'inactivo' : 'activo'}>{parseInt(data.visibility) === 0 ? 'Inactivo' : 'Activo'}</div>
      }
  ]

  const gustos = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' }
  ]


    const imageChange = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        const dataImage = e.target.files[0];
        const splitData = dataImage.name.split(".");
        const typeFile = splitData[splitData.length - 1];
        if(checkFileIsImage(typeFile)){
          setSelectedImage(e.target.files[0]);
          setSelectedFilesPost(e.target.files[0])
        }else{
          Swal.fire({
            title: 'Extencion no permitida',
            text: 'El archivo debe ser de tipo imagen',
            icon: 'error'
          })
        }
      }
  };


  const removeSelectedImage = (e) => {
    e.preventDefault();
    setSelectedImage(null);
  };
      
  





   
    const [info, setInfo] = useState({
        id: "",
        spaceTypeId: "",
        description: "",
        internalCode: "",
        previusReservationTime: "",
        maximiunReservationTime: "",
        rulesOfUse: "",
        visibility: "", 
    })

    const [infoType, setInfoType] = useState({
        id: "",
        name: ""
  
    })
    

    // console.log(spaceTypes);

    
    
    const [error, setError] = useState(false)
    
    
    
    const{maximiunReservationTime,rulesOfUse, spaceTypeId, description, internalCode, previusReservationTime, id} = info;





    const{name} = infoType;




  
    const baseUrl=`${apiUrl}/space`;
    const handleChangeInsert = (e) => {

        setInfo({
            ...info,
            [e.target.name]: e.target.value
        })
        // console.log(e.target.name, e.target.value);
    }
    const handleChangeInsertType = (e) => {

        setInfoType({
            ...infoType,
            [e.target.name]: e.target.value
        })
        // console.log(e.target.name, e.target.value);
    }

    const seleccionarUser=async (user, caso)=>{
        setInfo(user);      
        await buscaImagenesDeEspacios(user.id);
        if(caso==="Editar"){
          abrirCerrarModalEditar()
        }else{
          abrirCerrarModalEliminar()
        } 
      }

    useEffect(() => {
      if(pageReferece){
        const init = async ()=>{
          await getEspacios();
          await getSpaceType();
        }
        init();
      }
      return ()=>{
        pageReferece.current = false;
      }
    }, []);

  /**
   * Listar espacios
   */
  const getEspacios = async() => {
    setLoading(true);
    const response = await getSpaces(token);
    if(response){
      setdataEspacio(response);
    }
    setLoading(false);
  }  
  /**
   * Guardar la lista de tipos de espacios de los formularios
   */
  const getSpaceType = async() => {
    try{
    const headers = getHeaders(token);
    const consulta = await axios.get(urlSpaceType, headers);
    const response = consulta.data.data;
    setSpaceTypes(response)
    }catch(e){
      console.log("Error al obtener los tipos de espacio:",e);
    }
  }

const [spaceIdDelete, setSpaceIdDelete] = useState([])
let result=[];
let result2= null
const buscarSpaceId = async() => {
        
  const url = `${apiUrl}/space-image`;

  const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),

  }


  // console.log(typeof(info.id));
  const idSeek = (info.id).toString()
  // console.log(typeof(idSeek));
  axios.get(url,{headers})
    .then( (response) =>{

          response.data.data.forEach((req) => {
          if (req.spaceId === idSeek) {

                    result.push(req);
            } 
          })
          // this.setState({results:results});   
          console.log(result);
          console.log(result.path);
    
   
    })
  .catch(function (error) {
    console.log(error);
  }) 
  console.log(pathImg);
  
}

const seleccionarVisibility=(user, caso)=>{
  setInfo(user);
  console.log(user);        
  // console.log(user.user);
  peticionPutVisibility(user)

}

const peticionPutVisibility=async(InfoUser)=>{    
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),

}
  if (switchOn) {
    await axios.put(`${apiUrl}/space`+"/"+InfoUser.id,  {visibility: "0"} , {headers: headers})
    .then(response=>{

      console.log("visi1");
      
      getEspacios()  
    }).catch(error=>{
      console.log(error);
    })
  } else{
    await axios.put(`${apiUrl}/space`+"/"+InfoUser.id,  {visibility: "1"} , {headers: headers})
    .then(response=>{

      console.log("visi2");
      
      getEspacios()   
    }).catch(error=>{
      console.log(error);
    })
  }}

  /**
   * Buscar las imagenes relacionadas a los espacios
   */
  const buscaImagenesDeEspacios = async (idSpace)=>{
    setPathImg(null);
    setLoading(true);
    setLoadingTitle("Cargando espacios");
    const consulta = await findImageSpace(token, idSpace);
    if(consulta){
      console.log(consulta.infoImageSpace);
      setInfoImg(consulta.infoImageSpace);
      setPathImg(`${urlAvatar}/${consulta.path}`);
    }else{  
      setInfoImg(null);
      setPathImg(null);
    }
    setLoading(false);
    setLoadingTitle("Cargando espacios");
  }

  /**
   * Eliminar imagen
   */
  const eliminarImg = async () => {
    const consulta = await deleteImageSpace(token, infoImg.id);
    if(consulta){
      Swal.fire({
        title: 'Imagen eliminada con exito',
        icon: 'success'
      });
      setPathImg(undefined);
    }
  }



    const addSpace=async()=>{
      setLoading(true);
      setLoadingTitle("Agregando espacio");
      const consulta = await setSpace(token, info, selectedFilesPost);
      if(consulta){
        abrirCerrarInsertar();
        Swal.fire({
          title: 'Espacio agregado',
          icon: 'success',
          text: 'El espacio se agrego con éxito'
        })
        setSelectedFilesPost([])
        await getEspacios();
      }else{
        Swal.fire({
          title: 'Error al añadir el espacio',
          icon: 'error'
        })
      }
      setLoading(false);
      setLoadingTitle("Cargando espacios");
    }
    /**
     * Borrar espacios
     */
    const borrarEspacio=async()=>{
      const deleteEspacio = await deleteSpace(token, info.id);
      if(deleteEspacio){
        if(infoImg?.id){
          await eliminarImg();
        }
        await getEspacios();
        abrirCerrarModalEliminar();
        Swal.fire({
          title: 'Espacio eliminado con exito',
          icon: 'success'
        })
      }else{
        Swal.fire({
          title: 'Error al eliminar el espacio',
          icon: 'error'
        })
      }
    }


      const editarEspacio=async()=>{ 
        setLoading(true);
        const consulta = await editSpace(token, info.id, info, selectedFilesPost);
        if(consulta){
          Swal.fire({
            title: 'Cambios guardados',
            icon: 'success'
          });
          await getEspacios();
          await buscaImagenesDeEspacios(info.id);
        }else{
          Swal.fire({
            title: 'Error al guardar cambios',
            icon: 'error'
          });
        }
        setLoading(false);
      }

      const handleChangeSwitch = () => {
          setSwitchOn(!switchOn)
        
      }

    const onSubmitInsertar = (e) => {

        e.preventDefault();

        if (description.trim() === "" || 
          spaceTypeId.trim() === "" ||
          internalCode.trim() === "" ||
          previusReservationTime.trim() === "" || 
          maximiunReservationTime.trim() === "" || 
          rulesOfUse.trim() === "" || 
          selectedImage === undefined){
          if(!selectedImage){
            alert("Error hace falta una imagen");
          }
          setError(true);
        }else{
            setError(false);

            addSpace();
            

            setInfo({
              id: "",
              spaceTypeId: "",
              description: "",
              internalCode: "",
              previusReservationTime: "",
              maximiunReservationTime: "",
              rulesOfUse: ""
            });
            setSelectedImage()

            abrirCerrarModalInsertar();
            getEspacios()
           
        }
        
    }
    const onSubmitEditar = (e) => {
      e.preventDefault();
      editarEspacio()
      setSelectedImage()
    }

        const abrirCerrarInsertar = () => {
          abrirCerrarModalInsertar();
          setSelectedImage(undefined)
          setInfoImg(undefined) 
         
        }
    const abrirCerrarModalInsertar = () => {
          
        setShowModalInsertar(!showModalInsertar)
      }

  const abrirCerrarModalEditar=async ()=>{
    if(showModalEditar){
      setSelectedImage(undefined)
      setInfoImg(undefined) 
    }
    setShowModalEditar(!showModalEditar);
    
  }
      const abrirCerrarModalEliminar=()=>{
        setShowModalEliminar(!showModalEliminar);
      }
      const styles= useStyles();

      const bodyInsertar=(
        <form action="" onSubmit={onSubmitInsertar}>

          <div className={styles.modal}>
            <h3 className="my-5">Agregar Nuevo Espacio</h3>

            {error ? <h4 className=" text-red-700">Completar todos los campos (*) del formulario</h4> : null}
            <select className='select1' onChange={handleChangeInsert} name="spaceTypeId" value={spaceTypeId}>


              {/* <label htmlFor=""  value="">Seleccione un tipo*</label>  */}
              <option value="" >Seleccione un tipo de espacio*</option>
              {spaceTypes.map(tipos => (
                <option value={tipos.id} key={tipos.id} >{tipos.name}</option>
              ))}



            </select>

            {/* <button className='mt-5' onClick={() => abrirCerrarModalAdd()}>Crear nuevo tipo de espacio</button> */}
            {/* <TextField className={styles.inputMaterial} name="type" onChange={handleChangeInsert} label="Tipo*"  />  */}
            <br />
            <TextField className={styles.inputMaterial} name="description" onChange={handleChangeInsert} label="Descripción*" multiline rows={3} />
            {/* <TextField className={styles.inputMaterial} name="spaceTypeId" onChange={handleChangeInsert}  label="typeid*" /> */}
            <TextField className={styles.inputMaterial} name="internalCode" onChange={handleChangeInsert} label="ID (N° o nombre)*" />
            <br />
            <br />
            {/* <TextField className={styles.inputMaterial} name="previusReservationTime" onChange={handleChangeInsert} label="Tiempo previo de reserva (horas)*" /> */}
            <select className='select1' onChange={handleChangeInsert} name="previusReservationTime">


              {/* <label htmlFor=""  value="">Seleccione un tipo*</label>  */}
              <option value="" >Tiempo previo para reservar*</option>
  
                      {gustos.map((tipos,i) => (
                <option value={tipos.value} key={`${tipos.value}-${i}`} >{tipos.label}</option>
              ))}



            </select>
            <br />
            <TextField className={styles.inputMaterial} name="maximiunReservationTime" onChange={handleChangeInsert} label="Horas máximas de reservas al mes por usuario*" type="number" />
            <TextField className={styles.inputMaterial} name="rulesOfUse" onChange={handleChangeInsert} label="Normas de Uso*" multiline rows={5} />
            {/* <div className="image-upload">
              <label for="file-input">
                <img src={mas} />
              </label>
              <input type="file" className="mt-10" name="file" id="file-input" multiple onChange={(e) => subirArchivos(e.target.files)} />
            </div> */}
            {/* <br /><br /> */}

            <div className='mt-5'>
              {/* <label>Choose File to Upload: </label> */}
              <input type="file" onChange={imageChange} id="file" />
              <div className="label-holder">
                <label htmlFor="file" className="label">
                  <i className="material-icons">add_a_photo</i>
                </label>
              </div>
            </div> <br />


            {selectedImage && (
              <div className='eliminarImg'>
                <img
                  src={URL.createObjectURL(selectedImage)}
                  className='foto1'
                  alt="Thumb"
                />
                <button onClick={removeSelectedImage} style={styles.delete}>
                  Eliminar
                </button>
              </div>
            )}


            {/* aparte  */}
            <div align="right">
              <Button color="primary" type="submit">Insertar</Button>
              <Button onClick={abrirCerrarInsertar}> Cancelar</Button>
            </div>
          </div>
        </form>
      )

  const bodyEditar = (
    <form action="" onSubmit={onSubmitEditar}>
      <div className={styles.modal}>
        <h3 className="my-5">Editar Espacio</h3>
        {error ? <h4 className=" text-red-700">Completar todos los campos del formulario</h4> : null}
        <select className='select1' onChange={handleChangeInsert} name="spaceTypeId" value={spaceTypeId}>


          {/* <label htmlFor=""  value="">Seleccione un tipo*</label>  */}
          <option value="" >Seleccione un tipo de espacio</option>
          {spaceTypes.map((tipos,i) => (
            <option value={tipos.id} key={`${tipos.id}-${i}`}>{tipos.name}</option>
          ))}



        </select>

        {/* <TextField className={styles.inputMaterial} name="spaceTypeId" onChange={handleChangeInsert} value={info && info.spaceTypeId} label="Tipo*" /> */}
        <br />
        <TextField className={styles.inputMaterial} name="description" onChange={handleChangeInsert} value={info && info.description} label="Descripción*" />
        <br />
        <TextField className={styles.inputMaterial} name="internalCode" onChange={handleChangeInsert} value={info && info.internalCode} label="ID (N° o nombre)*" />
        <br />
        <TextField className={styles.inputMaterial} name="previusReservationTime" onChange={handleChangeInsert} value={info && info.previusReservationTime} label="TTiempo previo de reserva (horas)*" />
        <TextField className={styles.inputMaterial} name="maximiunReservationTime" onChange={handleChangeInsert} value={info && info.maximiunReservationTime} label="Horas máximas de reservas al mes por usuario*" />
        <TextField multiline rows={2} maxRows={6} className={styles.inputMaterial} name="rulesOfUse" onChange={handleChangeInsert} value={info && info.rulesOfUse} label="Normas de Uso*" />
        <br /><br />

        { loading ? <div className='mt-5'>
          <h3>Cargando imagenes</h3>
        </div> : ( 
          selectedImage ? 
          <div className='eliminarImg mt-5'>
            <img
              src={URL.createObjectURL(selectedImage)}
              className='foto1'
              alt="Thumb"
            />
            <button onClick={removeSelectedImage} style={styles.delete}>
              Eliminar
            </button>
          </div>
        : (pathImg ? 
          <div className='eliminarImg'>
            <img
              src={pathImg}
              className='foto1'
            />
            <button onClick={() => eliminarImg()} style={styles.delete}>
              Eliminar
            </button>
          </div>
          :
          <div className='mt-5'>
            <input type="file"  onChange={imageChange} id="file" />
            <div className="label-holder">
              <label htmlFor="file" className="label">
                <i className="material-icons">add_a_photo</i>
              </label>
            </div>
          </div>
          )
        )}
        <div align="right">
          <Button color="primary" type="submit" >Editar</Button>
          <Button onClick={() => abrirCerrarModalEditar()}> Cancelar</Button>
        </div>
      </div>
    </form>
        )

        

        const bodyEliminar=(
            <div className={styles.modal}>
              <p>Estás seguro que deseas eliminar  <b>{info&&info.description}</b>? </p>
              <div align="right">
                <Button color="secondary" onClick={()=>borrarEspacio()}>Sí</Button>
                <Button onClick={()=>buscarSpaceId()}>No</Button>
        
              </div>
        
            </div>
          )

    

    return (
        <div>
            <Loader active={loading} title={loadingTitle}/>
            <div>
                <TitlePage titulo="Espacios de uso común" />
                <div className="flex justify-end ">
                    <button className="btn" onClick={()=>abrirCerrarModalInsertar()}>
                        Agregar nuevo espacio
                    </button>
                   
                </div>
                 <div className="mt-10"><Table2 
                 title="" 
                 columns={customerTableHead} 
                 data={dataEspacio}
                 actions= {[

                  {
                    icon:(itm) => {
                      return <Switch {...label} defaultChecked onChange={handleChangeSwitch} className="toggle-button"/>
                    },
                    tooltip:"Cambiar visibilidad",
                    onClick: (event, rowData) => seleccionarVisibility(rowData, "Visibility") 
                    
                  },
                  {
                    icon:() => <i className="material-icons edit">edit</i>,
                    tooltip:"Editar",
                    onClick: (event, rowData) => seleccionarUser(rowData, "Editar") 
                  },
                  {
                    icon:() => <i className="material-icons delete">highlight_off</i>,
                    tooltip:"Eliminar",
                    // onClick: (event, rowData) => seleccionarUser(rowData, "Eliminar")   
                    onClick: (event, rowData) => seleccionarUser(rowData, "Eliminar")
                  }        
                ] }

                 /></div>
            </div>
            <ModalInsertar
            showmodalInsertar={showModalInsertar}
            functionShow= {abrirCerrarModalInsertar}
            handleChangeInsert={handleChangeInsert}
            onSubmitInsertar={onSubmitInsertar}
            error={error}
            bodyInsertar={bodyInsertar}
            />
            <ModalEditar
            showModalEditar={showModalEditar}
            functionShow= {abrirCerrarModalEditar}
            handleChangeInsert={handleChangeInsert}
            onSubmitEditar={onSubmitEditar}
            info={info}
            bodyEditar={bodyEditar}
            />
            <ModalEliminar
            showModalEliminar={showModalEliminar}
            abrirCerrarModalEliminar= {abrirCerrarModalEliminar}
            onSubmitEditar={onSubmitEditar}
            info={info}
            peticionDelete={borrarEspacio}
            bodyEliminar={bodyEliminar}
            />
        </div>
    )
}

export default Espacio