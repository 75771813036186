import { faBolt, faBuilding, faWater } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavLink } from "react-router-dom"
import "../GastosComunes/styles/GastosComunesPage.scss";


const TabOptionsRoute = ()=>{
    return(
        <div className="tabCointainer">
            <NavLink to="/GastosComunes" className="tabLink" activeClassName="tabActive">
                <div><FontAwesomeIcon icon={faBuilding} style={{ marginRight:"8px" }}/></div>
                <div>Condominio</div>
            </NavLink>
            <NavLink to="/Energia" className="tabLink" activeClassName="tabActive">
                <div><FontAwesomeIcon icon={faBolt} style={{ marginRight:"8px" }}/></div>
                <div>Energía</div>
            </NavLink>
            <NavLink to="/Prueba" className="tabLink" activeClassName="tabActive">
                <div><FontAwesomeIcon icon={faWater} style={{ marginRight:"8px" }}/></div>
                <div>Agua</div>
            </NavLink>
        </div>
    )
}

export default TabOptionsRoute;