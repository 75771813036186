import React from "react";
import { useForm } from "react-hook-form";

export const BodyDetail = ({ styles, info, seleccionarUser2, urlAvatar,actualizarTramite}) => {


  
  return (
    <div className={styles.modal}>
      <div className="estilosmodalDetails">
        <h1>Detalles</h1>
        <div className="linea"></div>
        <h3>
          Propietario:{" "}
          <span className="mt-5 detailsInfo">{info && info.Propietario}</span>
        </h3>
        <h3>
          Manzana:{" "}
          <span className="mt-5 detailsInfo">
            {info.Propiedad && info.Propiedad.block}
          </span>
        </h3>
        <h3>
          Lote:{" "}
          <span className="mt-5 detailsInfo">
            {info.Propiedad && info.Propiedad.lot}
          </span>
        </h3>
        <h3>
          Doc de Identidad:{" "}
          <span className="mt-5 detailsInfo">{info && info.Documento}</span>
        </h3>
        <h3>
          Proyecto:{" "}
          <span className="mt-5 detailsInfo">
            {info.Proyecto && info.Proyecto}
          </span>
        </h3>

        <h3>Descripción:</h3>
        <div className="descriptionTramite">{info && info.Descripcion}</div>
        <h3>Documentos Adjuntos:</h3>
        {info.file === undefined ? (
          <span className="mt-5 detailsInfo">No hay archivos adjuntos</span>
        ) : (
          <div className="d-flex justify-content-center mt-5">
            <a
              href={`${urlAvatar}/${info.file.path}`}
              target="_blank"
              className="linkdownload"
            >
              <i className="material-icons file_download">file_download</i>
            </a>
          </div>
        )}

        <div className="separaBoton">
          <button
            className="btn btn-2 mt-10"
            onClick={() => actualizarTramite(info)}
          >
            Aprobar
          </button>
          <button
            className="btn btn-2 mt-10"
            onClick={() => seleccionarUser2()}
          >
            Observar
          </button>
        </div>
      </div>
    </div>
  );
};
