import { Button } from '@material-ui/core'
import React from 'react'

export const BodyEliminar = ({styles,info,abrirCerrarModalEliminar,peticionDelete}) => {
  return (
    <div className={styles.modal}>
      <p>
        Estás seguro que deseas eliminar <b>{info && info.title}</b>?{" "}
      </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()}>
          Sí
        </Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )
}
