import { Button, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";

export const BodyRespuestaQueja = ({onSubmitInsertar,
    
    handleChangeInsert2,
    styles,
    imageChange,
    selectedImage,
    removeSelectedImage,
    abrirCerrarModalRespuestaQueja,
    info}) => {

      const {register,handleSubmit,formState:{errors}}= useForm()
  return (
    <form action="" onSubmit={handleSubmit(onSubmitInsertar)}>
      <div className={styles.modal}>
        <h3 className="my-5">Agregar archivo</h3>
        <TextField
          className={styles.inputMaterial}
          name="subject"
          onChange={handleChangeInsert2}
          label="Observación*"
          {...register('observacion',{
            required:{
              value:true,
              message:'El campo es requerido'
            },
            minLength:{
              value:4,
              message:'La descripcion debe tener mas de 4 letras'
            }
          })}
        />
              <span className="text-danger text-small d-block mb-2">
                      {errors?.observacion?.message}
              </span>
        <br />
        <br />
        <br />
        <div className="mt-5">
          {/* <label>Choose File to Upload: </label> */}
          <input type="file" onChange={imageChange} id="file" name="image" />
          <div className="label-holder">
            <label htmlFor="file" className="label">
              <i className="material-icons">attach_file</i>
            </label>
          </div>
        </div>{" "}
        <br />
        {selectedImage && (
          <div className="eliminarImg">
            <h4>
              <span className="detailsInfo">
                {info.file && selectedImage?.file?.name}
              </span>
            </h4>
            <button onClick={removeSelectedImage} style={styles.delete}>
              Eliminar
            </button>
          </div>
        )}
        <br />
        <br />
        <div align="right">
          <Button color="primary" type="submit">
            Insertar
          </Button>
          <Button onClick={abrirCerrarModalRespuestaQueja}> Cancelar</Button>
        </div>
      </div>
    </form>
  );
};
