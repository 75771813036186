import axios from "axios";
import Swal from "sweetalert2";
import { getHeaders } from "../../../utils/methods"
import { urlRegister } from "../../../utils/urls";

const checkInformation = (userInfo)=>{
    let newInfo = {...userInfo};
    if(userInfo?.password){
        newInfo = {...newInfo, password:"12345678" };
    }
    if(userInfo?.roleId){
        newInfo = {...newInfo, roleId:"3" };
    }
    return newInfo;
}

export const createUser = async (token, info) =>{
    const header = getHeaders(token);
    const userInfo = checkInformation(info);
    try{
        const creaUser = await axios.post(urlRegister, userInfo, header);
        if(creaUser.status === 201){
            return creaUser.data?.user;
        }else{
            return false;
        }
    }catch(e){
        const errores = e.response.data.errors;
        let alertMessage = ``;
        for(let i in errores){
            alertMessage += `<li>${errores[i][0]}</li>`;
        }
        Swal.fire({
            title: 'Error al crear usuario',
            html: '<ul>'+alertMessage+'</ul>',
            icon: 'error'
        });
        return false;
    }
}