export const calculateSubTotalLight = (consume=0,unitCost=0)=>parseFloat(consume*unitCost);
export const calculateTotalLight = (consume = 0, unitCost = 1, amount = 1)=>parseFloat(calculateSubTotalLight(consume,unitCost) + parseFloat(amount));
export const calculateTotalLightOfCondominium = (data=[],unitCost,amount)=>{
    if(data.length > 0 ){
        const reduce = data.reduce((t,{ consume })=> t + calculateTotalLight(consume,unitCost, amount) ,0);
        return reduce;
    }else{
        return 0;
    }
}
export const getSubTotalGastosGenerales=(participation,totalGastosGenerales)=>(parseFloat(participation)*parseFloat(totalGastosGenerales)/100);
export const getTotalGastosGenerales=(participation,totalGastosGenerales,amount)=>(getSubTotalGastosGenerales(participation,totalGastosGenerales)+parseFloat(amount));

export const getSubtotalWater = (consume, unitcost)=>(parseFloat(consume)*parseFloat(unitcost));
export const getTotalWater = (consume, unitcost, amount)=>(getSubtotalWater(consume,unitcost) + parseFloat(amount));