import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlPropertyUser } from "../../../utils/urls";

export const getPropietarios = async (token) =>{
    const header = getHeaders(token);
    try{
        const obtenerPropietarios = await axios.get(urlPropertyUser, header);
        if(obtenerPropietarios.status === 200){
            return obtenerPropietarios.data?.data;
        }else{
            return false;
        }
    }catch(e){
        alert("Error al obtener los propietarios");
        return false;
    }
}