import "./Featured.css";

import FeaturedCard from "../featuredCard/FeaturedCard";
import React from "react";

const Featured = ({ quejas, tramites, reclamos, reservas, history }) => {
  return (
    <div id="containerFeatured">
      <h2 id="subTittle"> Pendientes </h2>

      {/* <h2 id="subTittle"> Pendientes </h2> */}

      <FeaturedCard
        onClick={() => history.push("/Quejas")}
        tittle="Quejas  y Reclamos pendientes"
        num={quejas}
      />
      {/* <FeaturedCard onClick={()=>history.push("/Tramites")} tittle="Tramites por revisar" num={tramites}/> */}
      <FeaturedCard
        onClick={() => history.push("/Calendario")}
        tittle="Reservas del dia"
        num={reservas}
      />
    </div>
  );
};
Featured.defaultProps = {
  quejas: 0,
  tramites: 0,
  reclamos: 0,
  reservas: 0,
  history: () => {},
};
export default Featured;
