import "../users/Users.css"
import {
    Link,
    NavLink,
} from "react-router-dom";
import React, {useContext, useEffect, useState} from 'react';

import Table2 from '../../components/Table2';
import TitlePage from '../../components/pageComponents/TitlePage';
import { TotalCondoContext } from "../../context/TotalCondContext";
import axios from "axios";
import { userContext } from '../../context/UserContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faCheck, faL } from "@fortawesome/free-solid-svg-icons";
import { getHeaders } from "../../utils/methods";
import { urlCollectionExpense, urlCondominiumExpense, urlLightExpediture, urlPropertyUser, urlTotalLightExpediture, urlTotalWaterExpediture, urlWaterExpenditure } from "../../utils/urls";
import Loader from "../../components/LoaderComponent";
import "./styles/CalculosPage.scss";
import { customerTableHeadCondominiumCommunal, customerTableHeadEnergyTable, customerTableHeadWater, customerTableTotalPerPeriod } from "./constants/tableHeads";
import { useSelector } from "react-redux";
import { getGastosGeneralesRepartidos, getTotalCobranza, getTotalParticipation } from "./CalculosPage/methods/financial";
import InformationCostCalculus from "./CalculosPage/components/InformationCost";
import { calculateTotalLight, getTotalGastosGenerales, getTotalWater } from "./methods/maths";
import ModalApprove from "./CalculosPage/modals/ModalApprove";
import { saveDataPropietarioCalculusPage } from "./CalculosPage/services/saveUser";

function Calculos() {
  /**
   * @description Seccion de states
   */
  const [progess,setProgress] = useState(25);
  const [infoCondominium,setInfoCondominium] = useState([]);
  const [servicesCondominium, setServicesCondominium] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalArea, setTotalArea] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState("Cargando datos de los condominios");
  const [finish, setFinish] = useState(false);
  /**
   * Estados para el manejo de InforationCost
   */
  const [generalCostPeriod, setGeneralCostPeriod] = useState(0);
  /**
   * Estados importantes para la información financiera
   */
  const [participation, setParticipation] = useState(0.0);
  const [gastosGeneralesRepartidos, setGastosGeneralesRepartidos] = useState(0);
  const [totalCobranza, setTotalCobranza] = useState(0);
  const [generalAmount, setGeneralAmount] = useState(0);
  /**
   * Estados para la información de energía
   */
  const [dataTotalEnergy, setDataTotalEnergy] = useState({});
  const [infoEnergyIndividual, setInfoEnergyIndividual] = useState([]);
  const [energyAmount, setEnergyAmount] = useState(0);
  const [totalEnergy, setTotalEnergy] = useState(0);
  /**
   * Estados para la información del agua
   */
  const [waterAmount, setWaterAmount] = useState(0);
  const [dataTotalWater, setDataTotalWater] = useState(0);
  const [infoWater, setInfoWater] = useState([]);
  const [totalPeriodWater, setTotalPeriodWater] = useState(0);
  /**
   * Estados total general
   */
  const [infoGeneralIndividual, setInfoGeneralIndividual] = useState([]);
  const [showModalApprove, setShowModalApprove] = useState(false);
  //Contextos
  const { setdataUser } = useContext(userContext);
  const { totalCondo } = useContext(TotalCondoContext);

  const token = localStorage.getItem('Authorization');
  /**
   * @constant
   * @type {object}
   * @description Selector que controla los estatus de los periodos
   */
  const periodoStore = useSelector((store)=>store.PeriodoControllerReducer);

  /**
   * @function sumarGastosTotales
   * @description Suma los gastos comunales del condominio
   * @param {object} info 
   */

  const sumarGastosTotales = (info) => {
    if(info?.length){
      let suma = 0
      for (let i = 0; i < info.length; i++) {
        suma = suma + parseFloat(info[i].amount);
      }
      console.log("sumarGastosTotales: ",suma);
      setTotal(suma);
    }
  }
  const sumarAreaTotales = (info) => {
    if(info?.length){
      let area = 0
      for (let i = 0; i < info.length; i++) {
          area = area + parseFloat(info[i].property.area) 
      }
      console.log(area);
      setTotalArea("sumarAreaTotales:",area);
    }
  }

  const getConsumoGeneral = async() => {
    setLoading(true);
    try{
      const header = getHeaders(token);
      const consult = await axios.get(urlPropertyUser, header);
      console.log("Info condominio ",consult.data.data);
      const response = consult.data.data;
      setInfoCondominium(response);
      setParticipation(getTotalParticipation(response));
      sumarAreaTotales(response);
    }catch(e){
      alert("Error al obtener la información de los condominios");
      console.log("Error condominios",e);
    }
    setLoading(false);
  }
  /**
   * Servicios generales del periodo
   */
  const getServicesCondominium = async() => {
    setLoading(true);
    try{
      const header = getHeaders(token);
      const consulta = await axios.get(urlCondominiumExpense, header);
      const response = consulta.data.data
      const filterServices = response.filter(itm=>itm.date.slice(0,7) === periodoStore.periodo)
      setdataUser(JSON.parse(localStorage.getItem('user')));
      console.log("Data condominium Expense -",periodoStore.periodo,filterServices)
      setServicesCondominium(filterServices);
      sumarGastosTotales(filterServices);
    }catch(e){
      alert("Error en la consulta de los condominios");
      console.log("Error de consulta",e);
    }
    setLoading(false);
  }
  /**
   * Datos de enegría
   */
  const getTotalLight = async ()=>{
    setLoading(true);
    try{
      const header = getHeaders(token);
      const consulta = await axios.get(urlTotalLightExpediture, header);
      const response = consulta.data.data;
      const dataPerPeriod = response.filter(data=>data.date.slice(0,7) === periodoStore.periodo);
      setDataTotalEnergy(dataPerPeriod?.[0]);
    }catch(e){
      alert("Error al obtener el total de energía consumida");
      console.log("Error de totalEnergía:",e);
    }
    setLoading(false);
  }
  /**
   * Gastos de energía por periodo
   */
  const getLightExpediturePerPeriod = async ()=>{
    setLoading(true);
    try{
      const header = getHeaders(token);
      const consulta = await axios.get(urlLightExpediture,header);
      const response = consulta.data.data;
      const dataLightExpedituresPerPeriod = response.filter(data=>data.date.slice(0,7) === periodoStore.periodo);
      setInfoEnergyIndividual(dataLightExpedituresPerPeriod);
    }catch(e){
      alert("Error al obtener las facturas de luz");
      console.log("Error Light Expediture:",e);
    }
    setLoading(false);
  }
  const getTotalEnergyInvoice = ()=>{
    const totalEnergy = infoEnergyIndividual.reduce((t,{ consume })=>t + calculateTotalLight(consume, dataTotalEnergy?.consume, energyAmount),0);
    console.log(totalEnergy);
    setTotalEnergy(totalEnergy);
  }
  useEffect(()=>{
    const initEnergyCalculus = ()=>{
      if(infoEnergyIndividual.length > 0){
        getTotalEnergyInvoice();
      }
    }
    initEnergyCalculus();
  },[dataTotalEnergy,infoEnergyIndividual]);
  /**
   * Datos del agua
   */
  const getTotalWaterExpediture = async () => {
    try{
      const header = getHeaders(token);
      const consulta = await axios.get(urlTotalWaterExpediture, header);
      const response = consulta.data.data;
      setDataTotalWater(response?.[0]);
    }catch(e){
      alert("Error al obtener los gastos totales del agua");
      console.log("Error Total Water",e);
      setDataTotalWater(0);
    }
  }
  /**
   * Gastos de agua por periodo
   */
  const getWaterExpediture = async () => {
    try{
      const header = getHeaders(token);
      const consulta = await axios.get(urlWaterExpenditure, header);
      const response = consulta.data.data;
      const filterExpediture = response.filter(itm=>itm.date.slice(0,7) === periodoStore.periodo);
      console.log("Water periodo:",filterExpediture);
      console.log(filterExpediture);
      setInfoWater(filterExpediture);
    }catch(e){
      alert("Error al obtener los gastos individuales del agua");
      console.log("Error Water Expediture",e);
    }
  }
  const getTotalWaterInvoice = ()=>{
    const totalWater = infoWater.reduce((t, { consume })=> t + getTotalWater(consume, dataTotalWater?.consume, waterAmount),0);
    setTotalPeriodWater(totalWater);
  }
  useEffect(()=>{
    const initWater = ()=>{
      getTotalWaterInvoice();
    }
    initWater();
  },[dataTotalWater,infoWater])
  /**
   * Controlador de servicios generales
   */
  const getAmountServices = async ()=>{
    setLoading(true);
    try{
      const header = getHeaders(token);
      const consulta = await axios.get(urlCollectionExpense,header);
      const response = consulta.data.data;
      const filterEnergy = response.filter(data=>data.id === 6);
      const filterGeneral= response.filter(data=>data.id === 8);
      const filterWater = response.filter(data=>data.id === 7);
      setEnergyAmount(filterEnergy?.[0]?.amount);
      setGeneralAmount(filterGeneral?.[0]?.amount);
      setWaterAmount(filterWater?.[0]?.amount);
    }catch(e){
      alert("Error al obtener los montos");
      console.log("Error ammount services");
    }
    setLoading(false);
  }
  /**
   * Calcular el total de los usuarios
   */
  useEffect(()=>{
    const getCalculus = ()=>{
      let totalPeriod = 0;
      const userInfo = infoCondominium.map(itm=>{
        const energyFilter = infoEnergyIndividual.filter(data=>data.propertyId === itm.propertyId);
        const waterFilter = infoWater.filter(data=>data.propertyId === itm.propertyId);
        //Calculamos el total general
        const totalIndividual = getTotalGastosGenerales(itm?.property?.participation, totalCondo, generalAmount)
        + calculateTotalLight(energyFilter?.[0]?.consume, dataTotalEnergy?.consume, energyAmount)
        + getTotalWater(waterFilter?.[0]?.consume, dataTotalWater?.consume, waterAmount);
        totalPeriod += totalIndividual;
        return {
          name: `${itm?.user?.name} ${itm?.user?.lastName}`,
          comunInvoice: getTotalGastosGenerales(itm?.property?.participation, totalCondo, generalAmount),
          lightInvoice: calculateTotalLight(energyFilter?.[0]?.consume, dataTotalEnergy?.consume, energyAmount),
          waterInvoice: getTotalWater(waterFilter?.[0]?.consume, dataTotalWater?.consume, waterAmount),
          total: totalIndividual,
          propertyId: itm.propertyId
        }
      });
      setGeneralCostPeriod(totalPeriod);
      setInfoGeneralIndividual(userInfo);
      console.log("User information:",userInfo);
    }
    getCalculus();
  },[infoCondominium, infoEnergyIndividual, infoWater, dataTotalWater, dataTotalEnergy]);
  /**
   * Precargamos la información
   */
  useEffect(() => {
    const init = async ()=>{
      //Obtenemos los datos generales para luz, agua, etc
      await getAmountServices();
      //Consumos generales
      await getConsumoGeneral();
      await getServicesCondominium();
      await sumarGastosTotales();
      //Consumo de energía
      await getTotalLight();
      await getLightExpediturePerPeriod();
      //Consumo de agua
      await getTotalWaterExpediture();
      await getWaterExpediture();
    }
    init();
  }, []);
  /**
   * Realizamos los calculos una vez tengamos información completa
   */
  useEffect(()=>{
    const initCalculos = ()=>{
      setGastosGeneralesRepartidos(getGastosGeneralesRepartidos(infoCondominium,total,generalAmount));
      setTotalCobranza(getTotalCobranza(infoCondominium,generalAmount));
    }
    initCalculos();
  },[total, infoCondominium])

  const [step,setStep] = useState(1);
  const totalStep = 4;

  const handleNextStep = ()=>{
    const percent = (100/totalStep);
    setProgress(progess+percent);
    setStep(step+1);
  }

  const handleReverseStep = ()=>{
    const percent = (100/totalStep);
    setProgress(progess-percent);
    setStep(step-1);
  }
  /**
   * Aprobar un gasto del periodo
   */
  const approveCondominiumExpensive = async (id)=>{
    try{
      const header = getHeaders(token);
      const body = {
        "approved":"1"
      }
      const params = JSON.stringify(body);
      const consulta = await axios.put(`${urlCondominiumExpense}/${id}`,params,header);
      if(consulta.status === 201){
        return true;
      }
    }catch(e){
      alert("Error al actualizar el estado de las facturas generales");
      console.log("Error approveCondominiumExpensive:",e);
    }
    return false;
  }
  /**
   * Aprobar los gastos generales
   */
  const approveAllExpensive = async () =>{
    const sizeInfo = servicesCondominium.length;
    if(sizeInfo > 0){
      for(let i = 0; i < sizeInfo; i++){
        const data = servicesCondominium[i];
        await approveCondominiumExpensive(data?.id);
      }
    }
  }
  /**
   * Controladores del modal
   */
  const _handleApprove = ()=> {
    setShowModalApprove(true);
  }
  const _handleCloseModalApprove= ()=> setShowModalApprove(false);
  const _handleSave = async ()=>{
    setLoading(true);
    setLoadingTitle("Guardando la información");
    _handleCloseModalApprove();
    try{
      await approveAllExpensive();
      for(let i = 0; i < infoGeneralIndividual.length; i++){
        const propietario = infoGeneralIndividual[i];
        //await saveDataPropietarioCalculusPage(propietario?.propertyId, propietario?.total, periodoStore.periodo+'-01');
      }
      alert("Información guardada");
      setFinish(true);
    }catch(e){
      alert("Error al guardar los resultados");
      console.log("Error en invoice:",e);
    }
    setLoading(false);
    setLoadingTitle("Cargando datos de los condominios");
  }
  /**
   * Controlador para el InformationCost
   */
  const getGeneralCost = ()=>{
    switch(step){
      case 1:
        return total;
      case 2: 
        return totalEnergy;
      case 3:
        return totalPeriodWater;
      case 4:
        return generalCostPeriod;
      default:
        return 0;
    }
  }
  /**
   * Controlador para la tabla de datos
   */
  const getColumnsTable = ()=>{
    switch(step){
      case 1:
        return customerTableHeadCondominiumCommunal({
                  amount: generalAmount,
                  totalCondominium: totalCondo
                });
      case 2:
        return customerTableHeadEnergyTable({
                amount: energyAmount,
                unitCost: dataTotalEnergy?.consume,
                fecha: periodoStore.periodo
              });
      case 3:
        return customerTableHeadWater({
          amount: waterAmount,
          fecha: periodoStore.periodo,
          unitCost: dataTotalWater?.consume
        })
      case 4:
        return customerTableTotalPerPeriod();
      default:
        return [];
    }
  }
  
  const getDataTable = ()=>{
    switch(step){
      case 1:
        return infoCondominium;
      case 2:
        return infoEnergyIndividual;
      case 3:
        return infoWater;
      case 4:
        return infoGeneralIndividual;
      default:
        return [];
    }
  }

  const getTitleTable = ()=>{
    switch(step){
      case 1:
        return "Gastos comúnes";
      case 2:
        return "Gastos de energía";
      case 3:
        return "Gastos de agua";
      case 4:
        return "Gastos totales por propietario";
      default:
        return "";
    }
  }

    return (
      <>
        <div>
            <div>
                <TitlePage titulo={`Calculo de gastos periodo ${periodoStore.periodo}`} />
                <button className='btn'>
                  <Link to="/GastosComunes" style={{ textDecoration: 'none' }}>
                    <NavLink className="logoContainter1" exact to="/GastosComunes" activeClassName="linkactivo">
                      <h1 className="title1">Volver</h1>
                    </NavLink>
                  </Link>
                </button>
                <InformationCostCalculus 
                  step={step}
                  generalCost={getGeneralCost()}
                  participation={participation}
                  totalCobranza={totalCobranza}
                  gastosGeneralesRepartidos={gastosGeneralesRepartidos}
                  costoKW={dataTotalEnergy?.consume}
                  costoLT={dataTotalWater?.consume}
                />
                 <div className="mt-10">
                  <Table2 
                    title={getTitleTable()}
                    columns={ getColumnsTable() } 
                    data={getDataTable()}
                  />
                 </div>
            </div>
            <div className="stepContainer">
              <div className="stepsInformation">
                <div>Paso {step} de {totalStep}</div>
                <div className="progressbarContainer">
                  <div className="progress" style={{ width: progess+'%' }}></div>
                </div>
              </div>
              { !finish ? 
                <div className="buttonContainer">
                { step > 1 ?  <button onClick={handleReverseStep} className="button-step mr-4">
                                      <FontAwesomeIcon icon={ faAngleLeft } className="mr-2"/>  Regresar
                                    </button> : null }
                  { step !== totalStep ? (
                      <button onClick={handleNextStep} className="button-step">
                        Siguiente <FontAwesomeIcon icon={ faAngleRight } className="ml-2"/>
                      </button>
                  ) : <button className="button-step" onClick={_handleApprove}>
                        <FontAwesomeIcon icon={faCheck} className="mr-2"/> Aprobar
                      </button> }
                </div>
              :<div className="buttonContainer">
                  <Link className='button-step' to={"/GastosComunes"}>
                    Volver
                  </Link>
                </div>
              }
            </div>
        </div>
        <Loader title={loadingTitle} active={loading}/>
        <ModalApprove 
          title="Está seguro de que desea aprobar estos resultados"
          open={showModalApprove}
          onClose={_handleCloseModalApprove}
          yesFunction={_handleSave}
        />
      </>
    )
}

export default Calculos
