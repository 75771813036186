import axios from 'axios';
import Swal from 'sweetalert2';
import { getHeaders } from '../../../utils/methods';
import { urlRegister } from '../../../utils/urls';
export const guardarPersonal = async (token, info)=>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.post(urlRegister, info, header);
        if(consulta.status === 201){
            return true;
        }else{
            return false;
        }
    }catch(e){
        const errores = e.response.data.errors;
        let alertMessage = ``;
        for(let i in errores){
            alertMessage += `<li>${errores[i][0]}</li>`;
        }
        Swal.fire({
            title: 'Error al guardar el usuario',
            html: '<ul>'+alertMessage+'</ul>',
            icon: 'error'
        });
    }
}