import "../users/Users.css";

import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ModalEditar from "../../components/pageComponents/ModalEditar";
import ModalEliminar from "../../components/pageComponents/ModalEliminar";
import ModalInsertar from "../../components/pageComponents/ModalInsertar";
import Table2 from "../../components/Table2";
import TitlePage from "../../components/pageComponents/TitlePage";
import { makeStyles } from "@material-ui/core/styles";
import { getTelefonos } from "./services/getTelefonos";
import { setTelefono } from "./services/setTelefonos";
import { deleteTelefono } from "./services/deleteTelefono";
import { editTelefono } from "./services/editTelefono";
import Loader from "../../components/LoaderComponent";
import { BodyEliminar } from "./bodys/BodyEliminar";
import { BodyEditar } from "./bodys/BodyEditar";
import { BodyInsertar } from "./bodys/BodyInsertar";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //   display: "grid"
  },
  iconos: {
    cursor: "pointer",
  },
  inputMaterial: {
    width: "100%",
  },
}));

const customerTableHead = [
  {
    title: "Descripción",
    field: "description",
  },
  {
    title: "Numero",
    field: "phone",
  },
];

function Telefonos() {
  const [data, setData] = useState([]);
  const [showModalInsertar, setShowModalInsertar] = useState(false);
  const [showModalEditar, setShowModalEditar] = useState(false);
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("Authorization");

  const [info, setInfo] = useState({
    description: "",
    phone: "",
    internalCode: "2",
  });

  const [error, setError] = useState(false);
  const {register,handleSubmit,formState:{errors}}= useForm()

  const seleccionarUser = (user, caso) => {
    setInfo(user);
    caso === "Editar" ? abrirCerrarModalEditar() : abrirCerrarModalEliminar();
  };

  const obtenerTelefonos = async () => {
    setLoading(true);
    const telefonos = await getTelefonos(token);
    if (telefonos) {
      setData(telefonos);
    }
    setLoading(false);
  };

  const guardarTelefono = async (e) => {
    const getInfo = {
      description: e.descripcion,
      phone: e.phone,
      internalCode: "2",
    }
    setLoading(true);
    const guardaTelefono = await setTelefono(getInfo, token);
    if (guardaTelefono) {
      await obtenerTelefonos();
    }
    abrirCerrarModalInsertar();
    setLoading(false);
  };

  const borrarTelefonos = async () => {
    setLoading(true);
    const id = info.id;
    const borraTelefono = await deleteTelefono(id, token, info);
    if (borraTelefono) {
      obtenerTelefonos();
    }
    abrirCerrarModalEliminar();
    setLoading(false);
  };

  const editarTelefono = async () => {
    setLoading(true);
    const id = info.id;
    const body = {
      phone: info.phone,
      description: info.description,
    };
    const editaTelefono = await editTelefono(id, token, body);
    if (editaTelefono) {
      await obtenerTelefonos();
    }
    abrirCerrarModalEditar();
    setLoading(false);
  };

  useEffect(() => {
    obtenerTelefonos();
  }, []);

  const onSubmitInsertar = (e) => {
    // e.preventDefault();
    console.log(e, 'EEE');
    const {description, phone} = e
    setInfo({description, phone})
    if (description === "" || phone === "") {
      setError(true);
    } else {
      setError(false);
      guardarTelefono(e);
      abrirCerrarModalInsertar();
    }
  };
  const onSubmitEditar = (e) => {
    e.preventDefault();
    editarTelefono();
  };
  const abrirCerrarModalInsertar = () => {
    setShowModalInsertar(!showModalInsertar);
  };

  const abrirCerrarModalEditar = () => {
    setShowModalEditar(!showModalEditar);
  };
  const abrirCerrarModalEliminar = () => {
    setShowModalEliminar(!showModalEliminar);
  };
  const styles = useStyles();

  return (
    <div>
      <div>
        <TitlePage titulo="Información util" />
        <div className="flex justify-end ">
          <button className="btn" onClick={() => abrirCerrarModalInsertar()}>
            Agregar Info
          </button>
        </div>
        {loading ? (
          <Box sx={{ position: "absolute", left: 500, top: 500, zIndex: 1 }}>
            <CircularProgress color="success" size={80} />
          </Box>
        ) : null}

        <div className="mt-10">
          <Table2
            title=""
            columns={customerTableHead}
            data={data}
            actions={[
              {
                icon: () => <i className="material-icons edit">edit</i>,
                tooltip: "Editar",
                onClick: (event, rowData) => seleccionarUser(rowData, "Editar"),
              },
              {
                icon: () => (
                  <i className="material-icons delete">highlight_off</i>
                ),
                tooltip: "Eliminar",
                // onClick: (event, rowData) => seleccionarUser(rowData, "Eliminar")
                onClick: (event, rowData) =>
                  seleccionarUser(rowData, "Eliminar"),
              },
            ]}
          />
        </div>
        <Loader title={"Cargando información"} active={loading} />
      </div>
      <ModalInsertar
        showmodalInsertar={showModalInsertar}
        functionShow={abrirCerrarModalInsertar}
        onSubmitInsertar={onSubmitInsertar}
        bodyInsertar={
          <BodyInsertar
            onSubmitInsertar={onSubmitInsertar}
            error={error}
            styles={styles}
            abrirCerrarModalInsertar={abrirCerrarModalInsertar}
            handleSubmit={handleSubmit}
            register={register}
            errors={errors}
          />
        }
      />
      <ModalEditar
        showModalEditar={showModalEditar}
        functionShow={abrirCerrarModalEditar}
        onSubmitEditar={onSubmitEditar}
        info={info}
        bodyEditar={
          <BodyEditar
            onSubmitEditar={onSubmitEditar}
            error={error}
            styles={styles}
            info={info}
            abrirCerrarModalEditar={abrirCerrarModalEditar}
          />
        }
      />
      <ModalEliminar
        showModalEliminar={showModalEliminar}
        abrirCerrarModalEliminar={abrirCerrarModalEliminar}
        onSubmitEditar={onSubmitEditar}
        info={info}
        peticionDelete={borrarTelefonos}
        bodyEliminar={
          <BodyEliminar
            info={info}
            styles={styles}
            borrarTelefonos={borrarTelefonos}
            abrirCerrarModalEliminar={abrirCerrarModalEliminar}
          />
        }
      />
    </div>
  );
}

export default Telefonos;
