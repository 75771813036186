// falta loi de mail en editar
import "../users/Users.css";
import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ModalEditar from "../../components/pageComponents/ModalEditar";
import ModalEditar2 from "../../components/pageComponents/ModalEditar2";
import ModalEliminar from "../../components/pageComponents/ModalEliminar";
import ModalInsertar from "../../components/pageComponents/ModalInsertar";
import { PersonalContext } from "../../context/PersonalContext";
import Table2 from "../../components/Table2";
import TitlePage from "../../components/pageComponents/TitlePage";
import axios from "axios";
import excel from "../../IMG/template_employe_condominio.xlsx";
import { makeStyles } from "@material-ui/core/styles";
import { userContext } from "../../context/UserContext";
import { apiUrl } from "../../utils/urls";
import { usePersonalStore } from "./zustand/usePersonalStore";
import BodyEliminar from "./personalBodys/BodyEliminar";
import BodyEditar2 from "./personalBodys/BodyEditar2";
import BodyEditar from "./personalBodys/BodyEditar";
import BodyInsertar from "./personalBodys/BodyInsertar";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  iconos: {
    cursor: "pointer",
  },
  inputMaterial: {
    width: "100%",
  },
}));

const customerTableHead = [
  {
    title: "Nombres",
    field: "name",
  },
  {
    title: "Apellidos",
    field: "lastName",
  },
  {
    title: "Doc. de Identidad",
    field: "document",
  },
  {
    title: "Teléfono",
    field: "phone",
  },
];

function Personal() {
  const styles = useStyles();

  const [data, setdata] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const { dataUser, setdataUser } = useContext(userContext);
  const { dataPersonal, setdataPersonal } = useContext(PersonalContext);

  const {
    showModalInsertar,
    setShowModalInsertar,
    showModalEditar2,
    setShowModalEditar2,
    showModalEditar,
    setShowModalEditar,
    setShowModalEliminar,
    showModalEliminar,
    error,
    setError,
    loading
  } = usePersonalStore();

  const [info, setInfo] = useState({
    name: "",
    lastName: "",
    document: "",
    email: "",
    phone: "",
  });

  const { document, lastName, name, email, phone } = info;

  const baseUrl = `${apiUrl}/employe`;
  const handleChangeInsert = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const buscarCotizacion = async () => {
    const url = `${apiUrl}/employe`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };

    const rtdo = await axios.get(url, { headers });

    console.log(rtdo.data.data[0]);

    setdataUser(JSON.parse(localStorage.getItem("user")));
    setdataPersonal(rtdo.data.data);
  };
  useEffect(() => {
    if (dataPersonal.length === 0) {
      console.log(dataPersonal.length);
      buscarCotizacion();
    } else {
      console.log(dataPersonal.length);
      return;
    }
  }, []);


  const seleccionarUser = (user, caso) => {
    setInfo(user);
    console.log(user);
    caso === "Editar" ? abrirCerrarModalEditar() : abrirCerrarModalEliminar();
  };

  const peticionPost = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };
    await axios
      .post(`${apiUrl}/employe`, info, { headers })
      .then((response) => {
        // setdata(data.concat(response.data));
        abrirCerrarModalInsertar();
      })
      .catch((error) => {
        console.log(error);
      });

    buscarCotizacion();
  };

  const peticionPost2 = async (e) => {
    console.log("post2");

    const f = new FormData();

    f.append("file", selectedImage);

    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };

    const url1 = `${apiUrl}/import-employe`;
    await axios
      .post(url1, f, { headers })
      .then((response) => {
        buscarCotizacion();
      })
      .catch((error) => {
        console.log(error);
        setSelectedImage();
      });

    // console.log(filesImg);
  };

  const peticionDelete = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };
    await axios
      .delete(baseUrl + "/" + info.id, { headers }, info)
      .then((response) => {
        // setdata(data.filter(artista=>artista.id!==info.id));
        abrirCerrarModalEliminar();
      })
      .catch((error) => {
        console.log(error);
      });
    buscarCotizacion();
  };

  const peticionPut = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Authorization"),
    };
    await axios
      .put(`${apiUrl}/employe` + "/" + info.id, info, { headers: headers })
      .then((response) => {
        abrirCerrarModalEditar();
      })
      .catch((error) => {
        console.log(error);
      });
    buscarCotizacion();
  };

  const onSubmitEditar2 = (e) => {
    e.preventDefault();
    peticionPost2();
    abrirCerrarModalEditar2();
  };

  const onSubmitInsertar = (e) => {
    e.preventDefault();

    if (
      document.trim() === "" ||
      lastName.trim() === "" ||
      name.trim() === "" ||
      email.trim() === "" ||
      phone.trim() === ""
    ) {
      setError(true);
      return;
    } else {
      setError(false);

      peticionPost();
      setInfo({
        id: "",
        name: "",
        lastName: "",
        document: "",
        email: "",
      });
    }
  };
  const onSubmitEditar = (e) => {
    e.preventDefault();
    peticionPut();
  };

  const abrirCerrarModalInsertar = () => {
    setShowModalInsertar(!showModalInsertar);
  };
  const abrirCerrarModalEditar2 = () => {
    setShowModalEditar2(!showModalEditar2);
  };

  const abrirCerrarModalEditar = () => {
    setShowModalEditar(!showModalEditar);
  };
  const abrirCerrarModalEliminar = () => {
    setShowModalEliminar(!showModalEliminar);
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
      console.log(e.target.files[0]);
      // setSelectedFilesPost(e.target.files[0])
    }
  };
 
  return (
    <div>
      <div>
        <TitlePage titulo="Personal de Servicio" />
        {dataUser.roleId === "1" ? (
          <div className="flex justify-end ">
            <button className="btn" onClick={() => abrirCerrarModalInsertar()}>
              Agregar personal
            </button>
            <button className="btn" onClick={() => abrirCerrarModalEditar2()}>
              Importar Plantilla
            </button>
            <button className="btn">
              {/* <Link to="../../IMG/Pagos 1gastos.svg" target="_blank" download>Descagar Plantilla</Link> */}
              <a className="enlace" href={excel} download>
                Descagar Plantilla
              </a>
            </button>
          </div>
        ) : null}
        {loading ? (
          <Box sx={{ position: "absolute", left: 500, top: 500, zIndex: 1 }}>
            <CircularProgress color="success" size={80} />
          </Box>
        ) : null}

        {dataUser.roleId === "1" ? (
          <div className="mt-10">
            <Table2
              title=""
              columns={customerTableHead}
              data={dataPersonal}
              actions={[
                {
                  icon: () => <i class="material-icons edit">edit</i>,
                  tooltip: "Editar",
                  onClick: (event, rowData) =>
                    seleccionarUser(rowData, "Editar"),
                },
                {
                  icon: () => (
                    <i class="material-icons delete">highlight_off</i>
                  ),
                  tooltip: "Eliminar",
                  // onClick: (event, rowData) => seleccionarUser(rowData, "Eliminar")
                  onClick: (event, rowData) =>
                    seleccionarUser(rowData, "Eliminar"),
                },
              ]}
            />
          </div>
        ) : (
          <div className="mt-10">
            <Table2 title="" columns={customerTableHead} data={data} />
          </div>
        )}
      </div>
      <ModalInsertar
        showmodalInsertar={showModalInsertar}
        functionShow={abrirCerrarModalInsertar}
        handleChangeInsert={handleChangeInsert}
        onSubmitInsertar={onSubmitInsertar}
        error={error}
        bodyInsertar={
          <BodyInsertar 
          onSubmitInsertar={onSubmitInsertar}
          styles={styles}
          error={error}
          handleChangeInsert={handleChangeInsert}
          abrirCerrarModalInsertar={abrirCerrarModalInsertar}
          />
        }
      />
      {dataUser.roleId === "1" ? (
        <ModalEditar
          showModalEditar={showModalEditar}
          functionShow={abrirCerrarModalEditar}
          handleChangeInsert={handleChangeInsert}
          onSubmitEditar={onSubmitEditar}
          info={info}
          bodyEditar={
            <BodyEditar 
            onSubmitEditar={onSubmitEditar}
            styles={styles}
            handleChangeInsert={handleChangeInsert}
            info={info}
            abrirCerrarModalEditar={abrirCerrarModalEditar}
            />
          }
        />
      ) : null}
      <ModalEditar2
        showModalEditar2={showModalEditar2}
        functionShow={abrirCerrarModalEditar2}
        handleChangeInsert={handleChangeInsert}
        onSubmitEditar={onSubmitEditar}
        info={info}
        bodyEditar2={
          <BodyEditar2
          styles={styles}
          onSubmitEditar2={onSubmitEditar2}
          error={error}
          imageChange={imageChange}
          selectedImage={selectedImage}
          abrirCerrarModalEditar2={abrirCerrarModalEditar2}
          />
        }
      />
      {dataUser.roleId === "1" ? (
        <ModalEliminar
          showModalEliminar={showModalEliminar}
          abrirCerrarModalEliminar={abrirCerrarModalEliminar}
          onSubmitEditar={onSubmitEditar}
          info={info}
          peticionDelete={peticionDelete}
          bodyEliminar={
            <BodyEliminar 
            abrirCerrarModalEliminar={abrirCerrarModalEliminar} 
            styles={styles}
            info={info}
            peticionDelete={peticionDelete}
            />
        }
        />
      ) : null}
    </div>
  );
}

export default Personal;
