import React from 'react'
import { Button } from "@material-ui/core";


export default function BodyEliminar(props) {
    const {
        abrirCerrarModalEliminar,
        styles,
        info,
        peticionDelete
    } = props
    
  return (
    <div className={styles.modal}>
      <p>
        Estás seguro que deseas eliminar <b>{info && info.name}</b>?{" "}
      </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()}>
          Sí
        </Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )
}
