import axios from "axios"
import { urlCondominiumExpense } from "../../../../utils/urls"



export const getGastosComunesCondominioInformation = async (token)=>{
    try{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  token,
        }
        const consulta = await axios.get(urlCondominiumExpense,{headers});
        return consulta.data.data;
    }catch(e){
        return false;
    }
}