


// falta loi de mail en editar

import "../users/Users.css"

import {Button, Modal, TextField} from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ModalDetails from '../../components/pageComponents/ModalDetails';
import ModalEditar from '../../components/pageComponents/ModalEditar';
import ModalEliminar from '../../components/pageComponents/ModalEliminar';
import ModalInsertar from "../../components/pageComponents/ModalInsertar"
import { ProveedoresContext } from '../../context/ProveedoresContext';
import Table2 from '../../components/Table2';
import TitlePage from '../../components/pageComponents/TitlePage';
import axios from "axios"
import {makeStyles} from '@material-ui/core/styles';
import moment from "moment";
import { userContext } from '../../context/UserContext';
import { apiUrl, urlAvatar, urlGuestProvider } from "../../utils/urls";
import { obtenerProveedores } from "./services/obtainProviders";
import { setNuevoProveedor } from "./services/setNewprovider";
import { editarProveedor } from "./services/editProvider";
import Swal from "sweetalert2";

// import { Switch } from 'antd';

const useStyles = makeStyles((theme) => ({
    modal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    iconos:{
      cursor: 'pointer'
    }, 
    inputMaterial:{
      width: '100%'
    }
  }));


const customerTableHead = [

{
    title:"Tipo",
    field: "type"
    // render: data => (data.date).split(" ")[0].split("-").reverse().join("-")

},
{
    title:"Nombre",
    field: "name"
},

{
    title:"Documento",
    field: "document"
}
,
{
    title:"Placa",
    field: "licensePlate"
}
,
  {
    title:"Cantidad",
    field: "quantity"
  },    
  {
    title:"Manzana",    
    render: data => data?.user?.properties?.[0]?.block

  },
  {
    title:"Lote",    
    render: data => data?.user?.properties?.[0]?.lot
  
  }
]



function Proveedores() {

    const [data, setdata] = useState([]);
    const [showModalInsertar, setShowModalInsertar] = useState(false);
    const [showModalEditar, setShowModalEditar] = useState(false);
    const [showModalEliminar, setShowModalEliminar] = useState(false);
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false);
    const [showModalDetails, setShowModalDetails] = useState(false);
    const { dataProveedores, setdataProveedores } = useContext(ProveedoresContext);
    const { dataUser, setdataUser } = useContext(userContext);
    const [info, setInfo] = useState({

      name: "",
      lastName: "",
      document:"",
      licensePlate:"",
      quantity: "10"
      
    })
    const{document, lastName,  name, licensePlate } = info;
    /**
     * Token de usuario
     */
    const token = localStorage.getItem('Authorization');
  
    const baseUrl=`${apiUrl}/guest-provider`;
    const handleChangeInsert = (e) => {
      setInfo({
        ...info,
        [e.target.name]: e.target.value
    })

    }
    
  /**
   * obtener listado de proveedores
   */
  const getListaProveedores = async() => {
    const consulta = await obtenerProveedores(token);
    setdataProveedores(consulta)
  }
  /**
   * Muestra carga incial
   */
  useEffect(() => {
    const init = ()=>{
      getListaProveedores()
    }
    init();
    return ()=>{
      setdataProveedores([]);
    }
  }, []);


    

    const seleccionarUser=(user, caso)=>{
        setInfo(user);
        console.log(user);
        (caso==="Editar")?abrirCerrarModalEditar()
        : 
        abrirCerrarModalEliminar() 
      }
      const seleccionarUser2=(infoProveedor)=>{
        console.log("Información obtenida:",infoProveedor)
        setInfo(infoProveedor);
        abrirCerrarModalDetails() 
      }


        const peticionDelete=async()=>{
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
      
        }
          await axios.delete(baseUrl+"/"+info.id, {headers}, info) 
          .then(response=>{
            // setdata(data.filter(artista=>artista.id!==info.id));
            abrirCerrarModalEliminar();
          }).catch(error=>{ 
            console.log(error);
          })
          getListaProveedores()
        }

    const editProvider=async()=>{       
      const consulta = await editarProveedor(token, info);
      if(consulta){
        abrirCerrarModalEditar();
        Swal.fire({
          title: 'Cambios guardados',
          icon: 'success'
        });
      }else{
        Swal.fire({
          title: 'Error al guardar los cambios',
          icon: 'error'
        })
      }
      await getListaProveedores();
    }
      
    const onSubmitEditar = (e) => {
      e.preventDefault();
      editProvider();
    }
      const abrirCerrarModalDetails=()=>{
        setShowModalDetails(!showModalDetails);
        console.log(info);
      }

      const abrirCerrarModalEditar=()=>{
        setShowModalEditar(!showModalEditar);
      }
      const abrirCerrarModalEliminar=()=>{
        setShowModalEliminar(!showModalEliminar);
      }
      const styles= useStyles();

      const bodyDetails =(
        <div className={styles.modal}>
            <div className="estilosmodalDetails">
                <h1>Detalle</h1>
                <div className='linea'></div>
                <h3 >Nombre proveedor: <span className="mt-5 detailsInfo">{info&&info.name}</span></h3>
                <h3 >Doc de Identidad: <span className="mt-5 detailsInfo">{info&&info.document}</span></h3>
                <h3 >Placa: <span className="mt-5 detailsInfo">{info&&info.licensePlate}</span></h3>
                <h3 >Cantidad: <span className="mt-5 detailsInfo">{info&&info.quantity}</span></h3>
                <h3 >Lote: <span className="mt-5 detailsInfo">{info?.user?.properties?.[0]?.lot}</span></h3>
                <h3 >Manzana: <span className="mt-5 detailsInfo">{info?.user?.properties?.[0]?.block}</span></h3>
                <h3 >Tipo: <span className="mt-5 detailsInfo">{info&&info.type}</span></h3>
                <h3 >Ingreso: <span className="mt-5 detailsInfo">{moment(info&&info.startingdate).format("DD-MM-YYYY")}</span></h3>
                { info.type === "permanente" &&
                  <h3>Egreso: <span className="mt-5 detailsInfo">{moment(info&&info.endingdate).format("DD-MM-YYYY")}</span></h3>
                }
                {info.sctr !== null && info.sctr !== undefined ?
                  <div>
                    <a 
                      href={`${urlAvatar}/${info.sctr}`} 
                      target="_blank"  
                      className="linkdownload" 
                    >
                      <i className="material-icons file_download">file_download</i>
                    </a>
                  </div>
                  : <h3 className="mt-5 detailsInfo">No hay adjuntos</h3>
                }
                <button className="btn mt-5" onClick={()=>abrirCerrarModalDetails()}>Volver</button>
            </div>       
          </div>       
        )

      const bodyEditar=(
        <form action="" onSubmit={onSubmitEditar}>
          <div className={styles.modal}>
            <h3 className="my-5">Registrar usuario nuevo</h3>
            { error ? <h4 className=" text-red-700">Completar todos los campos del formulario</h4> : null }
            <TextField className={styles.inputMaterial} name="name" onChange={handleChangeInsert} value= {info&&info.name} label="Nombre" />
            <br />
            <TextField className={styles.inputMaterial} name="licensePlate" onChange={handleChangeInsert} value= {info&&info.licensePlate} label="Placa" />          
              <br />
              <TextField className={styles.inputMaterial} name="document" onChange={handleChangeInsert} value= {info&&info.document} label="Doc. de Identidad" />
            <br />
              <TextField className={styles.inputMaterial} name="licensePlate" onChange={handleChangeInsert} value= {info&&info.licensePlate} label="Patente" />
            <br />
              <TextField className={styles.inputMaterial} name="quantity" onChange={handleChangeInsert} value= {info&&info.quantity} label="Cantidad" />
            <br />
     
            <br /><br />
            <div align="right">
              <Button color="primary" type="submit" >Editar</Button>
              <Button onClick= {()=>abrirCerrarModalEditar()}> Cancelar</Button>
            </div>
          </div>
        </form>
      )

      const bodyEliminar=(
        <div className={styles.modal}>
          <p>Estás seguro que deseas eliminar  <b>{info&&info.name}</b>? </p>
          <div align="right">
            <Button color="secondary" onClick={()=>peticionDelete()}>Sí</Button>
            <Button onClick={()=>abrirCerrarModalEliminar()}>No</Button>
    
          </div>
    
        </div>
      )

    return (
        <div>
            <div>
                <TitlePage titulo="Proveedores" />
                { loading ?  <Box sx={{ position: 'absolute' , left: 500, top:500, zIndex:1}}>
           
           <CircularProgress color="success" size={80}/>
           </Box> : null}

           {dataUser.roleId === "1" ? 


                 <div className="mt-10"><Table2 
                 title="" 
                 columns={customerTableHead} 
                 data={dataProveedores}
                 actions= {[  
                    {
                        icon:() => <span className="material-icons find">
                        find_in_page
                        </span>,
                        tooltip:"Detalles",
                        onClick: (event, rowData) => seleccionarUser2(rowData) 
                    },                  
                
                            {
                        icon:() => <i className="material-icons edit">edit</i>,
                        tooltip:"Editar",
                        onClick: (event, rowData) => seleccionarUser(rowData, "Editar") 
                    },
                    {
                        icon:() => <i className="material-icons delete">highlight_off</i>,
                        tooltip:"Eliminar",
                        // onClick: (event, rowData) => seleccionarUser(rowData, "Eliminar")   
                        onClick: (event, rowData) => seleccionarUser(rowData, "Eliminar")
                    }
          
                ] }

                 /></div>
                 :  <div className="mt-10"><Table2 
                 title="" 
                 columns={customerTableHead} 
                 data={data}
                 actions= {[  
                    {
                        icon:() => <span className="material-icons find">
                        find_in_page
                        </span>,
                        tooltip:"Detalles",
                        onClick: (event, rowData) => seleccionarUser2(rowData) 
                    }
          
                ] }
              

                 /></div>}
            </div>
            <ModalDetails
              showModalDetails={showModalDetails}
              functionShow= {abrirCerrarModalDetails}
              info={info}
              bodyDetails={bodyDetails}
            />
              { dataUser.roleId === "1" ?
            <ModalEditar
            showModalEditar={showModalEditar}
            functionShow= {abrirCerrarModalEditar}
            handleChangeInsert={handleChangeInsert}
            onSubmitEditar={onSubmitEditar}
            info={info}
            bodyEditar={bodyEditar}
            />
            :null}
            { dataUser.roleId === "1" ?
            <ModalEliminar
            showModalEliminar={showModalEliminar}
            abrirCerrarModalEliminar= {abrirCerrarModalEliminar}
            onSubmitEditar={onSubmitEditar}
            info={info}
            peticionDelete={peticionDelete}
            bodyEliminar={bodyEliminar}
            />
            :null}
        </div>
    )
}

export default Proveedores
