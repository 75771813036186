import axios from "axios";
import { getHeaders } from "../../../utils/methods";
import { urlSpaces } from "../../../utils/urls";

export const getSpaces = async (token) => {
    try{
        const headers = getHeaders(token);
        const consulta = await axios.get(urlSpaces, headers);
        const response = consulta.data.data;
        return response;
    }catch(e){
        console.log("Error en getEspacios:",e);
        return false;
    }
}