import * as Yup from 'yup';
import { ERROR_ONLY_NUMBERS, ERRRO_FIELD_REQUIRED } from '../../../../utils/errors/dictionaryErrors';

const addGastosComunesSchema = Yup.object().shape({
    concept: Yup.string()
            .required(ERRRO_FIELD_REQUIRED),
    invoiceNumber: Yup.string()
            .required(ERRRO_FIELD_REQUIRED),
    amount: Yup.string()
            .required(ERRRO_FIELD_REQUIRED)
            .matches(/^\d+\.\d+/gm, ERROR_ONLY_NUMBERS)
});

export default addGastosComunesSchema;