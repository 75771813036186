import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlProperty, urlPropertyUser, urlUser } from "../../../utils/urls";

const borrarPropiedad = async (idPropiedad, token) =>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.delete(`${urlProperty}/${idPropiedad}`,header);
        if(consulta.status === 201){
            return true;
        }else{
            return false;
        }
    }catch(e){
        console.log("Error al borrar la propiedad:",e);
        return false;
    }
}

const borrarPropiedadUsuario = async (idRelacion, token)=>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.delete(`${urlPropertyUser}/${idRelacion}`, header);
        if(consulta.status === 201){
            return true;
        }else{
            return false;
        }
    }catch(e){
        console.log("Error al borrar relacion propiedad usuario:",e);
        return false;
    }
}

export const deleteUsuario = async (infoUser, token)=>{
    const header = getHeaders(token);
    try{
        const deletePropiedad = await borrarPropiedad(infoUser.propertyId, token);
        const deletePropiedadUsuario = await borrarPropiedadUsuario(infoUser.id, token);
        if(deletePropiedad && deletePropiedadUsuario){
            const consulta = await axios.delete(`${urlUser}/${infoUser.userId}`, header);
            if(consulta.status === 201){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }catch(e){
        console.log("Error al borrar usuario:",e);
        return false;
    }
}

export const borrarUsuarioPorId = async (id, token)=>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.delete(`${urlUser}/${id}`, header);
        if(consulta.status === 201){
            return true;
        }else{
            return false;
        } 
    }catch(e){
        console.log("Error al borrar usuario:",e);
        return false;
    }
}