import "../users/Users.css"
import "react-datepicker/dist/react-datepicker.css";

import {Button, Modal, TextField} from '@material-ui/core';
import {
    NavLink,
} from "react-router-dom";
import React,{useContext, useEffect, useState}  from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons"

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import DatePicker from "react-datepicker";
import {GastosContext} from '../../context/GastosContext';
import ModalEditar from '../../components/pageComponents/ModalEditar';
import ModalEliminar from '../../components/pageComponents/ModalEliminar';
import ModalInsertar from "../../components/pageComponents/ModalInsertar"
import Table2 from '../../components/Table2';
import TitlePage from '../../components/pageComponents/TitlePage';
import { TotalCondoContext } from "../../context/TotalCondContext";
import axios from "axios"
import {makeStyles} from '@material-ui/core/styles';
import moment from "moment";
import { userContext } from '../../context/UserContext';
import TabOptionsRoute from "./routes/tabsRoutes";
import { useSelector, useDispatch } from 'react-redux';
import "./styles/GastosComunesPage.scss";
import { setGastosPagosPeriodoInRedux } from "../../redux/actions/GastosyPagosActions";
import { apiUrl, urlCondominiumExpense } from "../../utils/urls";
import { getHeaders } from "../../utils/methods";
import CustomTable from "../../components/CustomTable/TableCustom";
import { money } from "../../utils/format";
import { ErrorMessage, Field, Form, Formik } from "formik";
import addGastosComunesSchema from "./GastosComunes/validations/addGastos.schema";
import Loader from "../../components/LoaderComponent";

const useStyles = makeStyles((theme) => ({
    modal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    iconos:{
      cursor: 'pointer'
    }, 
    inputMaterial:{
      width: '100%'
    }
  }));


const customerTableHead = [

{
    title:"Concepto",
    field: "concept"
},
{
    title:"N° de Factura",
    field: "invoiceNumber"
},
{
    title:"Periodo",
    field: "date",
    render: data => (data.date).split(" ")[0].split("-").reverse().join("-").slice(3, 10)
},
{
  title: "Estado",
  field: "approved",
  render: data => parseInt(data.approved) === 1 ? 'Aprobado' : 'Sin aprobar'
},
{
    title:"Monto",
    field: "amount"
},
{
    title:"Archivo",
    field: "document"
}
]



function GastosComunes() {

    const [total, setTotal] = useState(0);
    const [showModalInsertar, setShowModalInsertar] = useState(false);
    const [showModalEditar, setShowModalEditar] = useState(false);
    const [showModalEliminar, setShowModalEliminar] = useState(false);
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState();
    const [selectedFilesPost, setSelectedFilesPost] = useState();
    const [filteredData,setFilteredData]=useState([]);
    const { dataGastos, setdataGastos } = useContext(GastosContext);
    //Token del usuario
    const token = localStorage.getItem('Authorization');

    //let save status of approvade of gasto
    const [allApproved, setallApproved] = useState(false)
    const [arrApproved, setarrApproved] = useState([])

    const [exito, setExito] = useState(false);

    const { setTotalCondo } = useContext(TotalCondoContext);
    const { dataUser, setdataUser } = useContext(userContext);
    
    const [info, setInfo] = useState({

        amount: "",
       
        concept: "",
       
        date: "",
        document: "",
        id: "",
        invoiceNumber: "",

        transactionCost: ""

    
    })

    
    /**
     * Reducers necesarios para la integridad del sistema
     */
     const periodoStore = useSelector((store)=>store.PeriodoControllerReducer);
     /**
      * Acciones para el control de los periodos
      */
     let dispatch = useDispatch();
     /**
      * Inicialización del estado de la página
      */
     useEffect(() => {
      /**
      * Mandar al redux la fecha actual
      */
     const getPeriod = () =>{
        if(periodoStore.periodo === ""){
          const actualDate = new Date();
          const tempPeriod = moment(actualDate).format("YYYY-MM");
          dispatch(setGastosPagosPeriodoInRedux(tempPeriod));
        }
     }
     /**
      * Obtener la data de los condominios
      */
     const init = async ()=>{
       setLoading(true);
       if(dataGastos.length === 0){
         try{
           const header = getHeaders(token);
           const consulta = await axios.get(urlCondominiumExpense, header);
           const response = consulta.data.data;
           setdataGastos(response);
         }catch(e){
           alert("Error al obtener la información del condominio");
           console.log("Error condominio:",e);
         }
       }
       setLoading(false);
     }
     init();
     getPeriod();
   }, []);
   /**
    * Si cambia el periodo, cambiamos el filtro
    */   
    useEffect(()=>{
      //console.log("Use effect de filtros");
      const changeFilter = ()=>{
        const filterPeriod = dataGastos.filter(itm=>itm.date.slice(0,7) === periodoStore.periodo);
        //console.log("Periodo de filtro:",filterPeriod);
        setFilteredData(filterPeriod);
        sumarGastosTotales(filterPeriod);

        // quit button add registro
        let arr = []
        
        filterPeriod.map((val) => {
          arr.push(val.approved)
        })

        //setarrApproved(arr)

        const auxEvery = (e) => e == 1

        if(arr.every(auxEvery))
        {
          console.log(arr)
          setallApproved(true)

        } else{
          setallApproved(false)
        }

        if(arr.length===0) {
          setallApproved(false)
        }
        
      }
      changeFilter();
    },[periodoStore?.periodo, dataGastos]);

    const sumarGastosTotales = (data=[]) => {
      const suma = data.reduce((t,{ amount })=> t+parseFloat(amount),0);
      setTotal(suma)
      setTotalCondo(suma)
    }


    const{document, amount,  date, invoiceNumber, propertyId, transactionCost, concept } = info;
  
    const baseUrl=`${apiUrl}/condominium-expense`;
    const handleChangeInsert = (e) => {
      setInfo({
          ...info,
          [e.target.name]: e.target.value
      })
    }
    
    const buscarCotizacion1 = async() => {
      const header = getHeaders(token);
      const rtdo = await axios.get(urlCondominiumExpense, header)
      setdataGastos(rtdo.data.data)
      setdataUser(JSON.parse(localStorage.getItem('user')))
    }

    const seleccionarUser=(user, caso)=>{
        setInfo(user);
        console.log(user);
        (caso==="Editar")?abrirCerrarModalEditar()
        : 
        abrirCerrarModalEliminar() 
      }


      const addGastoComun=async()=>{
        const f = new FormData();
        
        const dateInsert = moment(new Date(periodoStore.periodo+"-10")).format("YYYY-MM-DD");   
        if (selectedFilesPost) {
          f.append("file", selectedFilesPost)
        }
        f.append("date", dateInsert)
        f.append("concept", info.concept)
        f.append("invoiceNumber", info.invoiceNumber)
        f.append("amount", parseInt(info.amount) )
        f.append("transactionCost","1" )
    
        const headers = {
          'Content-type': 'multipart/form-data',
          'Authorization': 'Bearer ' +  token,
        }
        try{
          await axios.post(urlCondominiumExpense, f, {headers})
          await setSelectedFilesPost();
          await buscarCotizacion1();
        }catch(e){
          alert("Error en la insersión");
          console.log("Error insersion:",e);
        }
      }
      


        const peticionDelete=async()=>{
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
      
        }
          await axios.delete(baseUrl+"/"+info.id, {headers}, info) 
          .then(response=>{
            // setdata(data.filter(artista=>artista.id!==info.id));
           
        }).catch(error=>{ 
            console.log(error);
        })
        buscarCotizacion1()
        abrirCerrarModalEliminar();
        }

        const peticionPut=async()=>{       

          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
      
        }
          await axios.put(`${apiUrl}/condominium-expense`+"/"+info.id,  info , {headers: headers})
          .then(response=>{

            abrirCerrarModalEditar();
           
          }).catch(error=>{
            console.log(error);
          })
         buscarCotizacion1()
        }
      

        const removeSelectedImage = () => {
            setSelectedImage();
        };
        const imageChange = (e) => {
            if (e.target.files && e.target.files.length > 0) {
              setSelectedImage(e.target.files[0]);
              setSelectedFilesPost(e.target.files[0])
            }
        };

    const onSubmitInsertar = async (e) => {
        e.preventDefault();
        setLoading(true);
        try{
          if (concept.trim() === ""  ){
            setError(true);
          }else{
            setError(false);
            await addGastoComun();
            setInfo({
              id: "",
              name: "",
              lastName: "",
              document:"",
              email: "",
              file: ""
            });
            setSelectedFilesPost();
            abrirCerrarModalInsertar();
          }
        }catch(e){
          alert("Error al añadir gasto");
          console.log("Error submitInsert:",e);
        }
        setLoading(false);
    }
    const onSubmitEditar = (e) => {

      e.preventDefault();
            peticionPut();
        }

    
    const abrirCerrarModalInsertar = () => {
          
        setShowModalInsertar(!showModalInsertar)
      }

      const abrirCerrarModalEditar=()=>{
        setShowModalEditar(!showModalEditar);
      }
      const abrirCerrarModalEliminar=()=>{
        setShowModalEliminar(!showModalEliminar);
      }
      const styles= useStyles();
      const initialValuesFormikaddGastos = {
        concept:'',
        invoiceNumber:'',
        amount:undefined,
        file:undefined
      }
      const bodyInsertar=(
        <form action="" onSubmit={onSubmitInsertar}>
          <div className={styles.modal}>
            <h3 className="my-5">Agregar Nuevo Gasto</h3>
            { error ? <h4 className=" text-red-700">Completar todos los campos (*) del formulario</h4> : null }
            <TextField className={styles.inputMaterial} name="concept" onChange={handleChangeInsert} label="Concepto*"  />
            <TextField className={styles.inputMaterial} name="invoiceNumber" onChange={handleChangeInsert}  label="N° de Factura*" />  
            <TextField className={styles.inputMaterial} name="amount" onChange={handleChangeInsert}  label="Monto" />
            <div className="mt-3">Periodo:{ periodoStore.periodo }</div>
            <div className='mt-5'>
                <input type="file"  onChange={imageChange} id="file" name='file'/>
                <div className="label-holder">
                    <label htmlFor="file" className="label">
                    <i className="material-icons">attach_file</i>
                    </label>
                </div>
            </div>
            <h4><span className="detailsInfo">{selectedImage&&selectedImage.name}</span></h4>
            {selectedImage && (
            <div className='eliminarImg'>
                <button onClick={removeSelectedImage} style={styles.delete}>
                Eliminar
                </button>
            </div>
            )}
            <br /><br />
            <div align="right">
              <Button color="primary" type="submit" >Insertar</Button>
              <Button onClick= {abrirCerrarModalInsertar}> Cancelar</Button>
            </div>
          </div>
        </form>
      )


      const bodyEditar=(
        <form action="" onSubmit={onSubmitEditar}>
          <div className={styles.modal}>
            <h3 className="my-5">Registrar gasto nuevo</h3>
            { error ? <h4 className=" text-red-700">Completar todos los campos del formulario</h4> : null }
            { error ? <h4 className=" text-red-700">Completar todos los campos (*) del formulario</h4> : null }
            <TextField className={styles.inputMaterial} name="concept" onChange={handleChangeInsert} label="Concepto*" value= {info&&info.concept} />
            <br />
            <TextField className={styles.inputMaterial} name="invoiceNumber" onChange={handleChangeInsert}  label="N° de Factura*"  value= {info&&info.invoiceNumber}/>          
              <br />
              <TextField className={styles.inputMaterial} name="amount" onChange={handleChangeInsert}  value= {info&&info.amount} label="Monto" />
            <br />
              {/* <TextField className={styles.inputMaterial} name="transactionCost" onChange={handleChangeInsert}  value= {info&&info.transactionCost} label="Costo de Transacción*" /> */}
              <br />
            
              <label htmlFor="" className='mt-5'>Fecha de publicación</label>
         
            
            <input type="date" className={styles.inputMaterial} name="date" onChange={handleChangeInsert} label="Fecha de Publicación*" value= {info&&info.date} />

            <br />
            <br />
     
            <br /><br />
            <div align="right">
              <Button color="primary" type="submit" >Editar</Button>
              <Button onClick= {()=>abrirCerrarModalEditar()}> Cancelar</Button>
            </div>
          </div>
        </form>
      )

      const bodyEliminar=(
        <div className={styles.modal}>
          <p>Estás seguro que deseas eliminar  <b>{info&&info.name}</b>? </p>
          <div align="right">
            <Button color="secondary" onClick={()=>peticionDelete()}>Sí</Button>
            <Button onClick={()=>abrirCerrarModalEliminar()}>No</Button>
    
          </div>
    
        </div>
      )


      const peticionPostTotal=async()=>{

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
    
      }
       
       


    
      
          await axios.post(`${apiUrl}/invoice`, {propertyId:1, stateId:1}, {headers})
          .then(response=>{
            // setdata(data.concat(response.data));
            abrirCerrarModalInsertar();
          }).catch(error=>{
            console.log(error);
          })

          // buscarCotizacion()
      
        }


      const actualizarState = async() => {

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
    
      }

        for (let i = 0; i < dataGastos.length; i++) {
          
        await axios.put(`${apiUrl}/condominium-expense`+"/"+dataGastos[i].id,  {approved:"1"} , {headers: headers})
        .then(response=>{

          console.log("exitoso");
          setExito(true)
        }).catch(error=>{
          console.log(error);
        }) 
          
          }
          
        }
    /**
     * Control de los periodos
     */
    const _handleDate = (date)=>{
      const periodo = moment(date).format("YYYY-MM");
      dispatch(setGastosPagosPeriodoInRedux(periodo));
    }
    const _handleSelectPeriodCalendar = ()=>{
      if(periodoStore.periodo !== ""){
        return new Date(periodoStore.periodo+"-20");
      }else{
        return new Date();
      }
    }

    //console.log(dataUser.roleId)

    return (
      <div>
        <div>
          <TitlePage titulo="Gastos Comunes" />
          <TabOptionsRoute/>
          {dataUser.roleId === "4" && <div className="flex justify-end">
            { exito ?         null    :
            <button className='btn btn-2' onClick={actualizarState} >

                  <h1 className="title1">Aprobar</h1>

            </button>  } 
            </div>
            }
          <div className="flex justify-between mt-16">
          
            {
              allApproved === false &&
              <button className="btn" onClick={() => abrirCerrarModalInsertar()} >
                Agregar gasto
              </button> 
            }
                
          
 
            <div className="pickFecha">
              <div className="flex">
                <h3>Periodo: </h3> <br />
                <DatePicker
                  wrapperClassName="datePicker"
                  selected={_handleSelectPeriodCalendar()}
                  onChange={(date) => _handleDate(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              </div>
            </div>

      <div className="text-gray-400">
        <h2>Total de gastos: { money.format(total) }</h2>
      </div>
</div>

          <div className="mt-10 tableGastos">
            {<CustomTable columns={customerTableHead}>
              {
                filteredData.map(itm=>
                  <tr>
                    <td>{itm.concept}</td>
                    <td>{itm.invoiceNumber}</td>
                    <td>{itm.date.slice(0,7)}</td>
                    <td>{parseInt(itm.approved) === 1 ? 'Aprobado' : 'Sin aprobar'}</td>
                    <td>{ money.format(itm.amount)}</td>
                    <td className="documentText">{itm.document}</td>
                    {dataUser?.roleId === "1" && parseInt(itm.approved) !== 1 ? 
                    <td className="buttonContainerActions">
                      <button className="button" onClick={()=>seleccionarUser(itm,"Editar")}><i className="material-icons edit">edit</i>,</button>
                      <button className="button" onClick={()=>seleccionarUser(itm,"Eliminar")}><i className="material-icons delete">highlight_off</i></button>
                    </td> : null
                    }
                  </tr>
                )
              }
            </CustomTable>}
          </div>
          <div className="float-calculus-view">
            {total === 0 ? 
              <div className="linkDisabled">
                <FontAwesomeIcon icon={faCalculator} style={{ marginRight:"8px" }}/> Ver Cálculos
              </div> 
              : 
              <NavLink className="linkButton" exact to="/Calculos">
                <FontAwesomeIcon icon={faCalculator} style={{ marginRight:"8px" }}/> Ver Cálculos
              </NavLink>
            }
          </div>
        </div>
        <Loader title="Cargando datos" active={loading}/>
            <ModalInsertar
            showmodalInsertar={showModalInsertar}
            functionShow= {abrirCerrarModalInsertar}
            handleChangeInsert={handleChangeInsert}
            onSubmitInsertar={onSubmitInsertar}
            error={error}
            bodyInsertar={bodyInsertar}
            />
            <ModalEditar
            showModalEditar={showModalEditar}
            functionShow= {abrirCerrarModalEditar}
            handleChangeInsert={handleChangeInsert}
            onSubmitEditar={onSubmitEditar}
            info={info}
            bodyEditar={bodyEditar}
            />
            <ModalEliminar
            showModalEliminar={showModalEliminar}
            abrirCerrarModalEliminar= {abrirCerrarModalEliminar}
            onSubmitEditar={onSubmitEditar}
            info={info}
            peticionDelete={peticionDelete}
            bodyEliminar={bodyEliminar}
            />

            
        </div>
    )
}

export default GastosComunes
