// import { useState } from "react";

import { useState } from "react";
import { months, years } from "../constants/dateConstants";
import "../styles/SubTitleExpense.css";

const SubTitleExpense = ({
    onChange
})=>{
    const [year,setYear] = useState('0000');
    const [month,setMonth] = useState('00');
    
    const handleMonthChange = (e)=>{
        setMonth(e.target.value);
        return onChange(`${year}-${e.target.value}`);
    }

    const handleYearChange = e =>{
        setYear(e.target.value);
        return onChange(`${e.target.value}-${month}`);
    }

    return(
        <div className="optionsCointainer">
            <select className="moneySelect">
                <option selected value=""> $ </option>
            </select>
            <div>
                <select className="dateSelect" onChange={handleYearChange}>
                    <option selected value=""> Año </option>
                    {years.map(itm=><option value={itm}>{itm}</option>)}
                </select>
                <select className="dateSelect" onChange={handleMonthChange}>
                    <option selected value=""> Mes </option>
                    {months.map(itm=><option value={itm.value}>{itm.name}</option>)}
                </select>
            </div>
        </div>
    )

}

SubTitleExpense.defaultProps = {
    onChange:()=>{}
}

export default SubTitleExpense;