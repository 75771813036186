import './Home.css'

import React, {useContext, useEffect, useState} from 'react';

import Featured from '../../components/featuredInfo/Featured'
import Graphics from "../../components/graphics/Graphics"
import PendingPayments from '../../components/pendingPayments/PendingPayments';
import moment from 'moment';
import { userContext } from '../../context/UserContext';
import { getInvoiceForOwner } from '../gastos/NewExpenseRecordPage/services/invoiceService';
import { getClaims } from './services/claimServices';
import { getFormalitiesOwner } from './services/formalitiesService';
import { getReservations } from './services/reservationServices';
import { urlAvatar } from '../../utils/urls';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/LoaderComponent';
import { getReclamo } from './services/getReclamo';

function Home() {
  const { dataUser, setdataUser } = useContext(userContext);
  const [dataInvoice, setDataInvoice] = useState([]);
  const [totalClaims, setTotalClaims] = useState(0);
  const [totalReclamos, setTotalReclamos] = useState(0);
  const [totalFormalities, setTotalFormalities] = useState(0);
  const [totalReservations, setTotalReservations] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  /**
   * Token
   */
  const token = localStorage.getItem('Authorization');
  const userStorage = localStorage.getItem('user');
  /**
   * History control
   */
  const history = useHistory();
  /**
   * Inicialización de datos
   */
  useEffect(() => {
    const init = async ()=>{
      setIsLoading(true);
      if(userStorage !== ""){
        setdataUser(JSON.parse(userStorage));
        if(token){
          try{
            const data = await getInvoiceForOwner(token);
            if(data !== false){
              const filterData = data.filter(itm=>itm.state.name === "Pendiente" && itm.property !== null);
              const newData = filterData.map(itm=>({
                name: itm?.property?.users?.[0]?.name + " " + itm?.property?.users?.[0]?.lastName,
                propiedad: itm?.property?.block.toUpperCase()+"-"+itm?.property?.lot,
                telefono: itm?.property?.users?.[0]?.phone,
                periodo: itm?.date.slice(0,7),
                status: itm?.state?.name,
                avatarUrl: urlAvatar + "/" + itm?.property?.users?.[0]?.avatar
              }))
              setDataInvoice(newData);
            }
            /**
             * Obtener las quejas pendientes
             */
            const claims = await getClaims(token);
            setTotalClaims(claims.length);
            /**
             * Obtener las reclamos pendientes
             */
            const reclamos = await getReclamo(token);
            setTotalReclamos(reclamos.length);
            /**
             * Tramites pendientes
             */
            const formalities = await getFormalitiesOwner(token);
            setTotalFormalities(formalities.length);
            /**
             * Reservaciones pendientes
             */
            const reservations = await getReservations(token);
            const actualDate = moment(new Date()).format("YYYY-MM-DD");
            const dataFilter = reservations.filter(itm => itm.start.slice(0,10) === actualDate);
            setTotalReservations(dataFilter.length);
          }catch(e){
            console.log("ERROR:",e);
          }
        }
      }
      setIsLoading(false);
    }
    init();
  }, [token]);

  return (    
      <div className='homeContainer'>        
          <h1 id='tittleDashBoard'>Dashboard</h1>
          <Featured history={history} quejas={totalClaims} reclamos={totalReclamos} tramites={totalFormalities} reservas={totalReservations}/>
          <Graphics/>
          <PendingPayments data={dataInvoice}/>
          <Loader title={"Cargando datos del dashboard"} active={isLoading}/>
      </div>
  )
}

export default Home
