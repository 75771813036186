import { TYPE_SET_PERIODO } from "../types/GastosyPagosTypes";

const INITAL_STATE_PERIODO = {
    periodo: ""
};

export const PeriodoControllerReducer = (state=INITAL_STATE_PERIODO,action)=>{
    switch(action.type){
        case TYPE_SET_PERIODO:
            return {...state,periodo:action.periodo};
        default:
            return state;
    }
}