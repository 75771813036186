import React from 'react'
import { Button } from "@material-ui/core";

export default function BodyEditar2(props) {
    const {
        styles,
        onSubmitEditar2,
        error,
        imageChange,
        selectedImage,
        abrirCerrarModalEditar2
    } = props
  return (
    <form action="" onSubmit={onSubmitEditar2}>
    <div className={styles.modal}>
      <h3 className="my-5">Adjuntar Excel para su importación</h3>
      {error ? (
        <h4 className=" text-red-700">
          Completar todos los campos del formulario
        </h4>
      ) : null}
      {error ? (
        <h4 className=" text-red-700">
          Completar todos los campos (*) del formulario
        </h4>
      ) : null}
      {error ? (
        <h4 className=" text-red-700">
          Completar todos los campos (*) del formulario
        </h4>
      ) : null}
      {/* <TextField className={styles.inputMaterial} name="consume" onChange={handleChangeInsert} label="Kw consumidos*" type="number" /> */}
      <div className="mt-5">
        {/* <label>Choose File to Upload: </label> */}
        <input type="file" onChange={imageChange} id="file" />
        <div className="label-holder">
          <label htmlFor="file" className="label">
            <i className="material-icons">note_add</i>
          </label>
        </div>
      </div>{" "}
      <br />
      {selectedImage && (
        <div className="eliminarImg">
          <h4>{selectedImage.name}</h4>

          {/* <button onClick={removeSelectedImage} style={styles.delete}>
                Eliminar
              </button> */}
        </div>
      )}
      <br />
      <br />
      <div align="right">
        <Button color="primary" type="submit">
          Importar
        </Button>
        <Button onClick={abrirCerrarModalEditar2}> Cancelar</Button>
      </div>
    </div>
  </form>
  )
}
