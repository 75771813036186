import axios from "axios";
import Swal from "sweetalert2";
import { getHeaderFiles, getHeaders } from "../../../utils/methods"
import { urlProcessObservation } from "../../../utils/urls";

export const observationTramite = async (body, type ,token) =>{
    let header = null;
    if(type === "file"){
        header = getHeaderFiles(token);
    }else{
        header = getHeaders(token);
    }
    try{
        const consulta = await axios.post(urlProcessObservation, body, header);
        if(consulta.status === 201){
            return true;
        }else{
            return false;
        }
    }catch(e){
        console.log("Error al crear la observacion:",e);
        const errores = e.response.data.errors;
        let alertMessage = ``;
        for(let i in errores){
            alertMessage += `<li>${errores[i][0]}</li>`;
        }
        Swal.fire({
            title: 'Error al crear la observación',
            html: '<ul>'+alertMessage+'</ul>',
            icon: 'error'
        });
        return false;
    }
}