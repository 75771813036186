import "./App.css"

import CondoProvider from "../src/context/Condocontext";
import Modal from 'react-modal';
import TotalCondoProvider from "../src/context/TotalCondContext";
import UserProvider, { userContext } from "../src/context/UserContext";
import { Provider } from "react-redux";
import store from "./redux/store";
import AppRoutes from "./routes";
import { useContext, useEffect, useState } from "react";

Modal.setAppElement('#root');

function App() {

  return (
    <Provider store={store}>
      <UserProvider>
        <TotalCondoProvider>
          <CondoProvider>
            <AppRoutes />
          </CondoProvider>
        </TotalCondoProvider>
      </UserProvider>
    </Provider>
  );
}

export default App;
