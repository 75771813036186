import "../styles/table.css";
import MaterialTable from "material-table"
import React from 'react';

const Table = props => {
    return (
        <div className="tableContainer">
            <MaterialTable
                columns= {props.columns}
                data = {props.data}      
                title={props.title}   
                actions={props.actions}
                style={{ width: "99%", border: 'solid .1rem rgb(255, 255, 255)', 'box-shadow': '0 0 0 0',}}
                options={{
                    paging: false,
                    maxBodyHeight: "100%",
                    actionsColumnIndex : -1,
                }}
                localization={{
                    header:{
                        actions:""
                    }
                }}
            />
        </div>
    )
}

export default Table
