import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlSpaces } from "../../../utils/urls";
import { setImageSpace } from "./addImageSpace";

export const editSpace = async (token, idSpace ,info, imageInfo) =>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.put(`${urlSpaces}/${idSpace}`, info, header);
        if(consulta.status === 201){
            const addNewImage = await setImageSpace(token, idSpace, imageInfo);
            if(addNewImage){
                return true;
            }
        }
    }catch(e){
        console.log("Error al editar el espacio:",e);
    }
    return false;
}