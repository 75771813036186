import { Button } from "@material-ui/core";
import React from "react";

export const BodyEliminar = ({
  info,
  styles,
  borrarTelefonos,
  abrirCerrarModalEliminar,
}) => {
  return (
    <div className={styles.modal}>
      <p>
        Estás seguro que deseas eliminar <b>{info && info.description}</b>?{" "}
      </p>
      <div align="right">
        <Button color="secondary" onClick={() => borrarTelefonos()}>
          Sí
        </Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  );
};
