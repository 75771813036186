import { Button, TextField } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";

export const BodyInsertar = ({
  onSubmitInsertar,
  styles,
  error,
  handleChangeInsert,
  imageChange,
  selectedImage,
  isImageFile,
  removeSelectedImage,
  abrirCerrarInsertar,
  selectedFilesPost
}) => {

  const {register,handleSubmit,formState:{errors}}= useForm()

  return (
    <form action="" onSubmit={handleSubmit(onSubmitInsertar)}>
      <div className={styles.modal}>
        <h3 className="my-5">Agregar archivo</h3>
      
        <TextField
          className={styles.inputMaterial}
          name="description"
          onChange={handleChangeInsert}
          label="Titulo*"
          {...register('description',{
            required:{
              value:true,
              message:'El campo es requerido'
            },
            minLength:{
              value:4,
              message:'La descripcion debe tener mas de 4 letras'
            }
          })}
        />
        <span className="text-danger text-small d-block mb-2">
                {errors?.descripcion?.message}
        </span>
        <br />
        <br />
        <br />
        <label htmlFor="">Fecha de Publicación*</label>
        <input
          type="date"
          className={styles.inputMaterial}
          name="publicationDate"
          onChange={handleChangeInsert}
          label="Fecha de Publicación*"
        />
        <div className="mt-5">
          <input type="file" onChange={imageChange} id="file" name="image" />
          <div className="label-holder">
            <label htmlFor="file" className="label">
              <i className="material-icons">attach_file</i>
            </label>
          </div>
        </div>{" "}
        <br />
        {selectedImage && isImageFile ? (
          <div className="eliminarImg">
            <img
              src={URL.createObjectURL(selectedImage)}
              className="foto1"
              alt="Thumb"
            />
            <button onClick={removeSelectedImage} style={styles.delete}>
              Eliminar
            </button>
          </div>
        ) : selectedImage && !isImageFile ? (
          <div className="eliminarImg">
            <div style={{ color: "#fff " }}>{selectedFilesPost.name}</div>
            <button onClick={removeSelectedImage} style={styles.delete}>
              Eliminar
            </button>
          </div>
        ) : null}
        <br />
        <br />
        <div align="right">
          <Button color="primary" type="submit">
            Insertar
          </Button>
          <Button onClick={abrirCerrarInsertar}> Cancelar</Button>
        </div>
      </div>
    </form>
  );
};
