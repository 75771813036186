import { useContext, useEffect, useState } from "react";
import Loader from "../../../components/LoaderComponent";
import TitlePage from "../../../components/pageComponents/TitlePage";
import Table2 from "../../../components/Table2";
import { userContext } from "../../../context/UserContext";
import { money } from "../../../utils/format";
import { obtainTotalastosCondominio } from "./actions/obtainTotalGastosCondominio";
import { saveInCacheGastosCondominios } from "./actions/saveInCacheGastosComunes";
import GastosComunesOptions from "./components/GastosComunesOptions";
import { CACHE_CONDOMINIO_INFORMATION_EXPIRED, CACHE_CONDOMINIO_INFORMATION_GASTOS } from "./constants/cacheConstants";
import { customerTableHeadGastosComunes } from "./constants/table.constants";
import TabOptionsRoute from "../routes/tabsRoutes";
import { getGastosComunesCondominioInformation } from "./services/InformationService";


const GastosComunesPage = () =>{
    //Context
    const { dataUser } = useContext(userContext);
    //State
    const [data,setData]= useState([]);
    const [load,setLoad]= useState(false);

    //Rol user
    const rolUser = dataUser?.roleId;

    //Inicialización
    useEffect(()=>{
        const init = async ()=>{
            setLoad(true);
            /**
             * Guardamos en el cache información de los condominioss
             */
            if(!localStorage.getItem(CACHE_CONDOMINIO_INFORMATION_GASTOS)){ 
                const info = await getGastosComunesCondominioInformation(localStorage.getItem('Authorization'));
                if(info !== false){
                    setData(info);
                    saveInCacheGastosCondominios(info);
                    alert(data);
                }
            }else{
                const horaDeExpirar = new Date(localStorage.getItem(CACHE_CONDOMINIO_INFORMATION_EXPIRED));
                const horaActual = new Date();
                const restaTiempo = horaDeExpirar.getTime() - horaActual.getTime();
                if(restaTiempo > 1000){
                    const info = JSON.parse(localStorage.getItem(CACHE_CONDOMINIO_INFORMATION_GASTOS));
                    setData(info);
                }else{
                    const info = await getGastosComunesCondominioInformation(localStorage.getItem('Authorization'));
                    console.log(info);
                    if(info !== false){
                        setData(info);
                        saveInCacheGastosCondominios(info);
                    }
                }
            }
            setLoad(false);
        }
        init();
    },[])
    const TotalCondominium = (data)=>{
        if(data.length == 0)
            return 0;
        return obtainTotalastosCondominio(data);
    }

    const getActionsTable = ()=>{
        if(rolUser !== "1")
            return []
        return [
            {
                icon: () => <i className="material-icons edit">edit</i>,
                tooltip: "Editar",
                onClick: (event, rowData) => {}
            },
            {
                icon: () => <i className="material-icons delete">highlight_off</i>,
                tooltip: "Eliminar",  
                onClick: (event, rowData) => {}
            }
        ]
    }

    const ifAprovadedAll = () => {
        let sumtrue = 0
        data.map((aprov) => {
            if(aprov.approved==1) {
                sumtrue++
            } sumtrue=sumtrue
        })

        if(sumtrue>=data.length) {
            return true
        } return false

    }

    console.log(data)

    return(
        <div>
            <TitlePage titulo="Gastos Comunes"/>
            <TabOptionsRoute/>
            <GastosComunesOptions approvedAll = {ifAprovadedAll}  role={rolUser} total={ money.format(TotalCondominium(data)) } />
            {data.length > 0 ?
                <Table2
                    columns={customerTableHeadGastosComunes}
                    data={data}
                    title=""
                    actions={getActionsTable()}
                />
                : null
            }
            <Loader title="Cargando gastos de los condominio" active={load}/>
        </div>
    )

}


export default GastosComunesPage;