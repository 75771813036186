import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlGuestProvider } from "../../../utils/urls";

export const obtenerProveedores = async token =>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.get(urlGuestProvider, header);
        if(consulta.status === 200){
            return consulta.data.data;
        }
    }catch(e){
        console.log("Error al obtener el listado de proveedores");
    }
    return false;
}