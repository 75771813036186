import "../users/Users.css";

import { Button } from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import ModalEditar from '../../components/pageComponents/ModalEditar';
import ModalEliminar from '../../components/pageComponents/ModalEliminar';
import ModalInsertar from "../../components/pageComponents/ModalInsertar"
import Table2 from '../../components/Table2';
import TitlePage from '../../components/pageComponents/TitlePage';
import XLSX from 'xlsx'
import axios from "axios"
import {makeStyles} from '@material-ui/core/styles';
import moment from "moment";
import { userContext } from '../../context/UserContext';
import TabOptionsRoute from "./routes/tabsRoutes";
import { getHeaders } from "../../utils/methods";
import { apiUrl, urlCollectionExpense, urlLightExpediture, urlPropertyUser, urlTotalLightExpediture } from "../../utils/urls";
import Loader from "../../components/LoaderComponent";
import { customerTableHeadEnergyTable, customerTableHeadEnergyTableV2 } from "./constants/tableHeads";
import { money } from "../../utils/format";
import { calculateSubTotalLight, calculateTotalLight, calculateTotalLightOfCondominium } from "./methods/maths";
import "./EnergiaPage/styles/EnergiaPage.scss";
import OptionsEnergyPage from "./EnergiaPage/components/OptionsEnergyPage";
import { useDispatch, useSelector } from "react-redux";
import { setGastosPagosPeriodoInRedux } from "../../redux/actions/GastosyPagosActions";
import CustomTable from "../../components/CustomTable/TableCustom";

const useStyles = makeStyles((theme) => ({
    modal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    iconos:{
      cursor: 'pointer'
    }, 
    inputMaterial:{
      width: '100%'
    }
  }));


  
  

  function Energia() {

    const token = localStorage.getItem('Authorization');
    
    const [data, setdata] = useState([]);
    const [data2, setdata2] = useState([]);
    const [data3, setdata3] = useState([]);
    const [data4, setdata4] = useState([]);
    const [fecha, setFecha] = useState("");
    const [totalAmount, setTotalAmount] = useState("");
    const [totalPeriod, setTotalPeriod] = useState("");
    const [unitCost, setunitCost] = useState("");
    const [showModalInsertar, setShowModalInsertar] = useState(false);
    const [showModalEditar, setShowModalEditar] = useState(false);
    const [showModalEliminar, setShowModalEliminar] = useState(false);
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [year,setYear]=useState('all')
    const { dataUser, setdataUser } = useContext(userContext);
    const [filteredData,setFilteredData]=useState([])
    const [filteredData2,setFilteredData2]=useState([])
    const [linkEncode, setlinkEncode] = useState("");
    const [exito, setExito] = useState(false);
    const [info, setInfo] = useState({
        consume: "",
        transactionCost: "0"
    })
    const [info2, setInfo2] = useState({
        consume: "",
        amount:""
    })
    /**
     * Reducers necesarios para la integridad del sistema
     */
     const periodoStore = useSelector((store)=>store.PeriodoControllerReducer);
     /**
      * Acciones para el control de los periodos
      */
     let dispatch = useDispatch();
     /**
      * Inicialización del estado de la página
      */
    const baseUrl=`${apiUrl}/property`;
    const handleChangeInsert = (e) => {
      setInfo({
        ...info,
        [e.target.name]: e.target.value
    })

    }
    const handleChangeInsert2 = (e) => {
      setInfo2({
        ...info2,
        [e.target.name]: e.target.value
    })

    }

    const{consume, amount,  date } = info2;
    /**
     * Inicializacion de la página
     */
    useEffect(() => {
      /**
      * Mandar al redux la fecha actual
      */
      const getPeriod = () =>{
          if(periodoStore.periodo === ""){
            const actualDate = new Date();
            const tempPeriod = moment(actualDate).format("YYYY-MM");
            dispatch(setGastosPagosPeriodoInRedux(tempPeriod));
          }
      }
      /**
       * Obtenemos los datos iniciales
       */
      const init = async ()=>{
        await buscarCotizacion();
        await buscarTotalLight();
        await buscarCobranza();
        await buscarFecha();
      }
      init();
      getPeriod();
    }, []);
        
    const buscarCotizacion = async() => {
      setLoading(true);
      try{
        const header = getHeaders(token);
        const rtdo = await axios.get(urlPropertyUser, header)
        const rtdo2 = rtdo.data.data
        const rtdo3=  rtdo2.map(obj=> ({ 
          id:obj.propertyId, 
          nombre: obj.user.name, 
          apellido: obj.user.lastName, 
          manzana: obj.property.block,
          lote: obj.property.lot,
          documento: obj.user.document, 
          consumo: 0
        }));
        setdata(rtdo3);    
        setdataUser(JSON.parse(localStorage.getItem('user')));
      }catch(e){
        alert("Error al obtener la cotizacion");
        console.log("Error cotizacion",e);
      }
      setLoading(false);
    }
    const buscarFecha = async() => {
      setLoading(true);
      try{
        const headers = getHeaders(token);
        const rtdo = await axios.get(urlLightExpediture, headers)
        const rtdo2 = rtdo.data.data;
        console.log("buscarFecha: ",rtdo2);
        setdata4(rtdo2)    
      }catch(e){
        alert("Error en buscarFecha");
        console.log("buscarFecha:",e)
      }
      setLoading(false);
    }
    const buscarTotalLight = async() => {
      setLoading(true);
      try{
        const headers = getHeaders(token);
        const rtdo = await axios.get(urlTotalLightExpediture, headers)
        const rtdo2 = rtdo.data.data
        console.log("buscarTotalLight:",rtdo2);
        console.log("fechaStart:",startDate);
        setdata2(rtdo2);
        setFilteredData2(rtdo2?.filter(dt=>dt.date.slice(0, 7) === periodoStore?.periodo))
      }catch(e){
        console.log("Error TotalLight:",e);
        alert("Error al traer el total del periodo");
      }
      setLoading(false);    
    }

    const buscarCobranza = async() => {
      try{
        const headers = getHeaders(token);
        const rtdo = await axios.get(urlCollectionExpense, headers);
        const rtdo2 = (rtdo.data.data).filter(artista=> artista.id === 6)
        console.log("buscarCobranza",rtdo2[0]);
        setdata3(rtdo2[0]);
      }catch(e){
        alert("Error al obtener los datos de cobranza");
        console.log("Error cobranza:",e);
      }  
    }


  

useEffect(() => {
  setFilteredData(data4.filter(dt=>dt.date.slice(0, 7) === periodoStore.periodo))
}, [data4])

useEffect(() => {
  setFilteredData2(data2?.filter(dt=>dt.date.slice(0, 7) === periodoStore.periodo))
}, [data2])


  useEffect(()=>{
    setFilteredData(data4.filter(dt=>dt.date.slice(0, 7) === year))
    setFilteredData2(data2?.filter(dt=>dt.date.slice(0, 7) === year))
  },[year])
      
  const SaveData = () => {
    if (filteredData2.length > 0) {
      console.log("filteredData2:",filteredData2);
      setTotalAmount(filteredData2[filteredData2.length - 1].amount)
      setunitCost(filteredData2[filteredData2.length - 1].consume)
    }
  }


      

      useEffect(()=>{
        const findWithNewDate = ()=>{
          setTotalPeriod(0);
          const newDate = periodoStore?.periodo;
          const filter = data2?.filter(dt=>dt.date.slice(0, 7) === newDate);
          const filterExpediture = data4?.filter(dt=>dt.date.slice(0, 7) === newDate);
          setunitCost(filter?.[0]?.consume);
          setTotalAmount(filter?.[0]?.amount);
          setFilteredData(filterExpediture);
          console.log("data filtrada: ",filterExpediture,filter);
          const totalPeriodo = calculateTotalLightOfCondominium(filterExpediture,filter?.[0]?.consume,data3?.amount);
          console.log("Total del periodo: ",totalPeriodo);
          setTotalPeriod(totalPeriodo);
        }
        findWithNewDate();
      },[periodoStore?.periodo, data2, data3, data4]);


      const peticionPost2=async()=>{
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
    
      }
          await axios.post(`${apiUrl}/total-light-expenditure`, {...info2,   date:periodoStore?.periodo+'-20'}, {headers})
          .then(response=>{
            // setdata(data.concat(response.data));
            abrirCerrarModalInsertar();
          }).catch(error=>{
            console.log(error);
          })
          buscarTotalLight()
          buscarCotizacion()
        }

        const peticionPost=async(e)=>{
          const dateInsert = moment(new Date(periodoStore.periodo+"-10")).format("YYYY-MM-DD")
          const f = new FormData();
          f.append("file", selectedImage)
          f.append("transactionCost", "4.56")
          f.append("date", dateInsert)
          
          const headers = {
              'Content-type': 'multipart/form-data',
              'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
        
          }
        
            const url1= `${apiUrl}/import-light-expenditures`
              await axios.post(url1, f, {headers})
              .then(response=>{
                // setdata(data.concat(response.data));
                // abrirCerrarModalInsertar();
        
                
                console.log("exito -1");
                setExito(!exito)
              }).catch(error=>{
                console.log(error);
                setSelectedImage()
              })
        
          // console.log(filesImg);
     
          }
          useEffect(() => {
            buscarFecha()
          }, [exito]);
       
       


        const peticionDelete=async()=>{
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
      
        }
          await axios.delete(baseUrl+"/"+info.id, {headers}, info) 
          .then(response=>{
            // setdata(data.filter(artista=>artista.id!==info.id));
           
        }).catch(error=>{ 
            console.log(error);
        })
        buscarCotizacion()
        abrirCerrarModalEliminar();
        }

        const peticionPut=async()=>{       

          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  localStorage.getItem('Authorization'),
      
        }
          await axios.put(`${apiUrl}/condominium-expense`+"/"+info.id,  info , {headers: headers})
          .then(response=>{

            

            abrirCerrarModalEditar();
           
          }).catch(error=>{
            console.log(error);
          })
         buscarCotizacion()
        }
      


    const onSubmitInsertar = (e) => {

        e.preventDefault();

        if (consume.trim() === ""||amount.trim() === "" ) {
        
         setError(true);
         return
        }else {
            setError(false);

            peticionPost2()
            setInfo2({
              consume: "",
              date:periodoStore?.periodo+'-20',
              amount:""        
            });
            abrirCerrarModalInsertar()
            buscarCotizacion()
            buscarTotalLight()
            SaveData()
        }
    }
    const onSubmitEditar = (e) => {

      e.preventDefault();       

            peticionPost()
            abrirCerrarModalEditar()
            setSelectedImage()
        }

    
    const abrirCerrarModalInsertar = () => {
          
        setShowModalInsertar(!showModalInsertar)
      }

      const abrirCerrarModalEditar=()=>{
        setShowModalEditar(!showModalEditar);
        setSelectedImage()
      }
      const abrirCerrarModalEliminar=()=>{
        setShowModalEliminar(!showModalEliminar);
      }
      const styles= useStyles();

      const bodyInsertar=(
        <form action="" onSubmit={onSubmitInsertar}>
          <div className={styles.modal}>
            <h3 className="my-5">Agregar detalles de Consumo y gestión</h3>
            { error ? <h4 className=" text-red-700">Completar todos los campos (*) del formulario</h4> : null }
            <label htmlFor="">Monto total*</label> <br />
            <input type="number" name="amount" onChange={handleChangeInsert2}  label="Monto total*" step="any"/>
             <br />
              <br />
              <label htmlFor="">Costo Unitario kw*</label>
            <input className={styles.inputMaterial} name="consume" onChange={handleChangeInsert2} label="Costo Unitario kw*" type="number" step="any"/>
             <br />
              <br />
              
            <br /><br />
            <div align="right">
              <Button color="primary" type="submit" >Insertar</Button>
              <Button onClick= {abrirCerrarModalInsertar}> Cancelar</Button>
            </div>
          </div>
        </form>
      )

      const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          setSelectedImage(e.target.files[0]);
          console.log(e.target.files[0]);
          // setSelectedFilesPost(e.target.files[0])
        }
    };

      const bodyEditar=(
        <form action="" onSubmit={onSubmitEditar}>
          <div className={styles.modal}>
            <h3 className="my-5">Adjuntar Excel para su importación</h3>
            { error ? <h4 className=" text-red-700">Completar todos los campos del formulario</h4> : null }
            { error ? <h4 className=" text-red-700">Completar todos los campos (*) del formulario</h4> : null }
            { error ? <h4 className=" text-red-700">Completar todos los campos (*) del formulario</h4> : null }
            {/* <TextField className={styles.inputMaterial} name="consume" onChange={handleChangeInsert} label="Kw consumidos*" type="number" /> */}

            <div className="mt-3"> Periodo: { periodoStore.periodo }</div>

            <div className='mt-5'>
              {/* <label>Choose File to Upload: </label> */}
              <input type="file" onChange={imageChange} id="file" />
              <div className="label-holder">
                <label htmlFor="file" className="label">
                  <i className="material-icons">note_add</i>
                </label>
              </div>
            </div> <br />
            {selectedImage && (
              <div className='eliminarImg'>
                <h4>{selectedImage.name}</h4>
  
                {/* <button onClick={removeSelectedImage} style={styles.delete}>
                  Eliminar
                </button> */}
              </div>
            )}
  
     
            <br /><br />
            <div align="right">
              <Button color="primary" type="submit" >Importar</Button>
              <Button onClick= {abrirCerrarModalEditar}> Cancelar</Button>
            </div>
          </div>
        </form>
      )

      const bodyEliminar=(
        <div className={styles.modal}>
          <p>Estás seguro que deseas eliminar  <b>{info&&info.name}</b>? </p>
          <div align="right">
            <Button color="secondary" onClick={()=>peticionDelete()}>Sí</Button>
            <Button onClick={()=>abrirCerrarModalEliminar()}>No</Button>
    
          </div>
    
        </div>
      )

      console.log("Data property user:",data);
      const downloadExcel=()=>{
        let dataExel = null;
        if(filteredData.length > 0){
          dataExel = filteredData.map(itm=>({
            id: itm.propertyId,
            nombre: itm?.property?.users?.[0]?.name,
            apellidos: itm?.property?.users?.[0]?.lastName,
            manzana: itm?.property?.block?.toUpperCase(),
            lote: itm?.property?.lot?.toUpperCase(),
            documento: itm?.property?.users?.[0]?.document,
            consumo: 0
          }));
        }else{
          dataExel = data;
        }
        const workSheet=XLSX.utils.json_to_sheet(dataExel)
        const workBook=XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook,workSheet,"Consumo de energía")
        //Binary string
        XLSX.write(workBook,{bookType:"xlsx",type:"binary"})
        //Download
        XLSX.writeFile(workBook,"ConsumoEnergia.xlsx")
      }
      console.log("Roll:",dataUser);
      /**
       * Control de los periodos
       */
      const _handleDate = (date)=>{
        const periodo = moment(date).format("YYYY-MM");
        dispatch(setGastosPagosPeriodoInRedux(periodo));
      }
      const _handleSelectPeriodCalendar = ()=>{
        if(periodoStore.periodo !== ""){
          return new Date(periodoStore.periodo+"-20");
        }else{
          return new Date();
        }
      }
    return (
        <div>
        <div>
          <TitlePage titulo="Gasto de Energía" />
          <TabOptionsRoute />
          <div className="pickFecha mt-10 ">
            <div className="flex">
              <h3>Periodo: </h3> <br />
              <DatePicker
                wrapperClassName="datePicker"
                selected={_handleSelectPeriodCalendar()}
                onChange={_handleDate}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </div>
          </div>
          <OptionsEnergyPage 
            rol={dataUser?.roleId} 
            downLoad={downloadExcel}
            toggleEdit={abrirCerrarModalEditar}
            toggleInsert={abrirCerrarModalInsertar} 
          />
          <div className="flex justify-end mt-1 text-gray-400">
            { totalPeriod > 0 ?    
              <div className="flex justify-end mt-1 column text-gray-400">
                <div><h3>Fecha: {periodoStore?.periodo} </h3></div>
                <div><h3>Consumo total: { money.format(totalPeriod)}</h3></div>
                <div><h3>Costo unitario (kw): { money.format(unitCost) }</h3></div>
              </div> 
            : null }  
          </div>
          <CustomTable columns={customerTableHeadEnergyTableV2}>
              {filteredData.map(itm=>
                <tr>
                  <td>{itm?.property?.users?.[0]?.name + ' ' + itm?.property?.users?.[0]?.lastName}</td>
                  <td>{itm?.property?.block}</td>
                  <td>{itm?.property?.lot}</td>
                  <td>{itm?.date?.slice(0,7)}</td>
                  <td>{itm?.consume}</td>
                  <td>{money.format(calculateSubTotalLight(itm?.consume,unitCost))}</td>
                  <td>{money.format(data3?.amount)}</td>
                  <td>{ money.format(calculateTotalLight(itm?.consume,unitCost,data3?.amount)) }</td>
                </tr>
              )}
          </CustomTable>
          {/*
              <div className="mt-10 datagrid">
                <Table2 
                  title="Tabla 1" 
                  columns={customerTableHead2} 
                  data={data}
                />
              </div>
              <div className="mt-10">
              <Table2 
                title="" 
                columns={ customerTableHeadEnergyTable({
                  amount: data3.amount,
                  unitCost: unitCost,
                  fecha: fecha
                }) } 
                data={filteredData}
              />
              </div>*/}
            </div>
            <ModalInsertar
            showmodalInsertar={showModalInsertar}
            functionShow= {abrirCerrarModalInsertar}
            handleChangeInsert={handleChangeInsert}
            onSubmitInsertar={onSubmitInsertar}
            error={error}
            bodyInsertar={bodyInsertar}
           
            
            />
            <ModalEditar
            showModalEditar={showModalEditar}
            functionShow= {abrirCerrarModalEditar}
            handleChangeInsert={handleChangeInsert}
            onSubmitEditar={onSubmitEditar}
            info={info}
            bodyEditar={bodyEditar}
            />
            <ModalEliminar
            showModalEliminar={showModalEliminar}
            abrirCerrarModalEliminar= {abrirCerrarModalEliminar}
            onSubmitEditar={onSubmitEditar}
            info={info}
            peticionDelete={peticionDelete}
            bodyEliminar={bodyEliminar}
            />
          <Loader title={"Cargando gastos de energía"} active={loading} />
        </div>
    )
}

export default Energia
