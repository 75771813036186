import axios from "axios";
import { getHeaders } from "../../../utils/methods";
import { urlReservations } from "../../../utils/urls";
export const getReservations = async (token)=>{
    try{
        const header = getHeaders(token);
        const consulta = await axios.get(urlReservations,header);
        if(consulta.status === 200){
            return consulta.data.data;
        }
    }catch(e){
        alert("Error al obtener las reservaciones");
        console.log("Error reservations:",e);
    }
    
    return 0;
}