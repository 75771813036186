import * as Yup from 'yup';
import { FIELD_IS_REQUIRED, INVALID_CHARACTERS } from '../../../messages/error.messages';
import { onlyAlphanumericAndSpaceRegex, onlyNumbersRegex } from '../../../regex/validations';

const createSpaceValidationSchema = Yup.object({}).shape({
    spaceTypeId: Yup.string()
        .required(FIELD_IS_REQUIRED)
        .matches(onlyNumbersRegex, INVALID_CHARACTERS)
    ,
    description: Yup.string()
        .required(FIELD_IS_REQUIRED),
    internalCode: Yup.string()
        .required(FIELD_IS_REQUIRED)
        .matches(onlyAlphanumericAndSpaceRegex, INVALID_CHARACTERS),
    previusReservationTime: Yup.string()
        .required(FIELD_IS_REQUIRED)
        .matches(onlyNumbersRegex, INVALID_CHARACTERS),
    maximiunReservationTime: Yup.string()
        .required(FIELD_IS_REQUIRED)
        .matches(onlyNumbersRegex, INVALID_CHARACTERS),
    rulesOfUse: Yup.string()
        .required(FIELD_IS_REQUIRED),
    file: Yup.object()
        .required(FIELD_IS_REQUIRED)
});

export default createSpaceValidationSchema;