
const OptionsEnergyPage = ({ rol, downLoad, toggleEdit, toggleInsert }) =>{

    return(
        <>
        { rol === "1" ?
            <div className="flex justify-between mt-16">
                <button className="btn" onClick={()=>downLoad()}>
                    Descargar Plantilla
                </button>
                <button className="btn" onClick={()=>toggleEdit()}>
                    Importar Plantilla
                </button>
                <button className="btn" onClick={() => toggleInsert()}>
                    Agregar gasto
                </button>
            </div> 
        : null }
        </>
    )

}

OptionsEnergyPage.defaultProps = {
    rol: "0",
    downLoad: ()=>{},
    toggleEdit: ()=>{},
    toggleInsert:()=>{}
}

export default OptionsEnergyPage;