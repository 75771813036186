import React from 'react'
import { Button, TextField } from "@material-ui/core";

export default function BodyInsertar(props) {
    const {
        onSubmitInsertar,
        styles,
        error,
        handleChangeInsert,
        abrirCerrarModalInsertar
    } = props
  return (
    <form action="" onSubmit={onSubmitInsertar}>
    <div className={styles.modal}>
      <h3 className="my-5">Agregar Nuevo Usuario</h3>
      {error ? (
        <h4 className=" text-red-700">
          Completar todos los campos (*) del formulario
        </h4>
      ) : null}
      <TextField
        className={styles.inputMaterial}
        name="name"
        onChange={handleChangeInsert}
        label="Nombres*"
      />
      <br />
      <TextField
        className={styles.inputMaterial}
        name="lastName"
        onChange={handleChangeInsert}
        label="Apellidos*"
      />
      <br />
      <TextField
        className={styles.inputMaterial}
        name="document"
        onChange={handleChangeInsert}
        label="Doc. de Identidad*"
      />
      <br />
      <TextField
        className={styles.inputMaterial}
        name="email"
        onChange={handleChangeInsert}
        label="Email*"
      />
      <TextField
        className={styles.inputMaterial}
        name="phone"
        onChange={handleChangeInsert}
        label="Telefono*"
      />
      <br />
      {/* <input type="text" className={styles.inputMaterial} name="role" value="2" className="hide" onChange={handleChangeInsert}/> */}
      {/* <input type="text" className={styles.inputMaterial} name="role" value="2" className="hide" onChange={handleChangeInsert}/> */}

      <br />
      <br />
      <div align="right">
        <Button color="primary" type="submit">
          Insertar
        </Button>
        <Button onClick={abrirCerrarModalInsertar}> Cancelar</Button>
      </div>
    </div>
  </form>
  )
}
