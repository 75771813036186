import axios from 'axios';
import { getHeaders } from '../../../utils/methods';
import { urlClaims } from '../../../utils/urls';
export const obtainClaims = async (token)=>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.get(urlClaims, header);
        if(consulta.status === 200){
            return consulta.data.data;
        }else{
            return false;
        }
    }catch(e){
        console.log("Error al obtener el listado de quejas:",e);
        alert("Error al obtener el listado de quejas");
        return false;
    }
}