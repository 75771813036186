require('dotenv').config()

export const apiUrl = process.env.REACT_APP_URL_API_TEST;
export const urlAvatar = process.env.REACT_APP_URL_AVATAR_TEST
export const urlCondominiumExpense = `${apiUrl}/condominium-expense`;
export const urlCollectionExpense = `${apiUrl}/collection-expense`;
export const urlPropertyUser = `${apiUrl}/property-user`;
export const urlLightExpediture = `${apiUrl}/light-expenditure`;
export const urlTotalLightExpediture = `${apiUrl}/total-light-expenditure`;
export const urlWaterExpenditure = `${apiUrl}/water-expenditure`;
export const urlTotalWaterExpediture = `${apiUrl}/total-water-expenditure`;
export const urlInvoice = `${apiUrl}/invoice`;
export const urlClaims = `${apiUrl}/complaint-claim`;
export const urlFormalities = `${apiUrl}/owner-process`;
export const urlReservations= `${apiUrl}/reservation`;
export const urlGuest = `${apiUrl}/guest`;
export const urlSpaces= `${apiUrl}/space`;
export const urlSpaceType = `${apiUrl}/space-type`;
export const urlSpaceImage= `${apiUrl}/space-image`;
export const urlUsefulInformation = `${apiUrl}/useful-information`;
export const urlRegister = `${apiUrl}/register`;
export const urlProperty = `${apiUrl}/property`;
export const urlImportOwner = `${apiUrl}/import-owner`;
export const urlUser = `${apiUrl}/user`;
export const urlProcessObservation = `${apiUrl}/process-observation`;
export const urlResponse = `${apiUrl}/response`;
export const urlGuestProvider = `${apiUrl}/guest-provider`;