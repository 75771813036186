import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlUsefulInformation } from "../../../utils/urls";

export const getTelefonos = async (token) =>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.get(urlUsefulInformation, header);
        const response = consulta.data.data;
        const filterData = response.filter(itm=> itm.phone.length > 1 && itm.phone !== null);
        if(consulta.status === 200){
            return filterData;
        }else{
            return false;
        }
    }catch(e){
        alert("Error al obtener los telefonos");
        return false;
    }
}