import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlUser } from "../../../utils/urls";

export const toggleVisibilityUser = async (isVisible, info, token)=>{
    const header = getHeaders(token);
    try{
        const newIsVisible = isVisible ? "0" : "1";
        const consulta = await axios.put(`${urlUser}/${info?.userId}`,{ visibility: newIsVisible }, header);
        if(consulta.status === 201)
            return true;
        else 
            return false;
    }catch(e){
        console.log("Error al hacer el toggle");
        return false;
    }
}