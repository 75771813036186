import { Route } from "react-router-dom"
import Sidebar from "../../components/sidebar/Sidebar"
import Topbar from "../../components/topbar/Topbar"
import CalendarioProvider from "../../context/CalendarioContext"
import EspacioProvider from "../../context/EspacioContext"
import GastosProvider from "../../context/GastosContext"
import InvitadosProvider from "../../context/InvitadosContext"
import NoticiasProvider from "../../context/NoticiasContext"
import PersonalProvider from "../../context/PersonalContext"
import ProveedoresProvider from "../../context/ProveedoresContext"
import QuejasProvider from "../../context/QuejasContext"
import ReglasProvider from "../../context/ReglasContext"
import SeguridadProvider from "../../context/SeguridadContext"
import TramitesProvider from "../../context/TramitesContext"
import UsuariosProvider from "../../context/UsuariosContext"
import UtilInfoProvider from "../../context/UtilInfoContext"
import Archivos from "../../pages/archivos/Archivos"
import Calendar from "../../pages/calendar/Calendar"
import Espacio from "../../pages/espacio/Espacio"
import BankAccount from "../../pages/gastos/BankAccount"
import Calculos from "../../pages/gastos/Calculos"
import Configuracion from "../../pages/gastos/Configuracion"
import Energia from "../../pages/gastos/Energia"
import GastosComunes from "../../pages/gastos/GastosComunes"
import GastosComunesPage from "../../pages/gastos/GastosComunes/GastosComunesPage"
import GastosPrueba from "../../pages/gastos/GastosPrueba"
import Historial from "../../pages/gastos/Historial"
import HistorialAgua from "../../pages/gastos/HistorialAgua"
import NewExpenseRecordPage from "../../pages/gastos/NewExpenseRecordPage"
import Prueba from "../../pages/gastos/Prueba"
import Home from "../../pages/home/Home"
import Invitados from "../../pages/invitados/Invitados"
import Proveedores from "../../pages/invitados/Proveedores"
import Noticias from "../../pages/noticias/noticias"
import Personal from "../../pages/personal/Personal"
import Personal2 from "../../pages/personal/Personal2"
import Quejas from "../../pages/quejas/Quejas"
import Seguridad from "../../pages/seguridad/Seguridad"
import Telefonos from "../../pages/telefonos/Telefonos"
import DetalleTramite from "../../pages/tramites/DetalleTramite"
import Tramites from "../../pages/tramites/Tramites"
import Users from "../../pages/users/Users"
import Visita from "../../pages/visita/Visita"

const AuthRoutes = ()=>{
    return(
        <div className="container">
        <Sidebar/>
        <div  className="topbarContainer" >
          <Topbar/>      
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/logout">
            <div>
              Cerrando sesión
            </div>
          </Route>
          <UsuariosProvider>
            <Route path="/Users">
                <Users/>
            </Route>
          </UsuariosProvider>
          <PersonalProvider>
            <Route path="/Personal">
                <Personal/>
            </Route>
            <Route path="/Personal2">
                <Personal2/>
            </Route>
          </PersonalProvider>
          <EspacioProvider>
            <Route path="/Espacio">
                <Espacio/>
            </Route>
          </EspacioProvider>
          <ReglasProvider>
            <Route path="/Visita">
                <Visita/>
            </Route>            
          </ReglasProvider>
          <NoticiasProvider>
            <Route path="/Noticias">
                <Noticias/>
            </Route>
          </NoticiasProvider>
          <QuejasProvider>
            <Route path="/Quejas">
                <Quejas/>
            </Route>
          </QuejasProvider>
          <SeguridadProvider>
            <Route path="/Seguridad">
                <Seguridad/>
            </Route>
          </SeguridadProvider>
          <UtilInfoProvider>
            <Route path="/Archivos">
                <Archivos/>
            </Route>
            <Route path="/Telefonos">
                <Telefonos/>
            </Route>
          </UtilInfoProvider>
          <TramitesProvider>
            <Route path="/Tramites">
                <Tramites/>
            </Route>
            <Route path="/Tramites/:id">
                <DetalleTramite/>
            </Route>
          </TramitesProvider>
          <GastosProvider>
            <Route path="/GastosComunes">
              <GastosComunes/>
            </Route>
            <Route path="/NewExpenseRecord">
              <NewExpenseRecordPage/>
            </Route>
            <Route path="/GastosComunes1">
              <GastosComunesPage/>
            </Route>
          </GastosProvider>
          <Route path="/Calculos">
            <Calculos/>
          </Route>
          <CalendarioProvider>
            <Route path="/Calendario">
                <Calendar/>
            </Route>
          </CalendarioProvider>
          <Route path="/Energia">
            <Energia/>
          </Route>
          <Route path="/BankAccount">
            <BankAccount/>
          </Route>
          <Route path="/Prueba">
            <Prueba/>
          </Route>
          <Route path="/Configuracion">
            <Configuracion/>
          </Route>
          <Route path="/Historial">
            <Historial/>
          </Route>
          <Route path="/HistorialAgua">
            <HistorialAgua/>
          </Route>
          <Route path="/GastosPrueba">
            <GastosPrueba/>
          </Route>
          <InvitadosProvider>
            <Route path="/Invitados">
                <Invitados/>
            </Route>
          </InvitadosProvider>
          <ProveedoresProvider>
            <Route path="/Proveedores">
                <Proveedores/>
            </Route>
          </ProveedoresProvider>
          </div>
      </div>
    )
}

export default AuthRoutes;