import React from 'react';
import styled from '@emotion/styled'

const Titulo = styled.h1`
        color: gray;
    font-weight: 500;
    padding: 20px 0px;
    width:100%;
`

function TitlePage({titulo}) {
    return (
        <div>
            <Titulo>{titulo}</Titulo>
        </div>
    )
}

export default TitlePage
