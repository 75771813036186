import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlProperty } from "../../../utils/urls";

export const createProperty = async (token, propertyInfo) =>{
    const header = getHeaders(token);
    try{
        const creaPropiedad = await axios.post(urlProperty, propertyInfo, header);
        if(creaPropiedad.status === 201){
            return creaPropiedad.data?.data;
        }else{
            return false;
        }
    }catch(e){
        alert("Error al crear la propiedad");
        return false;
    }
}