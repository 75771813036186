import { JavascriptRounded } from "@mui/icons-material"
import "./PendingPayments.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser, faCalendarDays} from '@fortawesome/free-solid-svg-icons'



function PendingPayments({
    data
}) {
    return(
        <div id="containerPendingPay">
            <h2 id="tittlePendingPay">Pagos pendientes</h2>
            <div id="containerTable">
                <div className="tableContainer">
                    <div className="tableHeader">
                        <div className="headTitle gd20">Propietario</div>
                        <div className="headTitle gd20 tc">Propiedad</div>
                        <div className="headTitle gd20 tc">Telefono</div>
                        <div className="headTitle gd20 tc">Periodo</div>
                        <div className="headTitle gd20 tc">Estado</div>
                    </div>
                    {
                        data.map((person)=>{
                        return (
                            <div className="tableBodyPersons">
                                <div className="gd20 df ac"> <img src={person?.avatarUrl} title="logo" className="imgAvatar"/> {person.name} </div>
                                <div className="gd20 tc"> {person.propiedad} </div>
                                <div className="gd20 tc"> {person.telefono} </div>
                                <div className="gd20 tc"> <FontAwesomeIcon icon={faCalendarDays} color={"#0094AF"}/> {person.periodo} </div>
                                <div className={`gd20 ${ person.status === "Pendiente" ? "pendient" : null}`}> 
                                    {person.status} 
                                </div>
                            </div>
                        )})
                    }
                </div>  
            </div>
        </div>
    )
}

PendingPayments.defaultProps = {
    data: []
}

export default PendingPayments