import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlPropertyUser } from "../../../utils/urls";

export const crearRelacionPropietarios = async (token, info) =>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.post(urlPropertyUser, info, header);
        if(consulta.status === 201){
            return true;
        }else{
            return false;
        }
    }catch(e){
        alert("Error al relacionar los propietarios");
        return false;
    }
}