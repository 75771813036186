import "./styles/table2.scss";
import MaterialTable from "material-table";
import React from "react";

const Table2 = (props) => {
  return (
    <div className="tableContainer">
      <MaterialTable
        columns={props.columns}
        data={props.data}
        title={props.title}
        actions={props.actions}
        style={{ width: "95%", maxWith: "100%", margin: "auto" }}
        options={{
          search: false,
          maxBodyHeight: "150vh",
          actionsColumnIndex: -1,
          pageSizeOptions: [20, 40, 60],
          pageSize: 20,
          headerStyle: {
            minWidth: 20,
            maxWidth: 20,
            color: "#fff",
            fontSize: "15px",
            fontWeight: 700,
            background: "#f9b500",
            margin: "0",
          },
        }}
        localization={{
          header: {
            actions: "Acciones",
          },
        }}
      />
    </div>
  );
};

export default Table2;
