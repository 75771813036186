import { Button, makeStyles, Modal } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    modal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    iconos:{
      cursor: 'pointer'
    }, 
    inputMaterial:{
      width: '100%'
    }
  }));

const ModalApprove = ({
    title,
    open,
    onClose,
    yesFunction
})=>{
    const styles = useStyles();
    return(
        <>
        <Modal open={open} onClose={onClose}>
            <div className={styles.modal}>
                <p>{title}</p>
                <div align="right">
                <Button color="secondary" onClick={yesFunction}>Sí</Button>
                <Button onClick={onClose}>No</Button>
                </div>
            </div>
        </Modal>
        </>
    )
}
ModalApprove.defaultProps = {
    title: "",
    open: ()=>{},
    onClose:()=>{},
    yesFunction:()=>{}
}

export default ModalApprove;