import "./TableCustom.scss";
const CustomTable = ({ children, columns, title }) =>{
    return(
        <table className="tableCustomContainer">
            <thead className="thead">
                <tr className="theadTitle">
                    {columns.map(itm=><td className="theadContent"><b>{itm.title}</b></td>)}
                </tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </table>
    )
}

CustomTable.defaultProps = {
    columns: [],
    title: ""
}

export default CustomTable;