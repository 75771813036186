import "./Graphics.css"
import {Line} from 'react-chartjs-2'
import {Chart as ChartJS} from 'chart.js/auto'

let month = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio']



function Graphics() {
    return (
        <div id="containerGraphics">
            <div className="containerGraphic">
                <Line
    
                    data={{
                        labels: month,
                        datasets: [
                            {
                                label: 'Ingresos del Mes',
                                data: [0,0,0,0,0,0],
                                borderColor: 'rgb(4,183,36)'
                            }
                        ],
                    }}
            />
            </div>
           
            <div className="containerGraphic">
                <Line
  
                    data={{
                        labels: month,
                        datasets: [
                            {
                                label: 'Gastos',
                                data: [0,0,0,0,0,0],
                                borderColor: 'rgb(249,181,0)'
                            }

                        ],
                    }}
                />
            </div>
           
        </div>
    )
}

export default Graphics