import axios from "axios";
import { getHeaders } from "../../../utils/methods"
import { urlUser } from "../../../utils/urls";

export const obtenerPersonal = async (token)=>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.get(urlUser, header);
        if(consulta.status === 200){
            const filterData = consulta.data.data.filter(user=>user.roleId !== 3)
            return filterData;
        }else{
            return false;
        }
    }catch(e){
        console.log("Error al obtener la lista del personal:",e);
        return false;
    }
}