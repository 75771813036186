export const customerTableHeadGastosComunes = [
    {
        title:"Concepto",
        field: "concept"
    },
    {
        title:"N° de Factura",
        field: "invoiceNumber"
    },
    {
        title:"Fecha",
        field: "date",
        render: data => (data.date).split(" ")[0].split("-").reverse().join("-").slice(3, 10)
    },
    {
        title:"Monto",
        field: "amount"
    },
    {
        title:"Archivo",
        field: "document"
    }
]