export const getHeaders = token =>{
    const header = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' +  token
                    }
                }
    return header;
}

export const getHeaderFiles = (token) =>{
    const header ={
        headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': 'Bearer ' +  token,
      
        }
    }
    return header;
}

export const checkFileIsImage = (type)=>{
    const types = ['jpeg', 'jpg', 'png', 'webp', 'bmp'];
    if(types.includes(type))
        return true;
    return false;
}

export const checkFileIsValid = type =>{
    const types = ["jpg", "png", "jpeg", "pdf", "mp4", "avi"];
    if(types.includes(type))
        return true;
    return false;
}