import create from 'zustand'

const useStore = create((set) => ({
    data: [],
    // similar hooks useState (in zustand)
    error: false,
    setError: (interruptor)=> set((state)=> ({error: interruptor})),
    loading: false,
    setLoading: ()=> set((state)=> ({loading: !state.loading})),
    showModalInsertar: false,
    setShowModalInsertar: ()=> set((state)=> ({showModalInsertar: !state.showModalInsertar})),
    showModalEditar2: false,
    setShowModalEditar2: ()=> set((state)=>({showModalEditar2: !state.showModalEditar2})),
    showModalEditar: false,
    setShowModalEditar: ()=> set((state)=> ({showModalEditar: !state.showModalEditar})),
    showModalEliminar: false, 
    setShowModalEliminar: ()=> set((state)=> ({showModalEliminar: !state.showModalEliminar}))
}))

export const usePersonalStore = useStore;