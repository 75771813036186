import axios from "axios";
import { getHeaders } from "../../../utils/methods";
import { urlGuestProvider } from "../../../utils/urls";

export const editarProveedor = async (token, info)=>{
    const header = getHeaders(token);
    try{
        const consulta = await axios.post(`${urlGuestProvider}/${info?.id}`, info, header);
        if(consulta.status === 201){
            return true;
        }
    }catch(e){
        console.log("Error al crear la visita");
    }
    return false;
}