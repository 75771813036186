export const years = [
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
    '2031',
    '2032',
    '2033',
    '2034',
    '2035',
    '2036',
    '2037'
];

export const months = [
    {value:'01',name:'Enero'},
    {value:'02',name:'Febrero'},
    {value:'03',name:'Marzo'},
    {value:'04',name:'Abril'},
    {value:'05',name:'Mayo'},
    {value:'06',name:'Junio'},
    {value:'07',name:'Julio'},
    {value:'08',name:'Agosto'},
    {value:'09',name:'Septiembre'},
    {value:'10',name:'Octubre'},
    {value:'11',name:'Noviembre'},
    {value:'12',name:'Diciembre'}
]